import { FormInstance } from "antd/lib/form";
import { OutboundOfferFormFields } from "./OutboundOfferForm";
import {
  createEntity,
  getCompany,
  getSingleDataEntryFromApi,
  updateEntity,
} from "../../graphql/model.graphql";
import * as queries from "../../graphql/queries";
import { InboundInquiry } from "../../store/types/api.types";
import * as mutations from "../../graphql/mutations";
import { OutboundOfferVariablesUpdate } from "../../graphql/type.graphql";
import { removePropsOfValues } from "../InboundInquiryForm/model.inboundInquiryForm";

/**
 *
 * @param form
 */
export const calcMargin = (form: FormInstance<any>) => {
  const purchasePrice = parseFloat(form.getFieldValue("purchasePrice"))
    ? parseFloat(form.getFieldValue("purchasePrice"))
    : 0;
  const salesPrice = parseFloat(form.getFieldValue("salesPrice"))
    ? parseFloat(form.getFieldValue("salesPrice"))
    : 0;
  form.setFieldsValue({
    margin: salesPrice - purchasePrice,
  });
};

/**
 *
 * @param values
 */
export function removeInboundInquiryAttributes(
  values: OutboundOfferFormFields
): OutboundOfferVariablesUpdate {
  return removePropsOfValues(
    ["inboundInquiryComments", "mailContactPersonInboundInquiry"],
    values
  );
}

/**
 *
 * @param id
 */
export async function getInboundInquiry(
  id: string
): Promise<InboundInquiry | undefined> {
  return (
    await getSingleDataEntryFromApi({
      query: queries.getInboundInquiry,
      id: id,
    })
  ).data.getInboundInquiry;
}

/**
 *
 * @param values
 */
export async function createOutboundOffer(values: OutboundOfferFormFields) {
  const company = await getCompany(values.outboundOfferCompanyId);
  const inboundInquiry = await getInboundInquiry(
    values.outboundOfferInboundInquiryId
  );
  if (!inboundInquiry) return;
  await createEntity({
    mutation: mutations.createOutboundOffer,
    variables: {
      ...removeInboundInquiryAttributes(values),
      product: inboundInquiry.product,
      type: "outboundOffer",
      companyName: company?.name || "",
    },
  });
}

/**
 *
 * @param values
 */
export async function updateOutboundOffer(values: OutboundOfferFormFields) {
  const [company, inboundInquiry] = await Promise.all([
    getCompany(values.outboundOfferCompanyId),
    getInboundInquiry(values.outboundOfferInboundInquiryId),
  ]);
  if (!inboundInquiry) return;
  await updateEntity({
    mutation: mutations.updateOutboundOffer,
    variables: {
      ...removeInboundInquiryAttributes(values),
      product: inboundInquiry.product,
      companyName: company?.name || "",
    },
  });
}
