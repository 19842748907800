import React, { useState } from "react";
import { Form, Input, Button, Grid } from "antd";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { UserState } from "../../store/types/user.types";
import { useTranslation } from "react-i18next";
import { confirmSignUp } from "./model";
import { confirmUser, logInUser } from "../../store/actions/user.actions";
import { AuthCard } from "../AuthCard/AuthCard";
import { FormState } from "../../pages/AuthenticationPage/AuthenticationPage";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const { useBreakpoint } = Grid;

export const ConfirmSignUpForm = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const screens = useBreakpoint();
  const [correctAuthCode, setCorrectAuthCode] = useState(true);
  const userInformation: UserState = useSelector(
    (state: RootStateOrAny) => state.userReducer
  );

  const submitForm = (values: { authCode: string }) => {
    confirmSignUp(userInformation.user.attributes.email, values.authCode).then(
      (check) => {
        if (check) {
          dispatch(logInUser(userInformation.user));
          dispatch(confirmUser());
        } else {
          setCorrectAuthCode(false);
        }
      }
    );
  };

  return (
    <AuthCard title={FormState.CONFIRM_SIGN_UP}>
      <Form onFinish={submitForm} {...layout} form={form}>
        <Form.Item
          name="authCode"
          label={t("Authentication Code")}
          rules={[
            {
              required: true,
              message: t("Please enter your authentication-code"),
            },
          ]}
        >
          <Input />
        </Form.Item>
        {!correctAuthCode && (
          <p style={{ color: "#ff4d4f" }}>
            {t("The authentication code is not correct")}
          </p>
        )}
        <Form.Item wrapperCol={{ offset: screens.xs ? 0 : 8 }}>
          <Button htmlType="submit" type="primary" style={{ width: "100%" }}>
            {t("Confirm Sign Up")}
          </Button>
        </Form.Item>
      </Form>
    </AuthCard>
  );
};
