import {
  CompanyVariablesCreate,
  CompanyVariablesUpdate,
} from "../../graphql/type.graphql";
import { createEntity, updateEntity } from "../../graphql/model.graphql";
import * as mutations from "../../graphql/mutations";

/**
 *
 * @param values
 */
export async function updateCompany(values: CompanyVariablesUpdate) {
  await updateEntity({
    mutation: mutations.updateCompany,
    variables: values,
  });
}

/**
 *
 * @param values
 */
export async function createCompany(values: CompanyVariablesCreate) {
  await createEntity({
    mutation: mutations.createCompany,
    variables: { ...values, type: "company" },
  });
}
