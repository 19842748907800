import { Auth } from "aws-amplify";
import { UserInformation } from "../../store/types/user.types";

/**
 * fuction that allows a user to create an account
 */
export async function signUp(userInformation: UserInformation) {
  try {
    await Auth.signUp({
      username: userInformation.email,
      password: userInformation.password,
      attributes: {
        name: userInformation.firstName ? userInformation.firstName : "",
        family_name: userInformation.lastName ? userInformation.lastName : "",
        picture: userInformation.photoUrl ? userInformation.photoUrl : "",
      },
    });
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}
