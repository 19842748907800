import React, { useState } from "react";
import { Form, Input, Col, Row, Button, Layout, Steps, Grid } from "antd";
import { useTranslation } from "react-i18next";
import { AuthCard } from "../AuthCard/AuthCard";
import {
  UserOutlined,
  SolutionOutlined,
  SmileOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import {
  ChangePasswordStatus,
  sendVerificationCode,
  setNewPassword,
} from "./model";
import { FormState } from "../../pages/AuthenticationPage/AuthenticationPage";

interface Props {
  setFormState: React.Dispatch<React.SetStateAction<FormState>>;
}

const INITIAL_STATUS: ChangePasswordStatus = {
  email: "",
  sendVerificationCode: "process",
  VerificationCodeEntered: "wait",
  done: "wait",
};

const { useBreakpoint } = Grid;

export const ForgotPasswordForm: React.FC<Props> = ({ setFormState }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const screens = useBreakpoint();
  const [steps, setSteps] = useState<ChangePasswordStatus>(INITIAL_STATUS);

  const layout = {
    labelCol: { span: steps.VerificationCodeEntered === "process" ? 8 : 4 },
    wrapperCol: { span: steps.VerificationCodeEntered === "process" ? 16 : 20 },
  };

  const handleSubmit = async (values: any) => {
    if ("password" in values) setNewPassword(values, setSteps, t);
    else sendVerificationCode(values, setSteps, t);
  };

  return (
    <AuthCard title="">
      <Steps size="small">
        <Steps.Step
          title={screens.xs ? "" : t("Get Verification-Code")}
          status={steps.sendVerificationCode}
          icon={<UserOutlined />}
        />
        <Steps.Step
          title={screens.xs ? "" : t("Verify")}
          status={steps.VerificationCodeEntered}
          icon={<SolutionOutlined />}
        />
        <Steps.Step
          title={screens.xs ? "" : t("Done")}
          status={steps.done}
          icon={<SmileOutlined />}
        />
      </Steps>
      <Layout.Content style={{ marginTop: 20 }}>
        {steps.done === "wait" ? (
          <Form
            style={{ marginTop: 50 }}
            onFinish={handleSubmit}
            form={form}
            {...layout}
          >
            {steps.sendVerificationCode === "process" ||
            steps.sendVerificationCode === "error" ? (
              <div>
                <Form.Item required label="Email">
                  <Row>
                    <Col xs={24} sm={19}>
                      <Form.Item
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: t("Please enter your email"),
                          },
                        ]}
                      >
                        <Input type="email" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={5}>
                      <Button
                        style={{ width: "100%", fontSize: 12 }}
                        htmlType="submit"
                        type="primary"
                      >
                        {t("Get Code")}
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
                <Row justify="center">
                  <Col xs={24} sm={10} style={{ textAlign: "center" }}>
                    <Button
                      type="link"
                      style={{ color: "#1890FF" }}
                      onClick={() => setFormState(FormState.SIGN_IN)}
                    >
                      {t("Log in")}
                    </Button>
                  </Col>
                </Row>
              </div>
            ) : (
              <div>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: t("Please enter your email"),
                    },
                  ]}
                >
                  <Input type="email" readOnly value={steps.email} />
                </Form.Item>
                <Form.Item
                  name="password"
                  label={t("New Password")}
                  rules={[
                    {
                      required: true,
                      message: t("Please enter a password"),
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item
                  name="confirm"
                  label={t("Confirm Password")}
                  dependencies={["password"]}
                  rules={[
                    {
                      required: true,
                      message: t("Please confirm your password"),
                    },
                    ({ getFieldValue }) => ({
                      validator(_, confirm_password) {
                        if (getFieldValue("password") === confirm_password) {
                          return Promise.resolve();
                        } else
                          return Promise.reject(
                            t(
                              "The two passwords that you entered do not match!"
                            )
                          );
                      },
                    }),
                  ]}
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item
                  label={t("Confrimation-Code")}
                  name="confirmationCode"
                  rules={[
                    {
                      required: true,
                      message: t("Please enter your confirmation Code"),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <div style={{ textAlign: "center" }}>
                  <Button htmlType="submit" type="primary">
                    {t("Change Password")}
                  </Button>
                </div>
              </div>
            )}
          </Form>
        ) : (
          <div
            style={{
              marginTop: 50,
              textAlign: "center",
              fontSize: "1.5em",
              color: "#52c41a", //Green
            }}
          >
            <CheckOutlined />
            Password Changed
            <Row justify="center">
              <Col
                xs={24}
                sm={10}
                style={{ textAlign: "center", marginTop: 20 }}
              >
                <Button onClick={() => setFormState(FormState.SIGN_IN)}>
                  {t("Log in")}
                </Button>
              </Col>
            </Row>
          </div>
        )}
      </Layout.Content>
    </AuthCard>
  );
};
