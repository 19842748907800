import client from "@sanity/client";

export const SANITY_PROJECT_ID: string = "0sndtehc";
export const SANITY_DATASET: string = "production";

export const sanityClient = client({
  projectId: SANITY_PROJECT_ID,
  dataset: SANITY_DATASET,
  token: "", // or leave blank to be anonymous user
  useCdn: true, // `false` if you want to ensure fresh data
});
