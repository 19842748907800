export const outboundOffersOfInboundInquiry = /* GraphQL */ `
  query GetInboundInquiry($id: ID!) {
    getInboundInquiry(id: $id) {
      outboundOffers {
        items {
          id
          lysisMember
          date
          condition
          type
          warranty
          inquiryComments
          purchasePrice
          salesPrice
          margin
          createdAt
          updatedAt
          offerStatus
          inboundInquiry {
            id
            product
            inquiryComments
          }
          company {
            id
            name
          }
        }
      }
      createdAt
      updatedAt
    }
  }
`;

export const listInboundOffersByProduct = /* GraphQL */ `
  query ListInboundOffers(
    $filter: ModelInboundOfferFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInboundOffers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        product
      }
      nextToken
    }
  }
`;
