import { AutoCompleteValue } from "../AutoCompleteFormField/AutoCompleteFormField";
import { AutoCompleteProductInformation } from "./InboundOfferForm";
import React from "react";

/**
 *
 */
export function getEmptyAutoCompleteValue(): AutoCompleteValue {
  return {
    id: "",
    val: "",
    err: false,
  };
}

/**
 *
 */
export function getEmptyAutoCompleteProductInformation(): AutoCompleteProductInformation {
  return {
    product: getEmptyAutoCompleteValue(),
    totalPrice: 0,
    purchasePrice: 0,
    shipping: 0,
  };
}

/**
 *
 * @param setProductInformation
 * @param index
 * @param value
 * @param key
 */
export function setProductInfoPrice(
  setProductInformation: React.Dispatch<
    React.SetStateAction<AutoCompleteProductInformation[]>
  >,
  index: number,
  value: number | string | undefined | null,
  key: keyof AutoCompleteProductInformation
) {
  if (!value) return;
  setProductInformation((prev) =>
    prev
      .filter((productInfo, i) => i !== index)
      .concat([
        {
          ...prev[index],
          [key]: Number(value),
        },
      ])
  );
}
