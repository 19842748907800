/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCompany = /* GraphQL */ `
  mutation CreateCompany(
    $input: CreateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    createCompany(input: $input, condition: $condition) {
      id
      typeOfContact
      name
      street1
      street2
      zip
      place
      country
      telephone
      fax
      type
      plz
      mail
      web
      vatNr
      comments
      paymentTerms
      createdAt
      contactPersons {
        nextToken
      }
      inboundInquiries {
        nextToken
      }
      suppliedOutboundOffers {
        nextToken
      }
      outboundOffers {
        nextToken
      }
      inboundOffers {
        nextToken
      }
      updatedAt
    }
  }
`;
export const updateCompany = /* GraphQL */ `
  mutation UpdateCompany(
    $input: UpdateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    updateCompany(input: $input, condition: $condition) {
      id
      typeOfContact
      name
      street1
      street2
      zip
      place
      country
      telephone
      fax
      type
      plz
      mail
      web
      vatNr
      comments
      paymentTerms
      createdAt
      contactPersons {
        nextToken
      }
      inboundInquiries {
        nextToken
      }
      suppliedOutboundOffers {
        nextToken
      }
      outboundOffers {
        nextToken
      }
      inboundOffers {
        nextToken
      }
      updatedAt
    }
  }
`;
export const deleteCompany = /* GraphQL */ `
  mutation DeleteCompany(
    $input: DeleteCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    deleteCompany(input: $input, condition: $condition) {
      id
      typeOfContact
      name
      street1
      street2
      zip
      place
      country
      telephone
      fax
      type
      plz
      mail
      web
      vatNr
      comments
      paymentTerms
      createdAt
      contactPersons {
        nextToken
      }
      inboundInquiries {
        nextToken
      }
      suppliedOutboundOffers {
        nextToken
      }
      outboundOffers {
        nextToken
      }
      inboundOffers {
        nextToken
      }
      updatedAt
    }
  }
`;
export const createContactPerson = /* GraphQL */ `
  mutation CreateContactPerson(
    $input: CreateContactPersonInput!
    $condition: ModelContactPersonConditionInput
  ) {
    createContactPerson(input: $input, condition: $condition) {
      id
      salutation
      title
      name
      surname
      telephone
      type
      mail
      comments
      createdAt
      companyName
      company {
        id
        typeOfContact
        name
        street1
        street2
        zip
        place
        country
        telephone
        fax
        type
        plz
        mail
        web
        vatNr
        comments
        paymentTerms
        createdAt
        updatedAt
      }
      inboundInquiries {
        nextToken
      }
      updatedAt
    }
  }
`;
export const updateContactPerson = /* GraphQL */ `
  mutation UpdateContactPerson(
    $input: UpdateContactPersonInput!
    $condition: ModelContactPersonConditionInput
  ) {
    updateContactPerson(input: $input, condition: $condition) {
      id
      salutation
      title
      name
      surname
      telephone
      type
      mail
      comments
      createdAt
      companyName
      company {
        id
        typeOfContact
        name
        street1
        street2
        zip
        place
        country
        telephone
        fax
        type
        plz
        mail
        web
        vatNr
        comments
        paymentTerms
        createdAt
        updatedAt
      }
      inboundInquiries {
        nextToken
      }
      updatedAt
    }
  }
`;
export const deleteContactPerson = /* GraphQL */ `
  mutation DeleteContactPerson(
    $input: DeleteContactPersonInput!
    $condition: ModelContactPersonConditionInput
  ) {
    deleteContactPerson(input: $input, condition: $condition) {
      id
      salutation
      title
      name
      surname
      telephone
      type
      mail
      comments
      createdAt
      companyName
      company {
        id
        typeOfContact
        name
        street1
        street2
        zip
        place
        country
        telephone
        fax
        type
        plz
        mail
        web
        vatNr
        comments
        paymentTerms
        createdAt
        updatedAt
      }
      inboundInquiries {
        nextToken
      }
      updatedAt
    }
  }
`;
export const createInboundInquiry = /* GraphQL */ `
  mutation CreateInboundInquiry(
    $input: CreateInboundInquiryInput!
    $condition: ModelInboundInquiryConditionInput
  ) {
    createInboundInquiry(input: $input, condition: $condition) {
      id
      lysisMember
      lysisEditor
      leadOrigin
      date
      product
      condition
      type
      inquiryComments
      status
      companyName
      company {
        id
        typeOfContact
        name
        street1
        street2
        zip
        place
        country
        telephone
        fax
        type
        plz
        mail
        web
        vatNr
        comments
        paymentTerms
        createdAt
        updatedAt
      }
      contactPerson {
        id
        salutation
        title
        name
        surname
        telephone
        type
        mail
        comments
        createdAt
        companyName
        updatedAt
      }
      outboundOffers {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateInboundInquiry = /* GraphQL */ `
  mutation UpdateInboundInquiry(
    $input: UpdateInboundInquiryInput!
    $condition: ModelInboundInquiryConditionInput
  ) {
    updateInboundInquiry(input: $input, condition: $condition) {
      id
      lysisMember
      lysisEditor
      leadOrigin
      date
      product
      condition
      type
      inquiryComments
      status
      companyName
      company {
        id
        typeOfContact
        name
        street1
        street2
        zip
        place
        country
        telephone
        fax
        type
        plz
        mail
        web
        vatNr
        comments
        paymentTerms
        createdAt
        updatedAt
      }
      contactPerson {
        id
        salutation
        title
        name
        surname
        telephone
        type
        mail
        comments
        createdAt
        companyName
        updatedAt
      }
      outboundOffers {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteInboundInquiry = /* GraphQL */ `
  mutation DeleteInboundInquiry(
    $input: DeleteInboundInquiryInput!
    $condition: ModelInboundInquiryConditionInput
  ) {
    deleteInboundInquiry(input: $input, condition: $condition) {
      id
      lysisMember
      lysisEditor
      leadOrigin
      date
      product
      condition
      type
      inquiryComments
      status
      companyName
      company {
        id
        typeOfContact
        name
        street1
        street2
        zip
        place
        country
        telephone
        fax
        type
        plz
        mail
        web
        vatNr
        comments
        paymentTerms
        createdAt
        updatedAt
      }
      contactPerson {
        id
        salutation
        title
        name
        surname
        telephone
        type
        mail
        comments
        createdAt
        companyName
        updatedAt
      }
      outboundOffers {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createOutboundOffer = /* GraphQL */ `
  mutation CreateOutboundOffer(
    $input: CreateOutboundOfferInput!
    $condition: ModelOutboundOfferConditionInput
  ) {
    createOutboundOffer(input: $input, condition: $condition) {
      id
      lysisMember
      date
      condition
      type
      warranty
      inquiryComments
      purchasePrice
      salesPrice
      margin
      companyName
      product
      offerStatus
      supplier {
        id
        typeOfContact
        name
        street1
        street2
        zip
        place
        country
        telephone
        fax
        type
        plz
        mail
        web
        vatNr
        comments
        paymentTerms
        createdAt
        updatedAt
      }
      inboundInquiry {
        id
        lysisMember
        lysisEditor
        leadOrigin
        date
        product
        condition
        type
        inquiryComments
        status
        companyName
        createdAt
        updatedAt
      }
      company {
        id
        typeOfContact
        name
        street1
        street2
        zip
        place
        country
        telephone
        fax
        type
        plz
        mail
        web
        vatNr
        comments
        paymentTerms
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateOutboundOffer = /* GraphQL */ `
  mutation UpdateOutboundOffer(
    $input: UpdateOutboundOfferInput!
    $condition: ModelOutboundOfferConditionInput
  ) {
    updateOutboundOffer(input: $input, condition: $condition) {
      id
      lysisMember
      date
      condition
      type
      warranty
      inquiryComments
      purchasePrice
      salesPrice
      margin
      companyName
      product
      offerStatus
      supplier {
        id
        typeOfContact
        name
        street1
        street2
        zip
        place
        country
        telephone
        fax
        type
        plz
        mail
        web
        vatNr
        comments
        paymentTerms
        createdAt
        updatedAt
      }
      inboundInquiry {
        id
        lysisMember
        lysisEditor
        leadOrigin
        date
        product
        condition
        type
        inquiryComments
        status
        companyName
        createdAt
        updatedAt
      }
      company {
        id
        typeOfContact
        name
        street1
        street2
        zip
        place
        country
        telephone
        fax
        type
        plz
        mail
        web
        vatNr
        comments
        paymentTerms
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteOutboundOffer = /* GraphQL */ `
  mutation DeleteOutboundOffer(
    $input: DeleteOutboundOfferInput!
    $condition: ModelOutboundOfferConditionInput
  ) {
    deleteOutboundOffer(input: $input, condition: $condition) {
      id
      lysisMember
      date
      condition
      type
      warranty
      inquiryComments
      purchasePrice
      salesPrice
      margin
      companyName
      product
      offerStatus
      supplier {
        id
        typeOfContact
        name
        street1
        street2
        zip
        place
        country
        telephone
        fax
        type
        plz
        mail
        web
        vatNr
        comments
        paymentTerms
        createdAt
        updatedAt
      }
      inboundInquiry {
        id
        lysisMember
        lysisEditor
        leadOrigin
        date
        product
        condition
        type
        inquiryComments
        status
        companyName
        createdAt
        updatedAt
      }
      company {
        id
        typeOfContact
        name
        street1
        street2
        zip
        place
        country
        telephone
        fax
        type
        plz
        mail
        web
        vatNr
        comments
        paymentTerms
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createInboundOffer = /* GraphQL */ `
  mutation CreateInboundOffer(
    $input: CreateInboundOfferInput!
    $condition: ModelInboundOfferConditionInput
  ) {
    createInboundOffer(input: $input, condition: $condition) {
      id
      type
      lysisMember
      date
      product
      condition
      inquiryComments
      currency
      purchasePrice
      warranty
      shipping
      totalPrice
      companyName
      company {
        id
        typeOfContact
        name
        street1
        street2
        zip
        place
        country
        telephone
        fax
        type
        plz
        mail
        web
        vatNr
        comments
        paymentTerms
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateInboundOffer = /* GraphQL */ `
  mutation UpdateInboundOffer(
    $input: UpdateInboundOfferInput!
    $condition: ModelInboundOfferConditionInput
  ) {
    updateInboundOffer(input: $input, condition: $condition) {
      id
      type
      lysisMember
      date
      product
      condition
      inquiryComments
      currency
      purchasePrice
      warranty
      shipping
      totalPrice
      companyName
      company {
        id
        typeOfContact
        name
        street1
        street2
        zip
        place
        country
        telephone
        fax
        type
        plz
        mail
        web
        vatNr
        comments
        paymentTerms
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteInboundOffer = /* GraphQL */ `
  mutation DeleteInboundOffer(
    $input: DeleteInboundOfferInput!
    $condition: ModelInboundOfferConditionInput
  ) {
    deleteInboundOffer(input: $input, condition: $condition) {
      id
      type
      lysisMember
      date
      product
      condition
      inquiryComments
      currency
      purchasePrice
      warranty
      shipping
      totalPrice
      companyName
      company {
        id
        typeOfContact
        name
        street1
        street2
        zip
        place
        country
        telephone
        fax
        type
        plz
        mail
        web
        vatNr
        comments
        paymentTerms
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
