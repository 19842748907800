import {
  CONFIRM_USER,
  EDIT_USER_INFORMATION,
  GET_USER_INFORMATION_COMPLETED,
  LOG_IN_USER,
  LOG_OUT_USER,
  SIGN_UP_USER,
  User,
  UserActionType,
} from "../types/user.types";

export function logInUser(user: User): UserActionType {
  return {
    type: LOG_IN_USER,
    payload: user,
  };
}

export function getUserInformationCompleted(): UserActionType {
  return {
    type: GET_USER_INFORMATION_COMPLETED,
  };
}

export function signUpUser(user: User): UserActionType {
  return {
    type: SIGN_UP_USER,
    payload: user,
  };
}

export function confirmUser(): UserActionType {
  return {
    type: CONFIRM_USER,
  };
}

export function logOutUser(): UserActionType {
  return {
    type: LOG_OUT_USER,
  };
}

export function editUserInformation(
  firstName: string,
  lastName: string
): UserActionType {
  return {
    type: EDIT_USER_INFORMATION,
    payload: {
      firstName: firstName,
      lastName: lastName,
    },
  };
}
