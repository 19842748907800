import { FormInstance } from "antd/lib/form";
import { InboundInquiry } from "../../store/types/api.types";

/**
 *
 * @param form
 */
export const calcMargin = (form: FormInstance<any>) => {
  const purchasePrice = parseFloat(form.getFieldValue("purchasePrice"))
    ? parseFloat(form.getFieldValue("purchasePrice"))
    : 0;
  const salesPrice = parseFloat(form.getFieldValue("salesPrice"))
    ? parseFloat(form.getFieldValue("salesPrice"))
    : 0;
  form.setFieldsValue({
    margin: salesPrice - purchasePrice,
  });
};

/**
 *
 * @param inboundInquiries
 * @param companyId
 */
export function getInboundInquiryOptions(
  inboundInquiries: InboundInquiry[],
  companyId: string
) {
  return inboundInquiries
    .filter(
      (inboundInquiry) =>
        (inboundInquiry.company.id === companyId || !companyId.length) &&
        inboundInquiry.status === "open"
    )
    .map((i: InboundInquiry) => ({
      value: `${i.product} / ${i.date} / ${i?.company?.name}`,
      id: i.id,
    }));
}
