import React, { useEffect, useState } from "react";
import { Input } from "antd";
import { getValidPlaceholderForSearchBar } from "./model";
import { useLocation } from "react-router-dom";
import { getValidForm } from "../../pages/DomainPage/utils";
import { useTranslation } from "react-i18next";

interface SearchBarProps {
  searchTerm: string;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
}

export const SearchBar: React.FC<SearchBarProps> = ({
  searchTerm,
  setSearchTerm,
}) => {
  const { pathname } = useLocation();
  const [currentSearchTerm, setCurrentSearchTerm] = useState(searchTerm);
  const entityType = getValidForm(pathname);
  const { t } = useTranslation();

  useEffect(() => {
    setSearchTerm("");
  }, [pathname]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length) {
      setCurrentSearchTerm(event.target.value);
    } else {
      setCurrentSearchTerm("");
      search("");
    }
  };

  const search = (value: string) => {
    setSearchTerm(value);
  };

  return (
    <Input.Search
      id="search-bar"
      value={currentSearchTerm}
      onChange={handleChange}
      onSearch={() => search(currentSearchTerm)}
      allowClear
      size="large"
      placeholder={getValidPlaceholderForSearchBar(entityType, t)}
    />
  );
};
