import React, { PropsWithChildren } from "react";
import { Modal, Button } from "antd";
import { useTranslation } from "react-i18next";

type Props = PropsWithChildren<{
  visible: boolean;
  onCancel?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  title: string | undefined;
  formId?: string;
}>;

export enum Entity {
  COMPANY,
  CONTACT_PERSON,
  INBOUND_OFFER,
  OUTBOUND_OFFER,
  INBOUND_INQUIRY,
}

export const CreateEntityModal: React.FC<Props> = ({
  visible,
  onCancel,
  children,
  title,
  formId,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      visible={visible}
      title={t(title || "")}
      onCancel={onCancel}
      footer={[
        <Button onClick={onCancel}>{t("Cancel")}</Button>,
        <Button form={formId} type="primary" htmlType="submit">
          {t("Submit")}
        </Button>,
      ]}
    >
      {children}
    </Modal>
  );
};
