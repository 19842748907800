import { useEffect } from "react";
import { FormInstance } from "antd/lib/form";
import { InboundInquiry, OutboundOffer } from "../../store/types/api.types";
import { AutoCompleteValue } from "../AutoCompleteFormField/AutoCompleteFormField";
import { getWarranty } from "../AutoCompleteFormField/AutoCompleteWarrantyField";
import { setFormValues } from "../../store/actions/api.actions";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import format from "date-fns/format";
import * as queries from "../../graphql/queries";
import { getSingleDataEntryFromApi } from "../../graphql/model.graphql";
import { OutboundOfferFormFields } from "./OutboundOfferForm";

/**
 *
 * @param form
 * @param setAutoCompleteFields
 */
export function useOutboundOfferPrefill(
  form: FormInstance<OutboundOfferFormFields>,
  setAutoCompleteFields: (
    company?: AutoCompleteValue,
    warranty?: AutoCompleteValue,
    condition?: AutoCompleteValue,
    inboundInquiry?: AutoCompleteValue,
    supplier?: AutoCompleteValue
  ) => void
) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const shouldPrefillForm: boolean = useSelector(
    (state: RootStateOrAny) => state.apiReducer.edit.setFormValues
  );
  const userEmail: string = useSelector(
    (state: RootStateOrAny) => state.userReducer.user.attributes.email
  );
  const currentSelectedOutboundOffer: OutboundOffer = useSelector(
    (state: RootStateOrAny) => state.apiReducer.edit.currSelectedItem
  );

  useEffect(() => {
    if (!form.getFieldValue("lysisMember")) {
      form.setFieldsValue({
        lysisMember: userEmail,
      });
    }
  }, [form.getFieldValue("lysisMember")]);

  useEffect(() => {
    if (!shouldPrefillForm) {
      form.setFieldsValue({
        date: format(new Date(), "yyyy-MM-dd"),
      });
    }
  }, []);

  useEffect(() => {
    if (currentSelectedOutboundOffer && shouldPrefillForm) {
      form.setFieldsValue({
        lysisMember: currentSelectedOutboundOffer.lysisMember,
        date: currentSelectedOutboundOffer.date,
        product: currentSelectedOutboundOffer.product,
        condition: currentSelectedOutboundOffer.condition,
        inquiryComments: currentSelectedOutboundOffer.inquiryComments,
        warranty: currentSelectedOutboundOffer.warranty,
        margin: currentSelectedOutboundOffer.margin,
        salesPrice: currentSelectedOutboundOffer.salesPrice,
        purchasePrice: currentSelectedOutboundOffer.purchasePrice,
        outboundOfferCompanyId: currentSelectedOutboundOffer.company.id,
        outboundOfferSupplierId:
          currentSelectedOutboundOffer.supplier?.id || "",
        outboundOfferInboundInquiryId:
          currentSelectedOutboundOffer.inboundInquiry?.id,
        inboundInquiryComments:
          currentSelectedOutboundOffer.inboundInquiry?.inquiryComments || "/",
      });
      const company: AutoCompleteValue = {
        val: currentSelectedOutboundOffer?.company.name,
        id: currentSelectedOutboundOffer?.company?.id,
        err: false,
      };
      const warranty: AutoCompleteValue = {
        val: getWarranty(t, currentSelectedOutboundOffer?.warranty),
        id: currentSelectedOutboundOffer?.warranty,
        err: false,
      };
      const condition: AutoCompleteValue = {
        val: currentSelectedOutboundOffer?.condition,
        id: currentSelectedOutboundOffer?.condition,
        err: false,
      };
      const inboundInquiry: AutoCompleteValue = {
        val: `${currentSelectedOutboundOffer?.inboundInquiry?.product} / ${currentSelectedOutboundOffer?.date} / ${currentSelectedOutboundOffer?.company?.name}`,
        id: currentSelectedOutboundOffer.inboundInquiry?.id || "",
        err: false,
      };
      const supplier: AutoCompleteValue = {
        val: currentSelectedOutboundOffer.supplier?.name || "",
        id: currentSelectedOutboundOffer.supplier?.id || "",
        err: false,
      };
      setAutoCompleteFields(
        company,
        warranty,
        condition,
        inboundInquiry,
        supplier
      );
      dispatch(setFormValues(false));
    }
  });

  return { currentSelectedOutboundOffer };
}

/**
 *
 * @param form
 * @param inboundInquiries
 * @param selectedInboundInquiry
 */
export function useInboundInquiryChanges(
  form: FormInstance<OutboundOfferFormFields>,
  inboundInquiries: InboundInquiry[],
  selectedInboundInquiry: AutoCompleteValue
) {
  const getInboundInquiry = async (inboundInquiryId: string) => {
    if (!inboundInquiryId.length) return;
    return (
      await getSingleDataEntryFromApi({
        query: queries.getInboundInquiry,
        id: inboundInquiryId,
      })
    ).data.getInboundInquiry as InboundInquiry;
  };

  useEffect(() => {
    getInboundInquiry(selectedInboundInquiry.id).then((inboundInquiry) => {
      form.setFieldsValue({
        inboundInquiryComments: inboundInquiry?.inquiryComments || "/",
        mailContactPersonInboundInquiry:
          inboundInquiry?.contactPerson?.mail || "",
      });
    });
  }, [selectedInboundInquiry]);
}
