import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import {
  Button,
  Input,
  Card,
  Layout,
  Form,
  Col,
  Row,
  notification,
} from "antd";
import { Auth } from "aws-amplify";
import { editUserInformation } from "../../store/actions/user.actions";
import { signOut } from "../../components/SideBar/SideBar";

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16 },
};

export const ProfilePage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const userInformation = useSelector(
    (state: RootStateOrAny) => state.userReducer.user
  );

  useEffect(() => {
    form.setFieldsValue({
      email: userInformation.attributes.email,
      firstName: userInformation.attributes.name,
      lastName: userInformation.attributes.family_name,
    });
  }, []);

  const editUserAttributes = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const firstName = form.getFieldValue("firstName");
    const lastName = form.getFieldValue("lastName");
    try {
      await Auth.updateUserAttributes(user, {
        name: firstName,
        family_name: lastName,
      });
      dispatch(editUserInformation(firstName, lastName));
    } catch (e) {
      notification.open({
        message: t("Error Occured"),
        type: "error",
      });
    }
  };

  return (
    <div>
      <h2>{t("Profile")}</h2>
      <Layout.Content style={{ marginTop: "20px" }}>
        <Card title="Edit User Information">
          <Form {...layout} form={form}>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  required: true,
                  message: t("Please enter an email"),
                },
              ]}
            >
              <Input readOnly type="email" />
            </Form.Item>
            <Form.Item name="firstName" label={t("First Name")}>
              <Input onBlur={editUserAttributes} />
            </Form.Item>
            <Form.Item name="lastName" label={t("Last Name")}>
              <Input onBlur={editUserAttributes} />
            </Form.Item>
          </Form>
        </Card>
        <Row justify="center" style={{ marginTop: "20px" }}>
          <Col xs={12} sm={6}>
            <Button
              type="primary"
              style={{ width: "100%" }}
              onClick={() => signOut(dispatch)}
            >
              {t("Sign Out")}
            </Button>
          </Col>
        </Row>
      </Layout.Content>
    </div>
  );
};
