import { FormInstance } from "antd/lib/form";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { InboundOffer } from "../../store/types/api.types";
import { useEffect } from "react";
import { AutoCompleteValue } from "../AutoCompleteFormField/AutoCompleteFormField";
import { getWarranty } from "../AutoCompleteFormField/AutoCompleteWarrantyField";
import { setFormValues } from "../../store/actions/api.actions";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";

/**
 *
 * @param form
 * @param setAutoCompleteFields
 */
export function useInboundOfferPrefill(
  form: FormInstance<any>,
  setAutoCompleteFields: (
    company?: AutoCompleteValue,
    warranty?: AutoCompleteValue,
    condition?: AutoCompleteValue,
    currency?: AutoCompleteValue,
    product?: AutoCompleteValue
  ) => void
) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const shouldPrefillForm: boolean = useSelector(
    (state: RootStateOrAny) => state.apiReducer.edit.setFormValues
  );
  const userEmail: string = useSelector(
    (state: RootStateOrAny) => state.userReducer.user.attributes.email
  );
  const currentSelectedInboundOffer: InboundOffer = useSelector(
    (state: RootStateOrAny) => state.apiReducer.edit.currSelectedItem
  );

  /*
   * sets the email of the current user as lysisMember
   */
  useEffect(() => {
    if (!form.getFieldValue("lysisMember")) {
      form.setFieldsValue({
        lysisMember: userEmail,
      });
    }
  }, [form.getFieldValue("lysisMember")]);

  /**
   * sets default values for date and currency
   */
  useEffect(() => {
    if (!shouldPrefillForm) {
      form.setFieldsValue({
        date: format(new Date(), "yyyy-MM-dd"),
        currency: "euro",
      });
    }
  }, []);

  /*
   * If an inboundOffer is updated this useEffect prefills the form
   * with the right values
   */
  useEffect(() => {
    if (currentSelectedInboundOffer && shouldPrefillForm) {
      form.setFieldsValue({
        lysisMember: currentSelectedInboundOffer.lysisMember,
        date: currentSelectedInboundOffer.date,
        product0: currentSelectedInboundOffer.product,
        condition: currentSelectedInboundOffer.condition,
        inquiryComments: currentSelectedInboundOffer.inquiryComments,
        currency: currentSelectedInboundOffer.currency,
        shipping0: currentSelectedInboundOffer.shipping,
        totalPrice0: currentSelectedInboundOffer.totalPrice,
        warranty: currentSelectedInboundOffer.warranty,
        companyId: currentSelectedInboundOffer.company?.id,
        purchasePrice0: currentSelectedInboundOffer.purchasePrice,
      });
      const company: AutoCompleteValue = {
        val: currentSelectedInboundOffer?.company.name,
        id: currentSelectedInboundOffer?.company?.id,
        err: false,
      };
      const warranty: AutoCompleteValue = {
        val: getWarranty(t, currentSelectedInboundOffer?.warranty),
        id: currentSelectedInboundOffer?.warranty,
        err: false,
      };
      const condition: AutoCompleteValue = {
        val: currentSelectedInboundOffer?.condition,
        id: currentSelectedInboundOffer?.condition,
        err: false,
      };
      const currency: AutoCompleteValue = {
        val: currentSelectedInboundOffer?.currency,
        id: currentSelectedInboundOffer?.currency,
        err: false,
      };
      const product: AutoCompleteValue = {
        val: currentSelectedInboundOffer.product,
        id: currentSelectedInboundOffer.product,
        err: false,
      };
      setAutoCompleteFields(company, warranty, condition, currency, product);
      dispatch(setFormValues(false));
    }
  });

  return { currentSelectedInboundOffer };
}
