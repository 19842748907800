import { API } from "aws-amplify";
import { SortKeyValues, sortEntity } from "../../components/Feed/model";
import { Routes } from "../../components/NavBar/routes";
import {
  errorOccured,
  filterCompanies,
  filterContactPersons,
  filterInboundInquiries,
  filterInboundOffers,
  filterOutboundOffers,
} from "../../store/actions/api.actions";
import { EntityForm } from "../DashboardPage/DashboardPage";
import { GraphQLResult } from "@aws-amplify/api";
import * as queries from "../../graphql/queries";
import { Dispatch } from "react";
import {
  ApiActionType,
  ApiState,
  Company,
  ContactPerson,
  InboundInquiry,
  InboundOffer,
  OutboundOffer,
} from "../../store/types/api.types";

/**
 * function that returns the Type of Form
 * that the modal should open
 */
export const getValidForm = (name: string): EntityForm | undefined => {
  if (name === Routes.InboundInquiries) return EntityForm.INBOUND_INQUIRY;
  if (name === Routes.InboundOffers) return EntityForm.INBOUND_OFFER;
  if (name === Routes.OutboundOffers) return EntityForm.OUTBOUND_OFFER;
  if (name === Routes.ContactPersons) return EntityForm.CONTACT_PERSON;
  if (name === Routes.Companies) return EntityForm.COMPANY;
};

export const getEntityKey = (name: string): keyof ApiState | undefined => {
  if (name === Routes.InboundInquiries) return "inboundInquiries";
  if (name === Routes.InboundOffers) return "inboundOffers";
  if (name === Routes.OutboundOffers) return "outboundOffers";
  if (name === Routes.ContactPersons) return "contactPersons";
  if (name === Routes.Companies) return "companies";
};

export function getFormType(entity?: EntityForm) {
  switch (entity) {
    case EntityForm.CONTACT_PERSON:
      return "Contact Person";
    case EntityForm.INBOUND_INQUIRY:
      return "Inbound Inquiry";
    case EntityForm.INBOUND_OFFER:
      return "Inbound Offer";
    case EntityForm.OUTBOUND_OFFER:
      return "Outbound Offer";
    case EntityForm.COMPANY:
      return "Company";
    default:
      return "";
  }
}

/**
 * function that returns the right header for
 * a particular form
 */
export const getHeader = (pathname: string) => {
  const header = getValidForm(pathname);
  return header || "";
};

/**
 * function that returns the keys by which a
 * particular Entity can be filtered / sorted
 */
export const getKeys = (
  entity: EntityForm | undefined
): Array<SortKeyValues> => {
  switch (entity) {
    case EntityForm.INBOUND_INQUIRY:
      return [
        "updatedAt",
        "lysisMember",
        "product",
        "date",
        "status",
        "condition",
      ];
    case EntityForm.OUTBOUND_OFFER:
      return [
        "updatedAt",
        "lysisMember",
        "date",
        "salesPrice",
        "warranty",
        "margin",
        "purchasePrice",
        "condition",
      ];
    case EntityForm.INBOUND_OFFER:
      return [
        "updatedAt",
        "lysisMember",
        "product",
        "date",
        "currency",
        "warranty",
        "shipping",
        "totalPrice",
        "purchasePrice",
        "condition",
      ];
  }
  return [];
};

/**
 * sorts a particular Entity by "sortByKey" in ascending
 * or descending order and stores the data to redux
 */
export const sortEntities = async (
  entityType: EntityForm | undefined,
  sortByKey: SortKeyValues,
  order: "asc" | "desc",
  dispatch: Dispatch<ApiActionType>,
  finishedLoading: () => void
) => {
  switch (entityType) {
    case EntityForm.INBOUND_INQUIRY:
      await (
        API.graphql({
          query: queries.listInboundInquirys,
        }) as Promise<GraphQLResult<any>>
      )
        .then((response) => {
          const inboundInquiries = sortEntity(
            response.data.listInboundInquirys.items,
            sortByKey,
            order
          ) as Array<InboundInquiry>;
          dispatch(filterInboundInquiries(inboundInquiries));
        })
        .catch((e) =>
          dispatch(errorOccured("Error Occured Loading Inbound Inquiries"))
        );
      break;
    case EntityForm.INBOUND_OFFER:
      await (
        API.graphql({
          query: queries.listInboundOffers,
        }) as Promise<GraphQLResult<any>>
      )
        .then((response) => {
          const inboundOffers = sortEntity(
            response.data.listInboundOffers.items,
            sortByKey,
            order
          ) as Array<InboundOffer>;
          dispatch(filterInboundOffers(inboundOffers));
        })
        .catch((e) =>
          dispatch(errorOccured("Error Occured Loading Inbound Offers"))
        );
      break;
    case EntityForm.OUTBOUND_OFFER:
      await (
        API.graphql({
          query: queries.listOutboundOffers,
        }) as Promise<GraphQLResult<any>>
      )
        .then((response) => {
          const outboundOffers = sortEntity(
            response.data.listOutboundOffers.items,
            sortByKey,
            order
          ) as Array<OutboundOffer>;
          dispatch(filterOutboundOffers(outboundOffers));
        })
        .catch((e) =>
          dispatch(errorOccured("Error Occured Loading Outbound Offers"))
        );
      break;
    case EntityForm.COMPANY:
      await (
        API.graphql({
          query: queries.listCompanys,
        }) as Promise<GraphQLResult<any>>
      )
        .then((response) => {
          const companies = sortEntity(
            response.data.listCompanys.items,
            sortByKey,
            order
          ) as Array<Company>;
          dispatch(filterCompanies(companies));
        })
        .catch((e) => dispatch(errorOccured("Companies")));
      break;
    case EntityForm.CONTACT_PERSON:
      await (
        API.graphql({
          query: queries.listContactPersons,
        }) as Promise<GraphQLResult<any>>
      )
        .then((response) => {
          const contactPersons = sortEntity(
            response.data.listContactPersons.items,
            sortByKey,
            order
          ) as Array<ContactPerson>;
          dispatch(filterContactPersons(contactPersons));
        })
        .catch((e) =>
          dispatch(errorOccured("Error Occured Loading Contact Persons"))
        );
  }
  finishedLoading();
};
