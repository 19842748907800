import {
  ContactPersonVariablesCreate,
  ContactPersonVariablesUpdate,
} from "../../graphql/type.graphql";
import {
  createEntity,
  getCompany,
  updateEntity,
} from "../../graphql/model.graphql";
import * as mutations from "../../graphql/mutations";

/**
 *
 * @param values
 */
export async function updateContactPerson(
  values: ContactPersonVariablesUpdate
) {
  const company = await getCompany(values.contactPersonCompanyId);
  await updateEntity({
    mutation: mutations.updateContactPerson,
    variables: { ...values, companyName: company?.name || "" },
  });
}

/**
 *
 * @param values
 */
export async function createContactPerson(
  values: ContactPersonVariablesCreate
) {
  const company = await getCompany(values.contactPersonCompanyId);
  await createEntity({
    mutation: mutations.createContactPerson,
    variables: {
      ...values,
      type: "contactPerson",
      companyName: company?.name || "",
    },
  });
}
