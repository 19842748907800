import React, { useState } from "react";
import { Feed } from "../../components/Feed/Feed";
import { SearchBar } from "../../components/SearchBar/SearchBar";
import { Row, Col, Button, Layout, Grid } from "antd";
import { CreateEntityModal } from "../../components/CreateEntityModal/CreateEntityModal";
import CompanyForm from "../../components/CompanyForm/CompanyForm";
import ConatctPersonForm from "../../components/ContactPersonForm/ContactPersonForm";
import InboundOfferForm from "../../components/InboundOfferForm/InboundOfferForm";
import InboundInquiryForm from "../../components/InboundInquiryForm/InboundInquiryForm";
import OutboundOfferForm from "../../components/OutboundOfferForm/OutboundOfferForm";
import { useTranslation } from "react-i18next";
import { Editor } from "../../components/Editor/Editor";

export enum EntityForm {
  COMPANY = "Add Partner",
  CONTACT_PERSON = "Add Contact Person",
  INBOUND_OFFER = "Add Inbound Offer",
  INBOUND_INQUIRY = "Add Inbound Inquiry",
  OUTBOUND_OFFER = "Add Outbound Offer",
}

export interface EntityFormState {
  visible: boolean;
  form: EntityForm | undefined;
}

const { useBreakpoint } = Grid;

export const DashboardPage = () => {
  const { t } = useTranslation();
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const screens = useBreakpoint();
  const [entityModalState, setEntityModalState] = useState<EntityFormState>({
    visible: false,
    form: undefined,
  });
  const formId = entityModalState.form?.toLowerCase().replaceAll(" ", "-");
  const [searchTerm, setSearchTerm] = useState("");
  const fontSize = screens.xs ? "11px" : "";
  const paddingLeftRight = screens.xs ? "0 2px" : "";

  const openModal = (form: EntityForm) => {
    setEntityModalState({ visible: true, form: form });
  };

  const closeModal = () => {
    setEntityModalState((prev) => ({
      visible: false,
      form: prev.form,
    }));
  };

  return (
    <>
      <div style={{ display: isEditorOpen ? "block" : "none" }}>
        {isEditorOpen && (
          <Editor form={"Inbound Inquiry"} setIsEditorOpen={setIsEditorOpen} />
        )}
      </div>
      <div style={{ display: isEditorOpen ? "none" : "block" }}>
        <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
        <Row
          gutter={[14, 14]}
          justify="center"
          align="middle"
          style={{ marginTop: 20, marginBottom: 20 }}
        >
          <Col span={12}>
            <Button
              type="primary"
              onClick={() => openModal(EntityForm.INBOUND_OFFER)}
              size="large"
              style={{
                width: "100%",
                fontSize,
                padding: paddingLeftRight,
              }}
            >
              {t(EntityForm.INBOUND_OFFER)}
            </Button>
          </Col>
          <Col span={12}>
            <Button
              onClick={() => openModal(EntityForm.INBOUND_INQUIRY)}
              type="primary"
              size="large"
              style={{ width: "100%", fontSize, padding: paddingLeftRight }}
            >
              {t(EntityForm.INBOUND_INQUIRY)}
            </Button>
          </Col>
          <Col span={12}>
            <Button
              onClick={() => openModal(EntityForm.COMPANY)}
              type="primary"
              size="large"
              style={{ width: "100%", fontSize, padding: paddingLeftRight }}
            >
              {t(EntityForm.COMPANY)}
            </Button>
          </Col>
          <Col span={12}>
            <Button
              onClick={() => openModal(EntityForm.CONTACT_PERSON)}
              type="primary"
              size="large"
              style={{ width: "100%", fontSize, padding: paddingLeftRight }}
            >
              {t(EntityForm.CONTACT_PERSON)}
            </Button>
          </Col>
        </Row>
        {entityModalState.visible && (
          <CreateEntityModal
            visible={entityModalState.visible}
            title={entityModalState.form}
            formId={formId}
            onCancel={() =>
              setEntityModalState((prev) => ({
                visible: false,
                form: prev.form,
              }))
            }
          >
            {entityModalState.form === EntityForm.COMPANY && (
              <CompanyForm type="add" closeModal={closeModal} formId={formId} />
            )}
            {entityModalState.form === EntityForm.CONTACT_PERSON && (
              <ConatctPersonForm
                type="add"
                closeModal={closeModal}
                formId={formId}
              />
            )}
            {entityModalState.form === EntityForm.INBOUND_OFFER && (
              <InboundOfferForm
                type="add"
                closeModal={closeModal}
                formId={formId}
              />
            )}
            {entityModalState.form === EntityForm.OUTBOUND_OFFER && (
              <OutboundOfferForm
                type="add"
                closeModal={closeModal}
                formId={formId}
              />
            )}
            {entityModalState.form === EntityForm.INBOUND_INQUIRY && (
              <InboundInquiryForm
                type="add"
                closeModal={closeModal}
                formId={formId}
              />
            )}
          </CreateEntityModal>
        )}
        <Layout.Content style={{ textAlign: "center", marginTop: 20 }}>
          <Feed
            setIsEditorOpen={setIsEditorOpen}
            isEditorOpen={isEditorOpen}
            searchTerm={searchTerm}
          />
        </Layout.Content>
      </div>
    </>
  );
};
