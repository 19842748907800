import { Auth } from "aws-amplify";
import { notification } from "antd";
import { TFunction } from "i18next";

interface FormFields {
  email: string;
  password: string;
  confirm: string;
  confirmationCode: string;
}

export interface ChangePasswordStatus {
  email: string;
  sendVerificationCode: "error" | "finish" | "process" | "wait";
  VerificationCodeEntered: "error" | "finish" | "process" | "wait";
  done: "error" | "finish" | "process" | "wait";
}

export const sendVerificationCode = async (
  values: FormFields,
  setSteps: React.Dispatch<React.SetStateAction<ChangePasswordStatus>>,
  t: TFunction
) => {
  const { email } = values;
  await Auth.forgotPassword(email)
    .then((response) => {
      notification.open({
        message: t("Confirmation Code Send!"),
        type: "success",
      });
      setSteps({
        email: email,
        sendVerificationCode: "finish",
        VerificationCodeEntered: "process",
        done: "wait",
      });
    })
    .catch((err) => {
      notification.open({
        message: "Please try again",
        type: "error",
      });
      setSteps({
        email: email,
        sendVerificationCode: "error",
        VerificationCodeEntered: "wait",
        done: "wait",
      });
    });
};

export const setNewPassword = (
  values: FormFields,
  setSteps: React.Dispatch<React.SetStateAction<ChangePasswordStatus>>,
  t: TFunction
) => {
  Auth.forgotPasswordSubmit(
    values.email,
    values.confirmationCode,
    values.password
  )
    .then((response) => {
      notification.open({
        message: t("Password sucessfully changed"),
        type: "success",
      });
      setSteps({
        email: values.email,
        VerificationCodeEntered: "finish",
        done: "process",
        sendVerificationCode: "finish",
      });
    })
    .catch((err) => {
      console.log(err);
      notification.open({
        message: "Please try again",
        type: "error",
      });
      setSteps({
        email: values.email,
        sendVerificationCode: "error",
        VerificationCodeEntered: "wait",
        done: "wait",
      });
    });
};
