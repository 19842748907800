import React from "react";
import { useTranslation } from "react-i18next";
import {
  AutoCompleteFormField,
  AutoCompleteValue,
} from "./AutoCompleteFormField";

interface Props {
  selectedCondition: AutoCompleteValue;
  setSelectedCondition: React.Dispatch<React.SetStateAction<AutoCompleteValue>>;
  reset?: boolean;
  setFormValue: (value: any) => void;
}

export const AutoCompleteConditionFormField: React.FC<Props> = ({
  selectedCondition,
  setSelectedCondition,
  reset,
  setFormValue,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <AutoCompleteFormField
        reset={reset}
        formFieldName="condition"
        setFormValue={setFormValue}
        options={[
          { value: t("New"), id: "new" },
          { value: t("Used"), id: "used" },
          { value: t("Defekt"), id: "defekt" },
          { value: t("Any"), id: "any" },
        ]}
        value={selectedCondition}
        setValue={setSelectedCondition}
      />
    </>
  );
};
