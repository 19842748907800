import React, { memo, useEffect, useLayoutEffect, useState } from "react";
import { Button, Form, Input, Row, Col, notification, InputNumber } from "antd";
import { useTranslation } from "react-i18next";
import { Company } from "../../store/types/api.types";
import { CreateEntityModal } from "../CreateEntityModal/CreateEntityModal";
import CompanyForm from "../CompanyForm/CompanyForm";
import InboundInquiryForm from "../InboundInquiryForm/InboundInquiryForm";
import {
  calcMargin,
  createOutboundOffer,
  updateOutboundOffer,
} from "./model.outboundOfferForm";
import {
  AutoCompleteFormField,
  AutoCompleteValue,
} from "../AutoCompleteFormField/AutoCompleteFormField";
import { AutoCompleteConditionFormField } from "../AutoCompleteFormField/AutoCompleteConditionField";
import { AutoCompleteWarrantyFormField } from "../AutoCompleteFormField/AutoCompleteWarrantyField";
import { useAllCompanies, useAllOpenInboundInquiries } from "../Feed/hook.feed";
import {
  useInboundInquiryChanges,
  useOutboundOfferPrefill,
} from "./hook.outboundOfferForm";
import { getEmptyAutoCompleteValue } from "../InboundOfferForm/model.inboundOfferForm";
import { OutboundOfferVariablesUpdate } from "../../graphql/type.graphql";
import { offerInboundInquiry } from "../InboundInquiryForm/model.inboundInquiryForm";
import { getInboundInquiryOptions } from "./utils";
import { EntityForm } from "../../pages/DashboardPage/DashboardPage";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export interface OutboundOfferFormFields extends OutboundOfferVariablesUpdate {
  inboundInquiryComments: string;
  mailContactPersonInboundInquiry: string;
}

interface Props {
  formId?: string;
  closeModal?: () => void;
  resetAutoCompleteFields?: boolean;
  type?: "update" | "add";
  inboundInquiryAutoComplete?: AutoCompleteValue;
  companyAutoComplete?: AutoCompleteValue;
}

interface FormState {
  companyForm: boolean;
  inboundInquiryForm: boolean;
}

export default memo<Props>(
  ({
    formId,
    closeModal,
    type,
    resetAutoCompleteFields,
    inboundInquiryAutoComplete,
    companyAutoComplete,
  }) => {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const { entities: companies } = useAllCompanies();
    const { entities: inboundInquiries } = useAllOpenInboundInquiries();
    const [selectedInboundInquiry, setSelectedInboundInquiry] =
      useState<AutoCompleteValue>(getEmptyAutoCompleteValue());
    const [selectedCompany, setSelectedCompany] = useState<AutoCompleteValue>(
      getEmptyAutoCompleteValue()
    );
    const [selectedCondition, setSelectedCondition] =
      useState<AutoCompleteValue>(getEmptyAutoCompleteValue());
    const [selectedWarranty, setSelectedWarranty] = useState<AutoCompleteValue>(
      getEmptyAutoCompleteValue()
    );
    const [selectedSupplier, setSelectedSupplier] = useState<AutoCompleteValue>(
      getEmptyAutoCompleteValue()
    );
    const [showForm, setShowForm] = useState<FormState>({
      companyForm: false,
      inboundInquiryForm: false,
    });
    useInboundInquiryChanges(form, inboundInquiries, selectedInboundInquiry);

    const setAutoCompleteFields = (
      company?: AutoCompleteValue,
      warranty?: AutoCompleteValue,
      condition?: AutoCompleteValue,
      inboundInquiry?: AutoCompleteValue,
      supplier?: AutoCompleteValue
    ) => {
      setSelectedCompany(company || getEmptyAutoCompleteValue());
      setSelectedWarranty(warranty || getEmptyAutoCompleteValue());
      setSelectedCondition(condition || getEmptyAutoCompleteValue());
      setSelectedInboundInquiry(inboundInquiry || getEmptyAutoCompleteValue());
      setSelectedSupplier(supplier || getEmptyAutoCompleteValue());
    };

    const { currentSelectedOutboundOffer } = useOutboundOfferPrefill(
      form,
      setAutoCompleteFields
    );

    useEffect(() => {
      return () => resetForm();
    }, []);

    useLayoutEffect(() => {
      if (inboundInquiryAutoComplete) {
        setSelectedInboundInquiry(inboundInquiryAutoComplete);
        form.setFieldsValue({
          outboundOfferInboundInquiryId: inboundInquiryAutoComplete.id,
        });
      }
      if (companyAutoComplete) {
        setSelectedCompany(companyAutoComplete);
        form.setFieldsValue({
          outboundOfferCompanyId: companyAutoComplete.id,
        });
      }
    }, [inboundInquiryAutoComplete, companyAutoComplete]);

    const handleCreate = async (values: OutboundOfferFormFields) => {
      await createOutboundOffer(values);
      await offerInboundInquiry(values.outboundOfferInboundInquiryId);
      resetForm();
      closeModal?.();
    };

    const handleUpdate = async (values: OutboundOfferFormFields) => {
      values.id = currentSelectedOutboundOffer.id;
      await updateOutboundOffer(values);
      resetForm();
      closeModal?.();
      notification.open({
        message: t("Updated Successfully"),
        type: "success",
      });
    };

    const resetForm = () => {
      form.resetFields();
      setAutoCompleteFields();
    };

    return (
      <Form
        style={{ width: "100%" }}
        onValuesChange={() => calcMargin(form)}
        onFinish={type === "add" ? handleCreate : handleUpdate}
        {...layout}
        id={formId}
        form={form}
      >
        <Form.Item name="lysisMember" label="Lysis Member">
          <Input readOnly type="text" />
        </Form.Item>
        <Form.Item name="date" label={t("Date")}>
          <Input type="date" />
        </Form.Item>
        <Form.Item
          label={t("Contact")}
          name="outboundOfferCompanyId"
          validateStatus={selectedCompany.err ? "error" : "success"}
          rules={[
            {
              required: true,
              message: t("There is no company with this name"),
            },
          ]}
        >
          <Row>
            <Col span={20}>
              <AutoCompleteFormField
                reset={resetAutoCompleteFields}
                formFieldName="outboundOfferCompanyId"
                setFormValue={form.setFieldsValue}
                options={companies.map((c: Company) => ({
                  value: c.name,
                  id: c.id,
                }))}
                value={selectedCompany}
                setValue={setSelectedCompany}
              />
            </Col>
            <Col style={{ textAlign: "right" }} span={4}>
              <Button
                shape="circle"
                onClick={() =>
                  setShowForm({ companyForm: true, inboundInquiryForm: false })
                }
              >
                +
              </Button>
            </Col>
            <CreateEntityModal
              formId="company-form-id"
              onCancel={() =>
                setShowForm({ companyForm: false, inboundInquiryForm: false })
              }
              visible={showForm.companyForm}
              title={t(EntityForm.COMPANY)}
            >
              <CompanyForm
                closeModal={() =>
                  setShowForm({ companyForm: false, inboundInquiryForm: true })
                }
                type="add"
                formId="company-form-id"
              />
            </CreateEntityModal>
          </Row>
        </Form.Item>
        <Form.Item
          label={t("Inbound Inquiry")}
          name="outboundOfferInboundInquiryId"
          validateStatus={selectedInboundInquiry.err ? "error" : "success"}
          rules={[
            {
              required: true,
              message: t("Please select an Inbound Inquiry"),
            },
          ]}
        >
          <Row>
            <Col span={20}>
              <AutoCompleteFormField
                reset={resetAutoCompleteFields}
                formFieldName="outboundOfferInboundInquiryId"
                setFormValue={form.setFieldsValue}
                options={getInboundInquiryOptions(
                  inboundInquiries,
                  selectedCompany.id
                )}
                value={selectedInboundInquiry}
                setValue={setSelectedInboundInquiry}
              />
            </Col>
            <Col span={4} style={{ textAlign: "right" }}>
              <Button
                shape="circle"
                onClick={() =>
                  setShowForm({ companyForm: false, inboundInquiryForm: true })
                }
              >
                +
              </Button>
            </Col>
            <CreateEntityModal
              formId="ibound-inquiry-form-id"
              onCancel={() =>
                setShowForm({ companyForm: false, inboundInquiryForm: false })
              }
              visible={showForm.inboundInquiryForm}
              title="Add Inbound Inquiry"
            >
              <InboundInquiryForm
                resetAutoCompleteFields={resetAutoCompleteFields}
                closeModal={() =>
                  setShowForm({ companyForm: false, inboundInquiryForm: false })
                }
                type="add"
                formId="ibound-inquiry-form-id"
              />
            </CreateEntityModal>
          </Row>
        </Form.Item>
        {selectedInboundInquiry.id.length ? (
          <Form.Item
            label={t("Contact Person")}
            name={"mailContactPersonInboundInquiry"}
          >
            <Input disabled tabIndex={-1} type={"text"} />
          </Form.Item>
        ) : null}
        <Form.Item
          name="condition"
          label={t("Condition")}
          validateStatus={selectedCondition.err ? "error" : "success"}
          rules={[
            {
              required: true,
              message: t("Please select a condition"),
            },
          ]}
        >
          <AutoCompleteConditionFormField
            selectedCondition={selectedCondition}
            setSelectedCondition={setSelectedCondition}
            reset={resetAutoCompleteFields}
            setFormValue={form.setFieldsValue}
          />
        </Form.Item>
        <Form.Item
          name="warranty"
          label={t("Warranty")}
          validateStatus={selectedWarranty.err ? "error" : "success"}
          rules={[
            {
              required: true,
              message: t("Please select a warranty"),
            },
          ]}
        >
          <AutoCompleteWarrantyFormField
            setFormValue={form.setFieldsValue}
            selectedWarranty={selectedWarranty}
            setSelectedWarranty={setSelectedWarranty}
            reset={resetAutoCompleteFields}
          />
        </Form.Item>
        <Form.Item
          name={"inboundInquiryComments"}
          label={t("Comments (Inquiry)")}
        >
          <Input.TextArea
            autoSize={{ minRows: 8 }}
            value={
              currentSelectedOutboundOffer?.inboundInquiry?.inquiryComments ||
              "/"
            }
            style={{ border: "none" }}
            disabled
            tabIndex={-1}
          />
        </Form.Item>
        <Form.Item name="inquiryComments" label={t("Inquiry Comments")}>
          <Input.TextArea autoSize={{ minRows: 8 }} />
        </Form.Item>
        <Form.Item name={"outboundOfferSupplierId"} label={t("Supplier")}>
          <>
            <AutoCompleteFormField
              reset={resetAutoCompleteFields}
              formFieldName="outboundOfferSupplierId"
              setFormValue={form.setFieldsValue}
              options={companies.map((c: Company) => ({
                value: c.name,
                id: c.id,
              }))}
              value={selectedSupplier}
              setValue={setSelectedSupplier}
            />
          </>
        </Form.Item>
        <Form.Item name="salesPrice" label={t("Sales Price")}>
          <InputNumber
            style={{ width: "100%" }}
            step="any"
            formatter={(value) => `€ ${value}`}
          />
        </Form.Item>
        <Form.Item name="purchasePrice" label={t("Purchase Price")}>
          <InputNumber
            formatter={(value) => `€ ${value}`}
            style={{ width: "100%" }}
            step="any"
          />
        </Form.Item>
        <Form.Item
          dependencies={["purchasePrice", "salesPrice", "currency"]}
          name="margin"
          label={t("Margin")}
        >
          <InputNumber
            style={{ width: "100%" }}
            readOnly
            step="any"
            formatter={(value) => `€ ${value}`}
          />
        </Form.Item>
      </Form>
    );
  }
);
