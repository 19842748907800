import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { OutboundOffer } from "../../store/types/api.types";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { API } from "aws-amplify";
import * as mutations from "../../graphql/mutations";
import { Button, Col, Row, Tooltip } from "antd";

interface OutboundOfferStatusButtonProps {
  item: OutboundOffer;
  offerStatus: OutboundOfferStatus;
}

export enum OutboundOfferStatus {
  All = "All",
  Sold = "Sold",
  NotSold = "Not Sold",
  Open = "Open",
}

export function OutboundOfferStatusButton({
  item,
  offerStatus,
}: OutboundOfferStatusButtonProps) {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const getIcon = () => {
    switch (offerStatus) {
      case OutboundOfferStatus.NotSold:
        return <CloseOutlined />;
      case OutboundOfferStatus.Sold:
        return <CheckOutlined />;
    }
  };

  const getButtonStyles = (): React.CSSProperties => {
    if (!item.offerStatus)
      return { backgroundColor: "grey", borderColor: "grey" };
    switch (item.offerStatus) {
      case t(OutboundOfferStatus.Open):
        return { backgroundColor: "grey", borderColor: "grey" };
      case t(OutboundOfferStatus.Sold):
        return { backgroundColor: "green", borderColor: "green" };
      case t(OutboundOfferStatus.NotSold):
        return { backgroundColor: "#f5222d", borderColor: "#f5222d" };
    }
    return { backgroundColor: "grey", borderColor: "grey" };
  };

  const setLysisEditor = async (item: OutboundOffer) => {
    setIsLoading(true);
    await API.graphql({
      query: mutations.updateOutboundOffer,
      variables: {
        input: {
          id: item.id,
          offerStatus,
        },
      },
    });
    setIsLoading(() => false);
  };

  return (
    <Tooltip title={t(offerStatus)}>
      <Button
        style={getButtonStyles()}
        size={"small"}
        onClick={async () => setLysisEditor(item)}
        type={"primary"}
        shape={"circle"}
        icon={getIcon()}
        loading={isLoading}
      />
    </Tooltip>
  );
}

export function OutboundOfferButtons(item: OutboundOffer) {
  return (
    <Row gutter={[16, 0]}>
      <Col md={12}>
        <OutboundOfferStatusButton
          item={item}
          offerStatus={OutboundOfferStatus.Sold}
        />
      </Col>
      <Col md={12}>
        <OutboundOfferStatusButton
          item={item}
          offerStatus={OutboundOfferStatus.NotSold}
        />
      </Col>
    </Row>
  );
}
