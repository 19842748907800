import React, { Dispatch, useState } from "react";
import { Layout, Button, Grid, notification } from "antd";
import { BarsOutlined } from "@ant-design/icons";
import "./SideBar.css";
import { NavBar } from "../NavBar/NavBar";
import { Auth } from "aws-amplify";
import { useDispatch } from "react-redux";
import { logOutUser } from "../../store/actions/user.actions";
import { useTranslation } from "react-i18next";
import { UserActionType } from "../../store/types/user.types";

const { useBreakpoint } = Grid;

const menuButtonPos = {
  position: "fixed",
  top: 0,
  left: 0,
};

export const signOut = async (dispatch: Dispatch<UserActionType>) => {
  try {
    await Auth.signOut();
    dispatch(logOutUser());
  } catch (error) {
    notification.open({
      type: "error",
      message: "An error occured",
    });
  }
};

export const SideBar = () => {
  const [collapsed, setCollapsed] = useState(true);
  const dispatch = useDispatch();
  const screens = useBreakpoint();
  const { t } = useTranslation();
  const pos = collapsed ? {} : menuButtonPos;

  const toggleCollapsed = () => {
    setCollapsed(() => !collapsed);
  };

  return (
    <div>
      <Button
        id="menu-button"
        type="primary"
        onClick={toggleCollapsed}
        style={pos}
      >
        <BarsOutlined />
      </Button>
      <Layout.Sider
        onClick={toggleCollapsed}
        theme="light"
        collapsedWidth={0}
        collapsed={screens.xl ? false : collapsed}
        style={{
          height: "100vh",
          position: "fixed",
          zIndex: 10,
          top: 0,
          paddingTop: screens.xl ? "" : "40px",
          left: 0,
          borderRight: "1px solid #f0f0f0",
        }}
      >
        <NavBar />
        <Button
          hidden={screens.xl ? false : collapsed}
          id="signOutButton"
          type="link"
          onClick={() => signOut(dispatch)}
        >
          {t("Sign Out")}
        </Button>
      </Layout.Sider>
    </div>
  );
};

/*
interface Props {
  children: PropsWithChildren<{}>;
}

export const SideBar: React.FC<Props> = ({ children }) => {
  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  return (
    <div>
      <Layout.Sider
        theme="light"
        className="desctop-sidebar"
        width={300}
        style={{
          height: "100vh",
          position: "fixed",
          left: 0,
          borderRight: "1px solid #f0f0f0",
        }}
      >
        {children}
      </Layout.Sider>

      <Layout.Sider
        theme="light"
        style={{
          position: "fixed",
          top: 20,
          left: 15,
          backgroundColor: "transparent",
        }}
      >
        <Button
          className="mobile-sidebar"
          size="large"
          type="ghost"
          onClick={showDrawer}
        >
          <BarsOutlined className="mobile-sidebar" style={{ fontSize: 20 }} />
        </Button>
        <Drawer
          className="mobile-sidebar"
          title="Menu"
          placement="left"
          closable={true}
          onClose={onClose}
          visible={visible}
        >
          {children}
        </Drawer>
      </Layout.Sider>
    </div>
  );
};
*/
