/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCompany = /* GraphQL */ `
  query GetCompany($id: ID!) {
    getCompany(id: $id) {
      id
      typeOfContact
      name
      street1
      street2
      zip
      place
      country
      telephone
      fax
      type
      plz
      mail
      web
      vatNr
      comments
      paymentTerms
      createdAt
      contactPersons {
        items {
          id
          salutation
          title
          name
          surname
          telephone
          type
          mail
          comments
          createdAt
          updatedAt
        }
      }
      inboundInquiries {
        nextToken
      }
      outboundOffers {
        nextToken
      }
      inboundOffers {
        nextToken
      }
      updatedAt
    }
  }
`;
export const listCompanys = /* GraphQL */ `
  query ListCompanys(
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        typeOfContact
        name
        street1
        street2
        zip
        place
        country
        telephone
        fax
        type
        plz
        mail
        web
        vatNr
        comments
        paymentTerms
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getContactPerson = /* GraphQL */ `
  query GetContactPerson($id: ID!) {
    getContactPerson(id: $id) {
      id
      salutation
      title
      name
      surname
      telephone
      type
      mail
      comments
      createdAt
      company {
        id
        typeOfContact
        name
        street1
        street2
        zip
        place
        country
        telephone
        fax
        type
        plz
        mail
        web
        vatNr
        comments
        paymentTerms
        createdAt
        updatedAt
      }
      inboundInquiries {
        nextToken
      }
      updatedAt
    }
  }
`;
export const listContactPersons = /* GraphQL */ `
  query ListContactPersons(
    $filter: ModelContactPersonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContactPersons(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        salutation
        title
        name
        surname
        telephone
        type
        mail
        comments
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getInboundInquiry = /* GraphQL */ `
  query GetInboundInquiry($id: ID!) {
    getInboundInquiry(id: $id) {
      id
      lysisMember
      lysisEditor
      date
      product
      condition
      type
      inquiryComments
      status
      company {
        id
        typeOfContact
        name
        street1
        street2
        zip
        place
        country
        telephone
        fax
        type
        plz
        mail
        web
        vatNr
        comments
        paymentTerms
        createdAt
        updatedAt
      }
      contactPerson {
        id
        salutation
        title
        name
        surname
        telephone
        type
        mail
        comments
        createdAt
        updatedAt
      }
      outboundOffers {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listInboundInquirys = /* GraphQL */ `
  query ListInboundInquirys(
    $filter: ModelInboundInquiryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInboundInquirys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        lysisMember
        lysisEditor
        date
        product
        condition
        type
        inquiryComments
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOutboundOffer = /* GraphQL */ `
  query GetOutboundOffer($id: ID!) {
    getOutboundOffer(id: $id) {
      id
      lysisMember
      date
      condition
      type
      warranty
      inquiryComments
      purchasePrice
      salesPrice
      offerStatus
      margin
      inboundInquiry {
        id
        lysisMember
        date
        product
        condition
        type
        inquiryComments
        status
        createdAt
        updatedAt
      }
      company {
        id
        typeOfContact
        name
        street1
        street2
        zip
        place
        country
        telephone
        fax
        type
        plz
        mail
        web
        vatNr
        comments
        paymentTerms
        createdAt
        updatedAt
      }
      supplier {
        id
        typeOfContact
        name
        street1
        street2
        zip
        place
        country
        telephone
        fax
        type
        plz
        mail
        web
        vatNr
        comments
        paymentTerms
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listOutboundOffers = /* GraphQL */ `
  query ListOutboundOffers(
    $filter: ModelOutboundOfferFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOutboundOffers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        lysisMember
        offerStatus
        date
        condition
        type
        warranty
        inquiryComments
        purchasePrice
        salesPrice
        margin
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getInboundOffer = /* GraphQL */ `
  query GetInboundOffer($id: ID!) {
    getInboundOffer(id: $id) {
      id
      type
      lysisMember
      date
      product
      condition
      inquiryComments
      currency
      purchasePrice
      warranty
      shipping
      totalPrice
      company {
        id
        typeOfContact
        name
        street1
        street2
        zip
        place
        country
        telephone
        fax
        type
        plz
        mail
        web
        vatNr
        comments
        paymentTerms
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listInboundOffers = /* GraphQL */ `
  query ListInboundOffers(
    $filter: ModelInboundOfferFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInboundOffers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        lysisMember
        date
        product
        condition
        inquiryComments
        currency
        purchasePrice
        warranty
        shipping
        totalPrice
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const companyByDate = /* GraphQL */ `
  query CompanyByDate(
    $type: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    companyByDate(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        typeOfContact
        name
        street1
        street2
        zip
        place
        country
        telephone
        fax
        type
        plz
        mail
        web
        vatNr
        comments
        paymentTerms
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const contactPersonsByCreatedAt = /* GraphQL */ `
  query ContactPersonsByCreatedAt(
    $type: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelContactPersonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    contactPersonsByCreatedAt(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        salutation
        title
        name
        surname
        telephone
        type
        mail
        comments
        createdAt
        updatedAt
        company {
          id
          name
        }
      }
      nextToken
    }
  }
`;
export const inboundInquiriesByStatus = /* GraphQL */ `
  query InboundInquiriesByStatus(
    $status: String
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInboundInquiryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    inboundInquiriesByStatus(
      status: $status
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lysisMember
        lysisEditor
        leadOrigin
        date
        product
        condition
        type
        inquiryComments
        status
        createdAt
        updatedAt
        company {
          id
          name
        }
        contactPerson {
          id
          name
          surname
          mail
        }
      }
      nextToken
    }
  }
`;
export const inboundInquiriesByDate = /* GraphQL */ `
  query InboundInquiriesByDate(
    $type: String
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInboundInquiryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    inboundInquiriesByDate(
      type: $type
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lysisMember
        lysisEditor
        leadOrigin
        date
        product
        condition
        type
        inquiryComments
        status
        createdAt
        updatedAt
        company {
          id
          name
        }
        contactPerson {
          id
          name
          surname
          mail
        }
      }
      nextToken
    }
  }
`;
export const inboundInquiriesLysisEditor = /* GraphQL */ `
  query InboundInquiriesLysisEditor(
    $lysisEditor: String
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInboundInquiryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    inboundInquiriesLysisEditor(
      lysisEditor: $lysisEditor
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lysisMember
        lysisEditor
        leadOrigin
        date
        product
        condition
        type
        inquiryComments
        status
        createdAt
        updatedAt
        company {
          id
          name
        }
        contactPerson {
          id
          name
          surname
          mail
        }
      }
      nextToken
    }
  }
`;
export const outboundOffersByDate = /* GraphQL */ `
  query OutboundOffersByDate(
    $type: String
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOutboundOfferFilterInput
    $limit: Int
    $nextToken: String
  ) {
    outboundOffersByDate(
      type: $type
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lysisMember
        date
        condition
        type
        warranty
        inquiryComments
        purchasePrice
        salesPrice
        offerStatus
        margin
        createdAt
        updatedAt
        supplier {
          id
          name
        }
        company {
          id
          name
        }
        inboundInquiry {
          id
          product
          inquiryComments
        }
      }
      nextToken
    }
  }
`;
export const inboundOffersByDate = /* GraphQL */ `
  query InboundOffersByDate(
    $type: String
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInboundOfferFilterInput
    $limit: Int
    $nextToken: String
  ) {
    inboundOffersByDate(
      type: $type
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        lysisMember
        date
        product
        condition
        inquiryComments
        currency
        purchasePrice
        warranty
        shipping
        totalPrice
        createdAt
        updatedAt
        company {
          id
          name
        }
      }
      nextToken
    }
  }
`;
export const inboundOffersByProduct = /* GraphQL */ `
  query InboundOffersByProduct(
    $product: String
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInboundOfferFilterInput
    $limit: Int
    $nextToken: String
  ) {
    inboundOffersByProduct(
      product: $product
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        lysisMember
        date
        product
        condition
        inquiryComments
        currency
        purchasePrice
        warranty
        shipping
        totalPrice
        createdAt
        updatedAt
        company {
          id
          name
        }
      }
      nextToken
    }
  }
`;
export const searchCompanys = /* GraphQL */ `
  query SearchCompanys(
    $filter: SearchableCompanyFilterInput
    $sort: SearchableCompanySortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchCompanys(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        typeOfContact
        name
        street1
        street2
        zip
        place
        country
        telephone
        fax
        type
        plz
        mail
        web
        vatNr
        comments
        paymentTerms
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const searchContactPersons = /* GraphQL */ `
  query SearchContactPersons(
    $filter: SearchableContactPersonFilterInput
    $sort: SearchableContactPersonSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchContactPersons(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        salutation
        title
        name
        surname
        telephone
        type
        mail
        comments
        createdAt
        updatedAt
        company {
          id
          name
        }
      }
      nextToken
      total
    }
  }
`;
export const searchInboundInquirys = /* GraphQL */ `
  query SearchInboundInquirys(
    $filter: SearchableInboundInquiryFilterInput
    $sort: SearchableInboundInquirySortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchInboundInquirys(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        lysisMember
        lysisEditor
        leadOrigin
        date
        product
        condition
        type
        inquiryComments
        status
        createdAt
        updatedAt
        contactPerson {
          id
          name
          surname
          mail
        }
        company {
          id
          name
        }
      }
      nextToken
      total
    }
  }
`;
export const searchOutboundOffers = /* GraphQL */ `
  query SearchOutboundOffers(
    $filter: SearchableOutboundOfferFilterInput
    $sort: SearchableOutboundOfferSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchOutboundOffers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        lysisMember
        date
        condition
        type
        warranty
        inquiryComments
        purchasePrice
        salesPrice
        margin
        createdAt
        updatedAt
        offerStatus
        inboundInquiry {
          id
          product
          inquiryComments
        }
        supplier {
          id
          name
        }
        company {
          id
          name
        }
      }
      nextToken
      total
    }
  }
`;
export const searchInboundOffers = /* GraphQL */ `
  query SearchInboundOffers(
    $filter: SearchableInboundOfferFilterInput
    $sort: SearchableInboundOfferSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchInboundOffers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        type
        lysisMember
        date
        product
        condition
        inquiryComments
        currency
        purchasePrice
        warranty
        shipping
        totalPrice
        createdAt
        updatedAt
        company {
          id
          name
        }
      }
      nextToken
      total
    }
  }
`;
