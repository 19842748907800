import React, { memo, useEffect, useState } from "react";
import { Button, Form, Input, Row, Col, notification, Select } from "antd";
import { useTranslation } from "react-i18next";
import { API } from "aws-amplify";
import { Company, ContactPerson } from "../../store/types/api.types";
import * as queries from "../../graphql/queries";
import { GraphQLResult } from "@aws-amplify/api";
import { useHistory, useParams } from "react-router-dom";
import CompanyForm from "../CompanyForm/CompanyForm";
import { CreateEntityModal } from "../CreateEntityModal/CreateEntityModal";
import {
  AutoCompleteFormField,
  AutoCompleteValue,
} from "../AutoCompleteFormField/AutoCompleteFormField";
import { AutoCompleteConditionFormField } from "../AutoCompleteFormField/AutoCompleteConditionField";
import ConatctPersonForm from "../ContactPersonForm/ContactPersonForm";
import { useAllCompanies } from "../Feed/hook.feed";
import { useProducts } from "../../hooks/hook.product";
import { getEmptyAutoCompleteValue } from "../InboundOfferForm/model.inboundOfferForm";
import { useInboundInquiryPrefill } from "./hook.inboundInquiryForm";
import {
  createInboundInquiry,
  initContactPerson,
  updateInboundInquiry,
} from "./model.inboundInquiryForm";
import {
  InboundInquiryVariablesCreate,
  InboundInquiryVariablesUpdate,
} from "../../graphql/type.graphql";
import { EntityForm } from "../../pages/DashboardPage/DashboardPage";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

interface Props {
  formId?: string;
  closeModal?: () => void;
  resetAutoCompleteFields?: boolean;
  type: "update" | "add";
}

export default memo<Props>(
  ({ formId, closeModal, resetAutoCompleteFields, type }) => {
    const [form] = Form.useForm();
    //@ts-ignore
    const { type: edit } = useParams();
    const { t } = useTranslation();
    const [showCompanyForm, setShowCompanyForm] = useState(false);
    const [showContactPersonForm, setShowContactPersonForm] = useState(false);
    const { entities: companies } = useAllCompanies();
    const [autocompleteProduct, setAutocompleteProduct] =
      useState<AutoCompleteValue>(getEmptyAutoCompleteValue());
    const { products } = useProducts();
    const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
    const [selectedContactPerson, setSelectedContactPerson] =
      useState<AutoCompleteValue>(getEmptyAutoCompleteValue());
    const [selectedCompany, setSelectedCompany] = useState<AutoCompleteValue>(
      getEmptyAutoCompleteValue()
    );
    const [selectedStatus, setSelectedStatus] = useState<AutoCompleteValue>({
      val: "Open",
      id: "open",
      err: false,
    });
    const [selectedCondition, setSelectedCondition] =
      useState<AutoCompleteValue>(getEmptyAutoCompleteValue());
    const [selectedLeadOrigin, setSelectedLeadOrigin] =
      useState<AutoCompleteValue>({
        val: "Mail",
        id: "mail",
        err: false,
      });

    useEffect(() => {
      return () => resetForm();
    }, []);

    const setAutoCompleteFields = (
      company?: AutoCompleteValue,
      contactPerson?: AutoCompleteValue,
      status?: AutoCompleteValue,
      condition?: AutoCompleteValue,
      product?: AutoCompleteValue,
      leadOrigin?: AutoCompleteValue
    ) => {
      setSelectedCompany(company || getEmptyAutoCompleteValue());
      setSelectedContactPerson(contactPerson || getEmptyAutoCompleteValue());
      setSelectedCondition(condition || getEmptyAutoCompleteValue());
      setSelectedStatus(status || getEmptyAutoCompleteValue());
      setAutocompleteProduct(product || getEmptyAutoCompleteValue());
      setSelectedLeadOrigin(leadOrigin || getEmptyAutoCompleteValue());
    };
    const { currentSelectedInboundInquiry } = useInboundInquiryPrefill(
      form,
      setAutoCompleteFields
    );

    const [contactPersons, setContactPersons] = useState<Array<ContactPerson>>([
      initContactPerson(currentSelectedInboundInquiry),
    ]);

    const handleUpdate = async (
      values: InboundInquiryVariablesUpdate & { emailContactPerson: string }
    ) => {
      values.id = currentSelectedInboundInquiry.id;
      await updateInboundInquiry(values);
      resetForm();
      closeModal?.();
      notification.open({
        message: t("Updated Successfully"),
        type: "success",
      });
    };

    const handleCreate = async (
      values: InboundInquiryVariablesCreate & { emailContactPerson: string }
    ) => {
      await createInboundInquiry(selectedProducts, values);
      resetForm();
      closeModal?.();
    };

    const resetForm = () => {
      form.resetFields();
      setAutoCompleteFields();
    };

    const filterContactPersons = async () => {
      const id: string = form.getFieldValue("inboundInquiryCompanyId");
      await (
        API.graphql({
          query: queries.getCompany,
          variables: { id },
        }) as Promise<GraphQLResult<any>>
      )
        .then((response) => {
          setContactPersons(response.data.getCompany.contactPersons.items);
        })
        .catch(console.error);
    };

    const setEmailContactPerson = () => {
      const contactPersonId = form.getFieldValue(
        "inboundInquiryContactPersonId"
      );
      const email = contactPersons.find(
        (cp) => cp.id === contactPersonId
      )?.mail;
      form.setFieldsValue({
        emailContactPerson: email,
      });
    };

    return (
      <Form
        style={{ width: "100%" }}
        onFinish={type === "add" ? handleCreate : handleUpdate}
        {...layout}
        id={formId}
        form={form}
      >
        <Form.Item name="lysisMember" label={t("Lysis Member")}>
          <Input readOnly type="text" />
        </Form.Item>
        <Form.Item name="date" label={t("Date")}>
          <Input type="date" />
        </Form.Item>
        <Form.Item name={"leadOrigin"} label={t("Lead Origin")}>
          <>
            <AutoCompleteFormField
              options={[
                { id: "dotmed", value: "Dotmed" },
                { id: "bimedis", value: "Bimedis" },
                { id: "instagram", value: "Instagram" },
                { id: "facebook", value: "Facebook" },
                { id: "website", value: "Website" },
                { id: "mail", value: "Mail" },
                { id: "unknown", value: "Unknown" },
              ]}
              value={selectedLeadOrigin}
              setValue={setSelectedLeadOrigin}
              formFieldName={"leadOrigin"}
              setFormValue={form.setFieldsValue}
            />
          </>
        </Form.Item>
        <Form.Item
          label={t("Partner")}
          name="inboundInquiryCompanyId"
          validateStatus={selectedCompany.err ? "error" : "success"}
          rules={[
            {
              required: true,
              message: t("There is no company with this name"),
            },
          ]}
        >
          <Row>
            <Col span={20}>
              <AutoCompleteFormField
                reset={resetAutoCompleteFields}
                filterContactPerson={() => filterContactPersons()}
                formFieldName="inboundInquiryCompanyId"
                setFormValue={form.setFieldsValue}
                options={companies.map((c: Company) => ({
                  id: c.id,
                  value: c.name,
                }))}
                value={selectedCompany}
                setValue={setSelectedCompany}
              />
            </Col>
            <Col style={{ textAlign: "right" }} span={4}>
              <Button
                shape="circle"
                onClick={() => setShowCompanyForm((prev) => !prev)}
              >
                +
              </Button>
            </Col>
            <CreateEntityModal
              formId="company-form-id"
              onCancel={() => setShowCompanyForm(false)}
              visible={showCompanyForm}
              title={t(EntityForm.COMPANY)}
            >
              <CompanyForm
                closeModal={() => setShowCompanyForm(false)}
                type="add"
                formId="company-form-id"
              />
            </CreateEntityModal>
          </Row>
        </Form.Item>
        <Form.Item
          name="inboundInquiryContactPersonId"
          dependencies={["companyId"]}
          label={t("Contact Person")}
          validateStatus={selectedContactPerson.err ? "error" : "success"}
          rules={[
            {
              required: true,
              message: t("Please select a conatact person"),
            },
          ]}
        >
          <Row>
            <Col span={20}>
              <AutoCompleteFormField
                disabled={selectedCompany.id === ""}
                reset={resetAutoCompleteFields}
                filterContactPerson={setEmailContactPerson}
                formFieldName="inboundInquiryContactPersonId"
                setFormValue={form.setFieldsValue}
                options={contactPersons
                  .filter((c: ContactPerson) => c.name && c.surname)
                  .map((c: ContactPerson) => ({
                    value: `${c.name} ${c.surname}`,
                    id: c.id,
                  }))}
                value={selectedContactPerson}
                setValue={setSelectedContactPerson}
              />
            </Col>
            <Col style={{ textAlign: "right" }} span={4}>
              <Button
                shape="circle"
                onClick={() => setShowContactPersonForm((prev) => !prev)}
              >
                +
              </Button>
            </Col>
            <CreateEntityModal
              formId="contactperson-form-id"
              onCancel={() => setShowContactPersonForm(false)}
              visible={showContactPersonForm}
              title={t("Add Contact Person")}
            >
              <ConatctPersonForm
                closeModal={() => setShowContactPersonForm(false)}
                type="add"
                formId="contactperson-form-id"
              />
            </CreateEntityModal>
          </Row>
        </Form.Item>

        <Form.Item
          name="emailContactPerson"
          label={`Email ${t("Contact Person")}`}
        >
          <Input type="text" readOnly />
        </Form.Item>
        <Form.Item
          label={t("Product")}
          name="product"
          rules={[
            {
              required: true,
              message: t("Please select a product"),
            },
          ]}
        >
          {type === "update" ? (
            <>
              <AutoCompleteFormField
                formFieldName="product"
                setFormValue={form.setFieldsValue}
                options={products}
                value={autocompleteProduct}
                setValue={setAutocompleteProduct}
                reset={resetAutoCompleteFields}
              />
            </>
          ) : (
            <Select
              value={selectedProducts}
              mode={"multiple"}
              onChange={(value) => setSelectedProducts(value)}
            >
              {products.map((product) => (
                <Select.Option value={product.value}>
                  {product.value}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item
          name="condition"
          label={t("Condition")}
          validateStatus={selectedCondition.err ? "error" : "success"}
          rules={[
            {
              required: true,
              message: t("Please select a condition"),
            },
          ]}
        >
          <>
            <AutoCompleteConditionFormField
              selectedCondition={selectedCondition}
              setSelectedCondition={setSelectedCondition}
              setFormValue={form.setFieldsValue}
              reset={resetAutoCompleteFields}
            />
          </>
        </Form.Item>
        <Form.Item name="inquiryComments" label={t("Inquiry Comments")}>
          <Input.TextArea autoSize={{ minRows: 8 }} />
        </Form.Item>
        {edit ? (
          <Form.Item
            name="status"
            label={t("Status")}
            rules={[
              {
                required: true,
                message: t("Please select a status"),
              },
            ]}
          >
            <Select>
              <Select.Option value="archieved">{t("Archived")}</Select.Option>
              <Select.Option value="offered">{t("Offered")}</Select.Option>
              <Select.Option value="open">{t("Open")}</Select.Option>
              <Select.Option value={"In progress"}>
                {t("in progress")}
              </Select.Option>
            </Select>
          </Form.Item>
        ) : (
          <Form.Item
            name="status"
            validateStatus={selectedStatus.err ? "error" : "success"}
            label={t("Status")}
            rules={[
              {
                required: true,
                message: t("Please select a status"),
              },
            ]}
          >
            <>
              <AutoCompleteFormField
                reset={resetAutoCompleteFields}
                formFieldName="status"
                setFormValue={form.setFieldsValue}
                options={[
                  { value: t("Archived"), id: "archieved" },
                  { value: t("Open"), id: "open" },
                  { value: t("Offered"), id: "offered" },
                  { value: t("In progress"), id: "in progress" },
                ]}
                value={selectedStatus}
                setValue={setSelectedStatus}
              />
            </>
          </Form.Item>
        )}
      </Form>
    );
  }
);
