import React, { memo, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Feed } from "../../components/Feed/Feed";
import { Layout } from "antd";
import { useTranslation } from "react-i18next";
import { EntityForm, EntityFormState } from "../DashboardPage/DashboardPage";
import { CreateEntityModal } from "../../components/CreateEntityModal/CreateEntityModal";
import ConatctPersonForm from "../../components/ContactPersonForm/ContactPersonForm";
import InboundOfferForm from "../../components/InboundOfferForm/InboundOfferForm";
import OutboundOfferForm from "../../components/OutboundOfferForm/OutboundOfferForm";
import InboundInquiryForm from "../../components/InboundInquiryForm/InboundInquiryForm";
import { useDispatch } from "react-redux";
import { clearAllFilters } from "../../store/actions/api.actions";
import { getFormType, getHeader, getValidForm } from "./utils";
import { RadioChangeEvent } from "antd/lib/radio";
import CompanyForm from "../../components/CompanyForm/CompanyForm";
import { DomainHeader } from "./DomainHeader";
import { Editor } from "../../components/Editor/Editor";
import { OutboundOfferStatus } from "../../components/OutboundOfferStatusButton/OutboundOfferStatusButton";

export enum InboundInquiryRadioButtonValue {
  all = "All",
  open = "Open",
  notArchieved = "Not archieved",
  inProgress = "In progress",
}

interface Props {}

export default memo<Props>(() => {
  const { pathname } = useLocation();
  const header: string = getHeader(pathname);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [currentSelectedEmail, setCurrentSelectedEmail] = useState<string>("");
  const [showInboundInquiriesOfEditor, setShowInboundInquiriesOfEditor] =
    useState(false);
  const [outboundOfferRadioButtonValue, setOutboundOfferRadioButtonValue] =
    useState<OutboundOfferStatus>(OutboundOfferStatus.All);
  const [inboundInquiryRadioButtonValue, setInboundInquiryRadioButtonValue] =
    useState<InboundInquiryRadioButtonValue>(
      InboundInquiryRadioButtonValue.all
    );
  const [entityModalState, setEntityModalState] = useState<EntityFormState>({
    visible: false,
    form: getValidForm(pathname),
  });
  const formId = entityModalState.form?.toLowerCase().replaceAll(" ", "-");

  useEffect(() => {
    setEntityModalState((prev) => ({
      visible: prev.visible,
      form: getValidForm(pathname),
    }));
    setIsEditorOpen(false);
    setSearchTerm("");
  }, [pathname]);

  useEffect(() => {
    if ((document.querySelector("#search-bar") as any)?.value === "") {
      filterIqByRadioButtonValue({
        target: { value: inboundInquiryRadioButtonValue },
      } as RadioChangeEvent);
    }
  }, [searchTerm]);

  useEffect(
    () => () => {
      dispatch(clearAllFilters());
    },
    []
  );

  const closeModal = () => {
    setEntityModalState((prev) => ({ visible: false, form: prev.form }));
  };

  const openModal = () => {
    setEntityModalState((prev) => ({
      visible: true,
      form: prev.form,
    }));
  };

  const filterIqByRadioButtonValue = (e: RadioChangeEvent) => {
    setInboundInquiryRadioButtonValue(e.target.value);
  };

  return (
    <>
      <div style={{ display: isEditorOpen ? "block" : "none" }}>
        <Editor
          form={getFormType(getValidForm(pathname))}
          setIsEditorOpen={setIsEditorOpen}
        />
      </div>
      <div style={{ display: isEditorOpen ? "none" : "block" }}>
        <DomainHeader
          setShowInboundInquiriesOfEditor={setShowInboundInquiriesOfEditor}
          showInboundInquiriesOfEditor={showInboundInquiriesOfEditor}
          entityForm={entityModalState.form}
          header={header}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          setCurrentSelectedEmail={setCurrentSelectedEmail}
          currentSelectedEmail={currentSelectedEmail}
          openModal={openModal}
          radioButtonValue={
            getValidForm(pathname) === EntityForm.OUTBOUND_OFFER
              ? outboundOfferRadioButtonValue
              : inboundInquiryRadioButtonValue
          }
          setRadionButtonValue={
            getValidForm(pathname) === EntityForm.OUTBOUND_OFFER
              ? setOutboundOfferRadioButtonValue
              : setInboundInquiryRadioButtonValue
          }
        />
        {entityModalState.visible && (
          <CreateEntityModal
            visible={entityModalState.visible}
            title={entityModalState.form}
            formId={formId}
            onCancel={() =>
              setEntityModalState((prev) => ({
                visible: false,
                form: prev.form,
              }))
            }
          >
            {entityModalState.form === EntityForm.CONTACT_PERSON && (
              <ConatctPersonForm
                type="add"
                closeModal={closeModal}
                formId={formId}
              />
            )}
            {entityModalState.form === EntityForm.INBOUND_OFFER && (
              <InboundOfferForm
                type="add"
                closeModal={closeModal}
                formId={formId}
              />
            )}
            {entityModalState.form === EntityForm.OUTBOUND_OFFER && (
              <OutboundOfferForm
                type="add"
                closeModal={closeModal}
                formId={formId}
              />
            )}
            {entityModalState.form === EntityForm.INBOUND_INQUIRY && (
              <InboundInquiryForm
                type="add"
                closeModal={closeModal}
                formId={formId}
              />
            )}
            {entityModalState.form === EntityForm.COMPANY && (
              <CompanyForm type="add" closeModal={closeModal} formId={formId} />
            )}
          </CreateEntityModal>
        )}
        <Layout.Content style={{ textAlign: "center", marginTop: 20 }}>
          <Feed
            setIsEditorOpen={setIsEditorOpen}
            currentSelectedEmail={currentSelectedEmail}
            isEditorOpen={isEditorOpen}
            setSearchTerm={setSearchTerm}
            searchTerm={searchTerm}
            entity={getValidForm(pathname)}
            showInboundInquiriesOfEditor={showInboundInquiriesOfEditor}
            filterForInboundInquiry={inboundInquiryRadioButtonValue}
            filterForOutboundOffer={outboundOfferRadioButtonValue}
          />
        </Layout.Content>
      </div>
    </>
  );
});
