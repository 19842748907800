import { FormInstance } from "antd/lib/form";
import { ContactPersonVariablesUpdate } from "../../graphql/type.graphql";
import { AutoCompleteValue } from "../AutoCompleteFormField/AutoCompleteFormField";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { ContactPerson } from "../../store/types/api.types";
import { useEffect } from "react";
import { setFormValues } from "../../store/actions/api.actions";

export function useContactPersonPrefill(
  form: FormInstance<ContactPersonVariablesUpdate>,
  setAutoCompleteFields: (
    company?: AutoCompleteValue,
    salutation?: AutoCompleteValue,
    title?: AutoCompleteValue
  ) => void
) {
  const dispatch = useDispatch();
  const shouldPrefillForm: boolean = useSelector(
    (state: RootStateOrAny) => state.apiReducer.edit.setFormValues
  );
  const currentSelectedContactPerson: ContactPerson = useSelector(
    (state: RootStateOrAny) =>
      state.apiReducer.edit.currSelectedItem as ContactPerson
  );

  useEffect(() => {
    if (currentSelectedContactPerson && shouldPrefillForm) {
      form.setFieldsValue({
        name: currentSelectedContactPerson.name,
        surname: currentSelectedContactPerson.surname,
        title: currentSelectedContactPerson.title,
        salutation: currentSelectedContactPerson.salutation,
        telephone: currentSelectedContactPerson.telephone,
        mail: currentSelectedContactPerson.mail,
        comments: currentSelectedContactPerson.comments,
        contactPersonCompanyId: currentSelectedContactPerson.company?.id,
      });
      const company: AutoCompleteValue = {
        val: currentSelectedContactPerson?.company?.name,
        id: currentSelectedContactPerson?.company?.id,
        err: false,
      };
      const salutation: AutoCompleteValue = {
        val:
          (currentSelectedContactPerson?.salutation
            ?.slice(0, 1)
            ?.toUpperCase() || "") +
          (currentSelectedContactPerson?.salutation?.slice(1) || ""),
        id: currentSelectedContactPerson?.salutation || "",
        err: false,
      };
      const title: AutoCompleteValue = {
        val: currentSelectedContactPerson?.title?.toUpperCase() || "",
        id: currentSelectedContactPerson?.title || "",
        err: false,
      };
      setAutoCompleteFields(company, salutation, title);
      dispatch(setFormValues(false));
    }
  });

  return {
    currentSelectedContactPerson,
  };
}
