import React, { useEffect, useState } from "react";
import { Button, Grid, Layout } from "antd";
import { InboundInquiry } from "../../store/types/api.types";
import { EntityForm } from "../../pages/DashboardPage/DashboardPage";
import { EntityTable } from "../EntityTable/EntityTable";
import { useEntities } from "./hook.feed";
import { LoadingSpinner } from "../LoadingSpinner/LoadingSpinner";
import { CreateEntityModal } from "../CreateEntityModal/CreateEntityModal";
import OutboundOfferForm from "../OutboundOfferForm/OutboundOfferForm";
import { InboundInquiryRadioButtonValue } from "../../pages/DomainPage/DomainPage";
import {
  useInboundInquiryFiltering,
  useOutboundOfferFilter,
  usePaginatedEntitySearch,
} from "../../pages/DomainPage/hook.domainPage";
import { DashboardList } from "./DashboardList";
import { useTranslation } from "react-i18next";
import { queryLimit } from "./model";
import { OutboundOfferStatus } from "../OutboundOfferStatusButton/OutboundOfferStatusButton";

interface Props {
  entity?: EntityForm;
  searchTerm?: string;
  setSearchTerm?: React.Dispatch<React.SetStateAction<string>>;
  filterForOutboundOffer?: OutboundOfferStatus;
  filterForInboundInquiry?: InboundInquiryRadioButtonValue;
  setIsEditorOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isEditorOpen: boolean;
  currentSelectedEmail?: string;
  showInboundInquiriesOfEditor?: boolean;
}

const { useBreakpoint } = Grid;

export const Feed: React.FC<Props> = ({
  entity,
  filterForInboundInquiry,
  searchTerm,
  setSearchTerm,
  setIsEditorOpen,
  isEditorOpen,
  showInboundInquiriesOfEditor,
  filterForOutboundOffer,
  currentSelectedEmail,
}) => {
  const { t } = useTranslation();
  const screens = useBreakpoint();
  const [elementCounter, setElementCounter] = useState(queryLimit);
  const {
    isLoading,
    setIsLoading,
    entities,
    setEntities,
    fetchedAllData,
    unsubscribeFromEvents,
  } = useEntities(elementCounter, entity, setSearchTerm);
  const {
    entitiesFilteredBySearchTerm,
    isLoading: isSearching,
    isSearchActive,
    fetchedAllData: fetchedAllDataSearch,
    setElementCounter: setElementCounterSearch,
    elementCounter: elementCounterSearch,
  } = usePaginatedEntitySearch(
    entities,
    searchTerm,
    entity,
    showInboundInquiriesOfEditor
  );
  const { filteredInboundInquiries } = useInboundInquiryFiltering(
    setIsLoading,
    showInboundInquiriesOfEditor,
    filterForInboundInquiry,
    entitiesFilteredBySearchTerm,
    searchTerm,
    entity
  );
  const { filteredOutboundOffers } = useOutboundOfferFilter(
    entitiesFilteredBySearchTerm,
    filterForOutboundOffer,
    currentSelectedEmail
  );
  const [outboundOfferModalOpen, setOutboundOfferModalOpen] = useState(false);
  const [
    createOutboundOfferForInboundInquiry,
    setCreateOutboundOfferForInboundInquiry,
  ] = useState<InboundInquiry | undefined>();

  useEffect(() => {
    setElementCounter(queryLimit);
    setElementCounterSearch(queryLimit);
    unsubscribeFromEvents();
  }, [entity]);

  const fetchMoreEntities = () => {
    if (isSearchActive) {
      setElementCounterSearch((prev) => prev + queryLimit);
    } else {
      setElementCounter((prev) => prev + queryLimit);
    }
  };

  const getEntities = () => {
    switch (entity) {
      case EntityForm.INBOUND_INQUIRY:
        return filteredInboundInquiries;
      case EntityForm.OUTBOUND_OFFER:
        return filteredOutboundOffers;
      case EntityForm.COMPANY:
      case EntityForm.CONTACT_PERSON:
      case EntityForm.INBOUND_OFFER:
      default:
        return entitiesFilteredBySearchTerm;
    }
  };

  if (
    (isLoading && elementCounter === 100) ||
    (isSearching && elementCounterSearch === 100)
  ) {
    return (
      <Layout.Content style={{ textAlign: "center", marginTop: 20 }}>
        <LoadingSpinner size="fit" />
      </Layout.Content>
    );
  }

  return (
    <div>
      {!entity || screens.xs ? (
        <DashboardList
          setIsEditorOpen={setIsEditorOpen}
          setEntities={setEntities}
          entities={getEntities()}
          createOutboundOfferForInboundInquiry={
            createOutboundOfferForInboundInquiry
          }
          setCreateOutboundOfferForInboundInquiry={
            setCreateOutboundOfferForInboundInquiry
          }
          setOutboundOfferModalOpen={setOutboundOfferModalOpen}
          entityForm={entity}
        />
      ) : (
        <EntityTable
          setIsEditorOpen={setIsEditorOpen!}
          data={getEntities()}
          entity={entity}
        />
      )}
      {!showInboundInquiriesOfEditor &&
        ((!fetchedAllData && !isSearchActive) ||
          (!fetchedAllDataSearch && isSearchActive)) && (
          <Button
            disabled={isLoading}
            type="primary"
            style={{ marginTop: 16 }}
            onClick={fetchMoreEntities}
          >
            {t("Load more")}
          </Button>
        )}
      {isLoading && elementCounter > 100 && <LoadingSpinner size="fit" />}
      {createOutboundOfferForInboundInquiry && (
        <CreateEntityModal
          visible={outboundOfferModalOpen}
          title="New Outbound Offer"
          formId="outbound-offer-form"
          onCancel={() => setOutboundOfferModalOpen(false)}
        >
          <OutboundOfferForm
            type="add"
            formId="outbound-offer-form"
            closeModal={() =>
              setCreateOutboundOfferForInboundInquiry(undefined)
            }
            companyAutoComplete={{
              err: false,
              val: createOutboundOfferForInboundInquiry!.company.name,
              id: createOutboundOfferForInboundInquiry!.company.id,
            }}
            inboundInquiryAutoComplete={{
              err: false,
              val: `${createOutboundOfferForInboundInquiry!.product || "-"} / ${
                createOutboundOfferForInboundInquiry!.date || "-"
              } / ${
                createOutboundOfferForInboundInquiry?.company?.name || "-"
              }`,
              id: createOutboundOfferForInboundInquiry!.id,
            }}
          />
        </CreateEntityModal>
      )}
    </div>
  );
};
