import React, { createContext, lazy, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import {
  AuthenticationPage,
  FormState,
} from "./pages/AuthenticationPage/AuthenticationPage";
import { LoadingSpinner } from "./components/LoadingSpinner/LoadingSpinner";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { UserState } from "./store/types/user.types";
import {
  confirmUser,
  getUserInformationCompleted,
  logInUser,
} from "./store/actions/user.actions";
import { DashboardPage } from "./pages/DashboardPage/DashboardPage";
import { ProfilePage } from "./pages/ProfilePage/ProfilePage";
import { AdminPage } from "./pages/AdminPage/AdminPage";
import { Routes } from "./components/NavBar/routes";
import { Layout } from "antd";
import { SideBar } from "./components/SideBar/SideBar";
import { Editor } from "./components/Editor/Editor";
import Amplify, { API, Auth } from "aws-amplify";
import awsconfig from "./aws-exports";
import { listAdmins } from "./utils/adminQueries";
Amplify.configure(awsconfig);

const DomainPage = lazy(() => import("./pages/DomainPage/DomainPage"));
export const UserEmails = createContext<string[]>([]);

function App() {
  const dispatch = useDispatch();
  const [userEmails, setUserEmails] = useState<string[]>([]);
  const userInformation: UserState = useSelector(
    (state: RootStateOrAny) => state.userReducer
  );

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((response) => {
        if (response) {
          dispatch(logInUser(response));
          if (response.attributes.email_verified === true) {
            dispatch(confirmUser());
          }
        }
      })
      //throws error if no authenticated user is found
      .catch(() => dispatch(getUserInformationCompleted()));
    listAdmins().then(setUserEmails).catch(console.error);
  }, [userInformation.userFound]);

  if (userInformation.loading) {
    return <LoadingSpinner size="fullscreen" />;
  }

  if (!userInformation.userFound) {
    return <AuthenticationPage initialForm={FormState.SIGN_IN} />;
  }

  if (!userInformation.confirmed) {
    return <AuthenticationPage initialForm={FormState.CONFIRM_SIGN_UP} />;
  }

  return (
    <Router>
      <UserEmails.Provider value={userEmails}>
        <SideBar />
        <Layout.Content id="content">
          <Switch>
            <Route path="/" exact>
              {userInformation.userFound ? (
                <Redirect from="/" to={Routes.Dashboard} />
              ) : (
                <Redirect from="/" to={Routes.Auth} />
              )}
            </Route>
            <Route path={Routes.Auth} component={AuthenticationPage} />
            <Route path={Routes.Dashboard} component={DashboardPage} />
            <Route path={`${Routes.Domain}/:name`} component={DomainPage} />
            <Route path={Routes.Profile} component={ProfilePage} />
            <Route path={Routes.Admin} component={AdminPage} />
            <Route path={`${Routes.Editor}/:form`} component={Editor} />
          </Switch>
        </Layout.Content>
      </UserEmails.Provider>
    </Router>
  );
}

export default App;
