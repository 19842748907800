export interface UserInformation {
  email: string;
  password: string;
  firstName?: string;
  lastName?: string;
  photoUrl?: string;
}

export interface User {
  attributes: {
    email: string;
    name?: string;
    family_name?: string;
    picture?: string;
  };
}

export interface UserState {
  loading: boolean;
  userFound: boolean;
  confirmed: boolean;
  user: User;
}

export const LOG_IN_USER = "LOG_IN_USER";
export const GET_USER_INFORMATION_COMPLETED = "GET_USER_INFORMATION_COMPLETED";
export const SIGN_UP_USER = "SIGN_UP_USER";
export const CONFIRM_USER = "CONFIRM_USER";
export const LOG_OUT_USER = "LOG_OUT_USER";
export const EDIT_USER_INFORMATION = "EDIT_USER_INFORMATION";

interface LogInUser {
  type: typeof LOG_IN_USER;
  payload: User;
}

interface GetUserInformationCompleted {
  type: typeof GET_USER_INFORMATION_COMPLETED;
}

interface SignUpUser {
  type: typeof SIGN_UP_USER;
  payload: User;
}

interface ConfirmUser {
  type: typeof CONFIRM_USER;
}

interface LogOutUser {
  type: typeof LOG_OUT_USER;
}

interface EditUserInformation {
  type: typeof EDIT_USER_INFORMATION;
  payload: {
    firstName: string;
    lastName: string;
  };
}

export type UserActionType =
  | LogInUser
  | GetUserInformationCompleted
  | SignUpUser
  | ConfirmUser
  | LogOutUser
  | EditUserInformation;
