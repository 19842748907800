import React, { memo, useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  InputNumber,
  Row,
  Col,
  notification,
  Divider,
  Badge,
} from "antd";
import { useTranslation } from "react-i18next";
import { API } from "aws-amplify";
import * as mutations from "../../graphql/mutations";
import { Company, InboundOffer } from "../../store/types/api.types";
import { useHistory } from "react-router-dom";
import CompanyForm from "../CompanyForm/CompanyForm";
import { CreateEntityModal } from "../CreateEntityModal/CreateEntityModal";
import {
  AutoCompleteFormField,
  AutoCompleteValue,
} from "../AutoCompleteFormField/AutoCompleteFormField";
import { AutoCompleteConditionFormField } from "../AutoCompleteFormField/AutoCompleteConditionField";
import { AutoCompleteWarrantyFormField } from "../AutoCompleteFormField/AutoCompleteWarrantyField";
import { useAllCompanies } from "../Feed/hook.feed";
import {
  getEmptyAutoCompleteProductInformation,
  getEmptyAutoCompleteValue,
  setProductInfoPrice,
} from "./model.inboundOfferForm";
import { useInboundOfferPrefill } from "./hook.inboundOfferForm";
import { useProducts } from "../../hooks/hook.product";
import { getCompany } from "../../graphql/model.graphql";
import { EntityForm } from "../../pages/DashboardPage/DashboardPage";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

interface Props {
  formId?: string;
  closeModal?: () => void;
  resetAutoCompleteFields?: boolean;
  type?: "update" | "add";
}

export interface AutoCompleteProductInformation {
  product: AutoCompleteValue;
  purchasePrice: number;
  shipping: number;
  totalPrice: number;
}

export interface FormFields {
  id: string;
  lysisMember: string;
  date?: Date;
  product0: string;
  condition: string;
  inquiryComments: string;
  currency: string;
  purchasePrice0: any;
  shipping0: any;
  totalPrice0: any;
  warranty: string;
  companyId: string;
  createdAt: Date;
  updatedAt: Date;
}

export default memo<Props>(
  ({ formId, closeModal, type, resetAutoCompleteFields }) => {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const { entities: companies } = useAllCompanies();
    const [showCompanyForm, setShowCompanyForm] = useState(false);
    const [productInformation, setProductInformation] = useState<
      AutoCompleteProductInformation[]
    >([getEmptyAutoCompleteProductInformation()]);
    const { products } = useProducts();
    const [selectedCondition, setSelectedCondition] =
      useState<AutoCompleteValue>(getEmptyAutoCompleteValue());
    const [selectedWarranty, setSelectedWarranty] = useState<AutoCompleteValue>(
      getEmptyAutoCompleteValue()
    );
    const [selectedCurrency, setSelectedCurrency] = useState<AutoCompleteValue>(
      {
        val: "Euro",
        id: "euro",
        err: false,
      }
    );
    const [selectedCompany, setSelectedCompany] = useState<AutoCompleteValue>(
      getEmptyAutoCompleteValue()
    );

    const setAutoCompleteFields = (
      company?: AutoCompleteValue,
      warranty?: AutoCompleteValue,
      condition?: AutoCompleteValue,
      currency?: AutoCompleteValue,
      product?: AutoCompleteValue,
      entity?: InboundOffer
    ) => {
      setSelectedCompany(company || getEmptyAutoCompleteValue());
      setSelectedWarranty(warranty || getEmptyAutoCompleteValue);
      setSelectedCondition(condition || getEmptyAutoCompleteValue());
      setSelectedCurrency(currency || getEmptyAutoCompleteValue());
      setProductInformation([
        {
          product: {
            val: product?.val || "",
            id: product?.id || "",
            err: false,
          },
          shipping: entity?.shipping || 0,
          totalPrice: entity?.totalPrice || 0,
          purchasePrice: entity?.purchasePrice || 0,
        },
      ]);
    };

    const { currentSelectedInboundOffer } = useInboundOfferPrefill(
      form,
      setAutoCompleteFields
    );

    useEffect(() => {
      form.setFieldsValue({
        mailContact:
          companies.find((company) => company.id === selectedCompany.id)
            ?.mail || "",
      });
    }, [selectedCompany, companies]);

    useEffect(() => {
      return () => resetForm();
    }, []);

    const handleUpdate = async (values: FormFields) => {
      const company = await getCompany(values.companyId);
      const {
        lysisMember,
        date,
        product0: product,
        condition,
        currency,
        purchasePrice0: purchasePrice,
        inquiryComments,
        shipping0: shipping,
        totalPrice0: totalPrice,
        companyId,
        warranty,
      } = values;
      await API.graphql({
        query: mutations.updateInboundOffer,
        variables: {
          input: {
            id: currentSelectedInboundOffer.id,
            lysisMember,
            date,
            product,
            condition,
            inquiryComments,
            currency,
            purchasePrice: Number(String(purchasePrice).replace(/(€|\$)/, "")),
            shipping: Number(String(shipping).replace(/(€|\$)/, "")),
            totalPrice: Number(String(totalPrice).replace(/(€|\$)/, "")),
            warranty,
            companyName: company?.name || "",
            inboundOfferCompanyId: companyId,
          },
        },
      });
      resetForm();
      closeModal?.();
      notification.open({
        message: t("Updated Successfully"),
        type: "success",
      });
    };

    const handleCreate = async (values: FormFields) => {
      const company = await getCompany(values.companyId);
      const {
        lysisMember,
        date,
        condition,
        currency,
        inquiryComments,
        companyId,
        warranty,
      } = values;
      await Promise.all(
        productInformation.map(
          async (productInfo) =>
            await API.graphql({
              query: mutations.createInboundOffer,
              variables: {
                input: {
                  lysisMember,
                  date,
                  product: productInfo.product.val,
                  condition,
                  inquiryComments,
                  currency,
                  purchasePrice: productInfo.purchasePrice,
                  shipping: productInfo.shipping,
                  totalPrice: productInfo.totalPrice,
                  warranty,
                  inboundOfferCompanyId: companyId,
                  companyName: company?.name || "",
                  type: "inboundOffer",
                },
              },
            })
        )
      );
      resetForm();
      closeModal?.();
    };

    const calcTotalPrice = (index: number) => {
      const purchasePrice = parseFloat(
        form.getFieldValue("purchasePrice" + index)
      )
        ? parseFloat(form.getFieldValue("purchasePrice" + index))
        : 0;
      const shipping = parseFloat(form.getFieldValue("shipping" + index))
        ? parseFloat(form.getFieldValue("shipping" + index))
        : 0;
      form.setFieldsValue({
        ["totalPrice" + index]: shipping + purchasePrice,
      });
      return shipping + purchasePrice;
    };

    const resetForm = () => {
      form.resetFields();
      setAutoCompleteFields();
    };

    const getCurrency = () => {
      return selectedCurrency.id === "usd" ? "$" : "€";
    };

    return (
      <Form
        style={{ width: "100%" }}
        onFinish={type === "add" ? handleCreate : handleUpdate}
        {...layout}
        id={formId}
        form={form}
      >
        <Form.Item name="lysisMember" label={t("Lysis Member")}>
          <Input readOnly type="text" />
        </Form.Item>
        <Form.Item name="date" label={t("Date")}>
          <Input type="date" />
        </Form.Item>
        <Form.Item
          label={t("Contact")}
          name="companyId"
          validateStatus={selectedCompany.err ? "error" : "success"}
          rules={[
            {
              required: true,
              message: t("There is no company with this name"),
            },
          ]}
        >
          <Row>
            <Col span={20}>
              <AutoCompleteFormField
                reset={resetAutoCompleteFields}
                formFieldName="companyId"
                setFormValue={form.setFieldsValue}
                options={companies.map((c: Company) => ({
                  value: c.name,
                  id: c.id,
                }))}
                value={selectedCompany}
                setValue={setSelectedCompany}
              />
            </Col>
            <Col style={{ textAlign: "right" }} span={4}>
              <Button
                shape="circle"
                onClick={() => setShowCompanyForm((prev) => !prev)}
              >
                +
              </Button>
            </Col>
            <CreateEntityModal
              formId="company-form-id"
              onCancel={() => setShowCompanyForm(false)}
              visible={showCompanyForm}
              title={t(EntityForm.COMPANY)}
            >
              <CompanyForm
                closeModal={() => setShowCompanyForm(false)}
                type="add"
                formId="company-form-id"
              />
            </CreateEntityModal>
          </Row>
        </Form.Item>
        {selectedCompany.id.length ? (
          <Form.Item label={"Mail"} name={"mailContact"}>
            <Input tabIndex={-1} type={"text"} disabled />
          </Form.Item>
        ) : null}
        <Form.Item
          name="condition"
          label={t("Condition")}
          validateStatus={selectedCondition.err ? "error" : "success"}
          rules={[
            {
              required: true,
              message: t("Please select a condition"),
            },
          ]}
        >
          <AutoCompleteConditionFormField
            selectedCondition={selectedCondition}
            setSelectedCondition={setSelectedCondition}
            reset={resetAutoCompleteFields}
            setFormValue={form.setFieldsValue}
          />
        </Form.Item>
        <Form.Item
          name="warranty"
          label={t("Warranty")}
          validateStatus={selectedWarranty.err ? "error" : "success"}
          rules={[
            {
              required: true,
              message: t("Please select a warranty"),
            },
          ]}
        >
          <AutoCompleteWarrantyFormField
            setFormValue={form.setFieldsValue}
            selectedWarranty={selectedWarranty}
            setSelectedWarranty={setSelectedWarranty}
            reset={resetAutoCompleteFields}
          />
        </Form.Item>
        <Form.Item name="inquiryComments" label={t("Inquiry Comments")}>
          <Input.TextArea autoSize={{ minRows: 8 }} />
        </Form.Item>
        {productInformation.map((productInfo, index) => (
          <React.Fragment key={index}>
            {index > 0 && <Divider />}
            <Form.Item
              label={t("Product")}
              name={"product" + index}
              rules={[
                {
                  required: true,
                  message: t("Please select a product"),
                },
              ]}
            >
              <>
                <AutoCompleteFormField
                  formFieldName={"product" + index}
                  setFormValue={form.setFieldsValue}
                  options={products}
                  value={productInfo.product}
                  setValue={(product: AutoCompleteValue) => {
                    setProductInformation((prev) =>
                      prev
                        .filter((productInfo, i) => i !== index)
                        .concat([{ ...prev[index], product: product }])
                    );
                  }}
                  reset={resetAutoCompleteFields}
                />
              </>
            </Form.Item>
            <Form.Item
              name={"purchasePrice" + index}
              label={t("Purchase Price")}
            >
              <InputNumber
                onChange={(value) => {
                  setProductInfoPrice(
                    setProductInformation,
                    index,
                    value,
                    "purchasePrice"
                  );
                  setProductInfoPrice(
                    setProductInformation,
                    index,
                    calcTotalPrice(index),
                    "totalPrice"
                  );
                }}
                style={{ width: "100%" }}
                formatter={(value) => `${getCurrency()} ${value}`}
                step="any"
              />
            </Form.Item>
            <Form.Item name={"shipping" + index} label={t("Shipping")}>
              <InputNumber
                onChange={(value) => {
                  setProductInfoPrice(
                    setProductInformation,
                    index,
                    value,
                    "shipping"
                  );
                  setProductInfoPrice(
                    setProductInformation,
                    index,
                    calcTotalPrice(index),
                    "totalPrice"
                  );
                }}
                style={{ width: "100%" }}
                formatter={(value) => `${getCurrency()} ${value}`}
                step="any"
              />
            </Form.Item>
            <Form.Item
              dependencies={[
                "purchasePrice" + index,
                "shipping" + index,
                "currency" + index,
              ]}
              name={"totalPrice" + index}
              label={t("Total Price")}
            >
              <InputNumber
                style={{ width: "100%" }}
                formatter={(value) => `${getCurrency()} ${value}`}
                readOnly
                step="any"
              />
            </Form.Item>
          </React.Fragment>
        ))}
        {type === "add" && (
          <Row justify={"end"}>
            <Col span={16}>
              <Row gutter={[2, 0]}>
                <Col span={12}>
                  <Button
                    type={"primary"}
                    style={{ width: "100%", marginBottom: 20 }}
                    onClick={() =>
                      setProductInformation((prev) => [
                        ...prev,
                        getEmptyAutoCompleteProductInformation(),
                      ])
                    }
                  >
                    {t("Add Product")}
                  </Button>
                </Col>
                <Col span={12}>
                  <Button
                    disabled={productInformation.length === 1}
                    type={"ghost"}
                    style={{ width: "100%", marginBottom: 20 }}
                    onClick={() =>
                      setProductInformation((prev) =>
                        prev.slice(0, prev.length - 1)
                      )
                    }
                  >
                    {t("Remove last")}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        <Form.Item
          name="currency"
          validateStatus={selectedCurrency.err ? "error" : "success"}
          label={t("Currency")}
          rules={[
            {
              required: true,
              message: t("Please select a currency"),
            },
          ]}
        >
          <>
            <AutoCompleteFormField
              setFormValue={form.setFieldsValue}
              value={selectedCurrency}
              setValue={setSelectedCurrency}
              reset={resetAutoCompleteFields}
              formFieldName="currency"
              options={[
                { value: "Euro", id: "euro" },
                { value: "Usd", id: "usd" },
              ]}
            />
          </>
        </Form.Item>
      </Form>
    );
  }
);
