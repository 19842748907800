import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import { Routes } from "./routes";
import {
  PieChartOutlined,
  ContainerOutlined,
  UserOutlined,
  BankOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";

export const NavBar = () => {
  const { t } = useTranslation();

  return (
    <nav>
      <Menu defaultSelectedKeys={["1"]} mode="inline">
        <Menu.Item title="" key="1" icon={<PieChartOutlined />}>
          <Link to={Routes.Dashboard}>Dashboard</Link>
        </Menu.Item>
        <Menu.Item title="" key="2" icon={<ContainerOutlined />}>
          <Link to={Routes.InboundInquiries}>{t("Inbound Inquiries")}</Link>
        </Menu.Item>
        <Menu.Item title="" key="3" icon={<ContainerOutlined />}>
          <Link to={Routes.OutboundOffers}>{t("Outbound Offers")}</Link>
        </Menu.Item>
        <Menu.Item title="" key="4" icon={<ContainerOutlined />}>
          <Link to={Routes.InboundOffers}>{t("Inbound Offers")}</Link>
        </Menu.Item>
        <Menu.Item title="" key="5" icon={<TeamOutlined />}>
          <Link to={Routes.ContactPersons}>{t("Contact Persons")}</Link>
        </Menu.Item>
        <Menu.Item title="" key="6" icon={<BankOutlined />}>
          <Link to={Routes.Companies}>{t("Partners")}</Link>
        </Menu.Item>
        <Menu.Item title="" key="7" icon={<UserOutlined />}>
          <Link to={Routes.Profile}>{t("Profile")}</Link>
        </Menu.Item>
        <Menu.Item title="" key="8" icon={<ContainerOutlined />}>
          <Link to={Routes.Admin}>Admin</Link>
        </Menu.Item>
      </Menu>
    </nav>
  );
};
