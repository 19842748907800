import { TFunction } from "i18next";
import { EntityForm } from "../../pages/DashboardPage/DashboardPage";
import { OutboundOffer, TableEntity } from "../../store/types/api.types";
import { DashboardAvatar } from "../Feed/DashboardAvatar";
import { OutboundOfferButtons } from "../OutboundOfferStatusButton/OutboundOfferStatusButton";

/**
 *
 * @param data
 * @param t
 * @returns
 */
export const initialState = (data: Array<TableEntity>, t: TFunction) => {
  return data.map((item: any, i) => {
    const ob: any = {};
    Object.keys(item).forEach((k) => {
      if (k === "company" && item["company"] !== null) {
        ob[k + "_"] = item[k]["name"];
        ob[k] = item[k];
      } else if (k === "inboundInquiry" && item[k]) {
        ob["product"] = item[k]["product"];
        ob[k] = item[k];
      } else if (k === "contactPerson" && item[k]) {
        ob[k] = item[k];
      } else if (typeof item[k] === "number" && k !== "plz") {
        ob[k] = `${item[k]} ${getCurrency(item["currency"])}`;
      } else if (k === "date") {
        ob[k] = item[k];
      } else ob[k] = t(item[k]);
    });
    ob["key"] = i;
    return ob;
  });
};

/**
 *
 * @param t
 * @param entity
 */
export function getColumnsWithCustomRender(t: TFunction, entity?: EntityForm) {
  if (entity && entity === EntityForm.INBOUND_INQUIRY)
    return [
      {
        title: t("Editor"),
        align: "left",
        dataIndex: "lysisEditor",
        render: (value: string, record: TableEntity) => {
          return DashboardAvatar({ item: record, editorCanBeChanged: true });
        },
      },
    ];
  if (entity && entity === EntityForm.OUTBOUND_OFFER) {
    return [
      {
        title: t("Status"),
        align: "left",
        dataIndex: "offerStatus",
        render: (value: string, record: TableEntity) => {
          return OutboundOfferButtons(record as OutboundOffer);
        },
      },
    ];
  }
  return [];
}

/**
 *
 * @param currency
 * @returns
 */
const getCurrency = (currency: string): string => {
  if (!currency) return "€";
  return currency === "usd" ? "$" : "€";
};

/**
 *
 * @param entity
 * @param forSearchBar
 * @returns
 */
export const getKeys = (entity?: EntityForm, forSearchBar?: boolean) => {
  //Idea behind company and company_
  // - company_: only contains the name of a company and is used by the table
  //             to display the name of that company
  // - company : contains the id and the name, used when editing the specifiy entity

  switch (entity) {
    case EntityForm.INBOUND_INQUIRY:
      return [
        "company_",
        forSearchBar ? "company.name" : "company",
        "product",
        "date",
        "condition",
        "status",
      ];
    case EntityForm.OUTBOUND_OFFER:
      return [
        "company_",
        forSearchBar ? "company.name" : "company",
        "product",
        "date",
        "condition",
        "warranty",
        "purchasePrice",
        "margin",
        "salesPrice",
      ];
    case EntityForm.INBOUND_OFFER:
      return [
        "company_",
        forSearchBar ? "company.name" : "company",
        "product",
        "date",
        "warranty",
        "condition",
        "shipping",
        "purchasePrice",
        "totalPrice",
      ];
    case EntityForm.COMPANY:
      return ["name", "mail", "telephone", "paymentTerms", "vatNr", "country"];
    case EntityForm.CONTACT_PERSON:
      return [
        "company_",
        forSearchBar ? "company.name" : "company",
        "name",
        "surname",
        "mail",
        "telephone",
      ];
  }
  return [];
};

/**
 *
 * @param key
 * @returns
 */
export const getTitle = (key: string): string => {
  if (key === "company_") return "Partner";
  return key.charAt(0).toUpperCase() + key.slice(1);
};
