import { Avatar, Button, Col, List, Popconfirm, Row, Tooltip } from "antd";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setFormValues, editEntity } from "../../store/actions/api.actions";
import {
  TableEntity,
  InboundInquiry,
  Company,
} from "../../store/types/api.types";
import { getType, deleteFeedEntry } from "./model";
import {
  TeamOutlined,
  BankOutlined,
  ContainerOutlined,
  MinusOutlined,
  DeploymentUnitOutlined,
  FolderOpenOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { EntityForm } from "../../pages/DashboardPage/DashboardPage";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { API } from "aws-amplify";
import * as mutations from "../../graphql/mutations";
import { getInboundOffersByProduct } from "../../graphql/model.graphql";
import { User } from "../../store/types/user.types";
import { DashboardAvatar } from "./DashboardAvatar";

interface DashboardListProps {
  entities: any[];
  setEntities?: React.Dispatch<React.SetStateAction<any[]>>;
  entityForm?: EntityForm;
  setOutboundOfferModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  createOutboundOfferForInboundInquiry: InboundInquiry | undefined;
  setIsEditorOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setCreateOutboundOfferForInboundInquiry: React.Dispatch<
    React.SetStateAction<InboundInquiry | undefined>
  >;
}

export const DashboardList: React.FC<DashboardListProps> = ({
  entities,
  setEntities,
  entityForm,
  setOutboundOfferModalOpen,
  setCreateOutboundOfferForInboundInquiry,
  setIsEditorOpen,
}) => {
  const { t } = useTranslation();
  const screens = useBreakpoint();
  const dispatch = useDispatch();
  const [productsWithInboundOffer, setProductsWithInboundOffer] = useState<
    string[]
  >([]);

  const editEntityInEditor = (entity: TableEntity) => {
    dispatch(setFormValues(true));
    dispatch(editEntity(entity));
    setIsEditorOpen(true);
  };

  const getDescription = (value: any) => {
    switch (getType(value)) {
      case "Company":
        return "";
      case "Contact Person":
        return `${value.name} ${value.surname}`;
      case "Outbound Offer":
        return `${value?.inboundInquiry?.product || "-"}, [${
          value.lysisMember
        }]`;
      case "Product":
        return `${value.title}, ${value.subTitle}, [${value.lysisMember}]`;
      default:
        return `${value.product}, [${value.lysisMember}]`;
    }
  };

  const getCompanyName = (value: any): string => {
    switch (getType(value)) {
      case "Company":
        return value.name;
      case "Contact Person":
      case "Inbound Inquiry":
      case "Inbound Offer":
      case "Outbound Offer":
        return value.company.name;
      default:
        return "-";
    }
  };

  const getAvatar = (value: TableEntity) => {
    switch (getType(value)) {
      case "Company":
        return <BankOutlined />;
      case "Contact Person":
        return <TeamOutlined />;
      case "Product":
        return <DeploymentUnitOutlined />;
      case "Inbound Inquiry":
        return entityForm ? (
          <ContainerOutlined />
        ) : (
          <DashboardAvatar item={value} key={(value as any).id} />
        );
      default:
        return <ContainerOutlined />;
    }
  };

  const setStatusOfInboundInquiryToArchived = async (id: string) => {
    setEntities?.((prev) => prev?.filter((entity) => entity.id !== id));
    await API.graphql({
      query: mutations.updateInboundInquiry,
      variables: {
        input: {
          id,
          status: "archieved",
        },
      },
    });
  };

  const inboundOfferExists = async (value: any) => {
    if (getType(value) !== "Inbound Inquiry" || !("product" in value)) {
      return;
    }
    try {
      const inboundOffers = await getInboundOffersByProduct(value.product, 1);
      return inboundOffers.length;
    } catch (e) {
      console.error(e);
      return false;
    }
  };

  useEffect(() => {
    const setAllInboundOfferProducts = async () => {
      const products = await Promise.all(
        entities.map(async (entity: any) => {
          const inboundOfferExistsForProduct = await inboundOfferExists(entity);
          return inboundOfferExistsForProduct ? entity.product : "";
        })
      );
      setProductsWithInboundOffer(products.filter((product) => product !== ""));
    };
    setAllInboundOfferProducts().catch(console.error);
  }, [entities]);

  return (
    <List
      itemLayout="horizontal"
      dataSource={entities}
      renderItem={(item: TableEntity, i: number) => {
        return (
          <List.Item>
            <Row style={{ width: "100%" }}>
              <Col xs={20} sm={12}>
                <List.Item.Meta
                  avatar={
                    <Avatar
                      style={{ backgroundColor: "#1890ff" }}
                      icon={getAvatar(item)}
                    />
                  }
                  title={
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <a
                      style={{ color: "black" }}
                      onClick={() => editEntityInEditor(item)}
                    >
                      {getCompanyName(item)}
                    </a>
                  }
                  description={
                    productsWithInboundOffer.includes(
                      (item as any).product || ""
                    ) ? (
                      <u style={{ fontWeight: "bold" }}>
                        {getDescription(item)}
                      </u>
                    ) : (
                      getDescription(item)
                    )
                  }
                />
              </Col>
              <Col xs={4} sm={12} style={{ alignSelf: "center" }}>
                <Row>
                  <Col xs={0} sm={12}>
                    <div>{t("Last update")}</div>
                    <div>{format(new Date(item.updatedAt), "d.M.Y H:mm")}</div>
                  </Col>
                  {!entityForm ? (
                    <>
                      <Col xs={12} sm={6}>
                        <Popconfirm
                          placement={screens.xs ? "top" : "left"}
                          title={t(
                            "Are you sure you want to archive this entry?"
                          )}
                          onConfirm={async () =>
                            setStatusOfInboundInquiryToArchived(
                              (item as any).id
                            )
                          }
                          okText={t("Yes")}
                          cancelText={t("No")}
                        >
                          <Button
                            disabled={!("id" in item)}
                            danger
                            size={screens.xs ? "small" : undefined}
                            type="ghost"
                            shape="circle"
                            icon={<FolderOpenOutlined />}
                          />
                        </Popconfirm>
                      </Col>
                      <Col sm={6} xs={12}>
                        <Tooltip
                          placement="top"
                          title={t("Create Outbound Offer")}
                        >
                          <Button
                            disabled={!("id" in item)}
                            type="ghost"
                            onClick={() => {
                              setCreateOutboundOfferForInboundInquiry(
                                entities[i] as InboundInquiry
                              );
                              setOutboundOfferModalOpen(true);
                            }}
                            style={{ borderColor: "#95de64" }}
                            size={screens.xs ? "small" : undefined}
                            shape="circle"
                            icon={
                              <ContainerOutlined style={{ color: "green" }} />
                            }
                          />
                        </Tooltip>
                      </Col>
                    </>
                  ) : (
                    <Col xs={12}>
                      <Popconfirm
                        placement={screens.xs ? "top" : "left"}
                        title={t("Are you sure you want to delete this entry?")}
                        onConfirm={() =>
                          deleteFeedEntry(item, (item as any).id)
                        }
                        okText={t("Yes")}
                        cancelText={t("No")}
                      >
                        <Button
                          disabled={!("id" in item)}
                          danger
                          size={screens.xs ? "small" : undefined}
                          type="ghost"
                          shape="circle"
                          icon={<MinusOutlined />}
                        />
                      </Popconfirm>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </List.Item>
        );
      }}
    />
  );
};
