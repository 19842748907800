import { Button, Checkbox, Col, Grid, Radio, Row, Select } from "antd";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { SearchBar } from "../../components/SearchBar/SearchBar";
import { EntityForm } from "../DashboardPage/DashboardPage";
import { InboundInquiryRadioButtonValue } from "./DomainPage";
import { OutboundOfferStatus } from "../../components/OutboundOfferStatusButton/OutboundOfferStatusButton";
import { UserEmails } from "../../App";

const { useBreakpoint } = Grid;

interface DomainHeaderProps {
  header: string;
  openModal: () => void;
  radioButtonValue: InboundInquiryRadioButtonValue | OutboundOfferStatus;
  currentSelectedEmail?: string;
  setCurrentSelectedEmail?: React.Dispatch<React.SetStateAction<string>>;
  setRadionButtonValue:
    | React.Dispatch<React.SetStateAction<InboundInquiryRadioButtonValue>>
    | React.Dispatch<React.SetStateAction<OutboundOfferStatus>>;
  showInboundInquiriesOfEditor: boolean;
  setShowInboundInquiriesOfEditor: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  searchTerm: string;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  entityForm?: EntityForm;
}

export const DomainHeader: React.FC<DomainHeaderProps> = ({
  header,
  openModal,
  radioButtonValue,
  setRadionButtonValue,
  entityForm,
  searchTerm,
  setSearchTerm,
  setShowInboundInquiriesOfEditor,
  showInboundInquiriesOfEditor,
  setCurrentSelectedEmail,
  currentSelectedEmail,
}) => {
  const { t } = useTranslation();
  const screens = useBreakpoint();
  const userEmails = useContext(UserEmails);

  const getEnumType = () => {
    if (entityForm === EntityForm.OUTBOUND_OFFER) return OutboundOfferStatus;
    return InboundInquiryRadioButtonValue;
  };

  return (
    <>
      <h2>{t(header.slice(4)).toUpperCase()}</h2>
      <Row
        justify="center"
        align="middle"
        style={{ marginTop: 20, marginBottom: screens.xs ? 0 : 20 }}
      >
        <Col xs={24} sm={12}>
          <Button
            onClick={openModal}
            type="primary"
            size="large"
            style={{ width: "100%" }}
          >
            {t(header)}
          </Button>
        </Col>
        <Col style={{ textAlign: "center" }} sm={12} xs={24}>
          {entityForm === EntityForm.OUTBOUND_OFFER && (
            <Select
              value={currentSelectedEmail}
              onChange={(e) => setCurrentSelectedEmail?.(e)}
              size={"large"}
              style={{
                width: "100%",
                marginTop: screens.xs ? 14 : 0,
                maxWidth: screens.xs ? "100%" : 300,
              }}
            >
              <Select.Option value={""}>{t("All")}</Select.Option>
              {userEmails.map((mail, index) => (
                <Select.Option key={index} value={mail}>
                  {mail}
                </Select.Option>
              ))}
            </Select>
          )}
          {entityForm === EntityForm.INBOUND_INQUIRY && (
            <Checkbox
              style={{
                fontSize: 16,
                marginTop: screens.xs ? 7 : 0,
                marginBottom: screens.xs ? 7 : 0,
              }}
              checked={showInboundInquiriesOfEditor}
              onChange={() => setShowInboundInquiriesOfEditor((prev) => !prev)}
            >
              {t("My Projects")}
            </Checkbox>
          )}
        </Col>
      </Row>
      {(entityForm === EntityForm.INBOUND_INQUIRY ||
        entityForm === EntityForm.OUTBOUND_OFFER) && (
        <Row gutter={[14, 14]}>
          <Col sm={12} xs={24}>
            <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
          </Col>
          <Col sm={12} xs={24} style={{ textAlign: "center" }}>
            <Radio.Group
              size={screens.xs ? "middle" : "large"}
              value={radioButtonValue}
              onChange={(e) => setRadionButtonValue(e.target.value)}
            >
              {Object.values(getEnumType()).map((option) => (
                <Radio.Button value={option}>{t(option)}</Radio.Button>
              ))}
            </Radio.Group>
          </Col>
        </Row>
      )}
      {entityForm !== EntityForm.INBOUND_INQUIRY &&
        entityForm !== EntityForm.OUTBOUND_OFFER && (
          <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
        )}
    </>
  );
};
