import React, { memo, useEffect, useState } from "react";
import { Form, Input, notification } from "antd";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  AutoCompleteFormField,
  AutoCompleteValue,
} from "../AutoCompleteFormField/AutoCompleteFormField";
import { useCompanyPrefill } from "./hook.companyForm";
import {
  CompanyVariablesCreate,
  CompanyVariablesUpdate,
} from "../../graphql/type.graphql";
import { createCompany, updateCompany } from "./model.companyForm";
import { getEmptyAutoCompleteValue } from "../InboundOfferForm/model.inboundOfferForm";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

interface Props {
  formId?: string;
  closeModal?: () => void;
  type?: "update" | "add";
  resetAutoCompleteFields?: boolean;
}

export interface Country {
  code: string;
  name: string;
}

export default memo<Props>(
  ({ formId, closeModal, type, resetAutoCompleteFields }) => {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const history = useHistory();
    const [selectedCountry, setSelectedCountry] = useState<AutoCompleteValue>(
      getEmptyAutoCompleteValue()
    );
    const [selectedContactType, setSelectedContactType] =
      useState<AutoCompleteValue>(getEmptyAutoCompleteValue());
    const countries: Array<Country> = require("country-list").getData();

    const setAutoCompleteFields = (
      typeOfContact?: AutoCompleteValue,
      country?: AutoCompleteValue
    ) => {
      setSelectedContactType(typeOfContact || getEmptyAutoCompleteValue());
      setSelectedCountry(country || getEmptyAutoCompleteValue());
    };

    const { currentSelectedCompany } = useCompanyPrefill(
      form,
      countries,
      setAutoCompleteFields
    );

    useEffect(
      () => () => {
        form.resetFields();
        setAutoCompleteFields();
      },
      []
    );

    const handleUpdate = async (values: CompanyVariablesUpdate) => {
      values.id = currentSelectedCompany.id;
      await updateCompany(values);
      form.resetFields();
      setAutoCompleteFields();
      closeModal?.();
      notification.open({
        type: "success",
        message: t("Updated Successfully"),
      });
    };

    const handleCreate = async (values: CompanyVariablesCreate) => {
      await createCompany(values);
      form.resetFields();
      setAutoCompleteFields();
      closeModal?.();
    };

    return (
      <Form
        style={{ width: "100%" }}
        onFinish={type === "add" ? handleCreate : handleUpdate}
        {...layout}
        id={formId}
        form={form}
      >
        <Form.Item
          name="typeOfContact"
          label={t("Type of Contact")}
          validateStatus={selectedContactType.err ? "error" : "success"}
          rules={[
            {
              required: true,
              message: t("Please select a type of contact"),
            },
          ]}
        >
          <>
            <AutoCompleteFormField
              value={selectedContactType}
              setValue={setSelectedContactType}
              formFieldName={"typeOfContact"}
              reset={resetAutoCompleteFields}
              setFormValue={form.setFieldsValue}
              options={[
                { value: "Enduser", id: "enduser" },
                { value: "Dealer", id: "dealer" },
              ]}
            />
          </>
        </Form.Item>
        <Form.Item
          name="name"
          label={t("Name")}
          rules={[
            {
              required: true,
              message: t("Please enter a name"),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="street1" label={t("Street 1")}>
          <Input type={"text"} />
        </Form.Item>
        <Form.Item name="street2" label={t("Street 2")}>
          <Input type="text" />
        </Form.Item>
        <Form.Item name="plz" label={t("ZIP")}>
          <Input type="text" />
        </Form.Item>
        <Form.Item name="place" label={t("Place")}>
          <Input type="text" />
        </Form.Item>
        <Form.Item
          name="country"
          label={t("Country")}
          validateStatus={selectedCountry.err ? "error" : "success"}
        >
          <>
            <AutoCompleteFormField
              value={selectedCountry}
              setValue={setSelectedCountry}
              formFieldName={"country"}
              reset={resetAutoCompleteFields}
              setFormValue={form.setFieldsValue}
              options={countries.map((c) => ({ value: c.name, id: c.code }))}
            />
          </>
        </Form.Item>
        <Form.Item name="telephone" label={t("Telephone")}>
          <Input type="text" />
        </Form.Item>
        <Form.Item name="fax" label={t("Fax")}>
          <Input type="text" />
        </Form.Item>
        <Form.Item name="mail" label={t("Email")}>
          <Input type="email" />
        </Form.Item>
        <Form.Item name="web" label={t("Website")}>
          <Input type="text" />
        </Form.Item>
        <Form.Item name="vatNr" label={t("VAT NR")}>
          <Input type="text" />
        </Form.Item>
        <Form.Item name="paymentTerms" label={t("Payment Terms")}>
          <Input type="text" />
        </Form.Item>
        <Form.Item name="comments" label={t("Comments")}>
          <Input type="text" />
        </Form.Item>
      </Form>
    );
  }
);
