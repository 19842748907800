import { API } from "aws-amplify";
import { Observable } from "redux";
import * as subscriptions from "../../graphql/subscriptions";
import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { GraphQLResult } from "@aws-amplify/api";
import {
  Company,
  ContactPerson,
  InboundInquiry,
  InboundOffer,
  OutboundOffer,
  TableEntity,
} from "../../store/types/api.types";
import { Entity } from "../CreateEntityModal/CreateEntityModal";
import { compareDesc } from "date-fns";
import { EntityForm } from "../../pages/DashboardPage/DashboardPage";
import { EntityQueryType } from "../../migration/migration";
import React from "react";

export const queryLimit = 100;

export interface OptionalSearchKey {
  [key: string]: {
    matchPhrasePrefix: string;
  };
}

export interface ApiFetchResponse {
  entities: any[];
  nextToken?: any;
  totalEntities?: number;
}

/**
 * Function that creates subscriptions for  addEvents
 * returns the subscription
 */
export function listenToAddEvent(
  type: Entity,
  setEntities: React.Dispatch<React.SetStateAction<any[]>>
) {
  switch (type) {
    case Entity.COMPANY:
      return (
        API.graphql({
          query: subscriptions.onCreateCompany,
        }) as unknown as Observable<any>
      ).subscribe({
        next: (data) => {
          setEntities((prev) => [data.value.data.onCreateCompany, ...prev]);
        },
      });
    case Entity.CONTACT_PERSON:
      return (
        API.graphql({
          query: subscriptions.onCreateContactPerson,
        }) as unknown as Observable<any>
      ).subscribe({
        next: (data) => {
          setEntities((prev) => [
            data.value.data.onCreateContactPerson,
            ...prev,
          ]);
        },
      });
    case Entity.INBOUND_INQUIRY:
      return (
        API.graphql({
          query: subscriptions.onCreateInboundInquiry,
        }) as unknown as Observable<any>
      ).subscribe({
        next: (data) => {
          setEntities((prev) => [
            data.value.data.onCreateInboundInquiry,
            ...prev,
          ]);
        },
      });
    case Entity.INBOUND_OFFER:
      return (
        API.graphql({
          query: subscriptions.onCreateInboundOffer,
        }) as unknown as Observable<any>
      ).subscribe({
        next: (data) => {
          setEntities((prev) => [
            data.value.data.onCreateInboundOffer,
            ...prev,
          ]);
        },
      });
    case Entity.OUTBOUND_OFFER:
      return (
        API.graphql({
          query: subscriptions.onCreateOutboundOffer,
        }) as unknown as Observable<any>
      ).subscribe({
        next: (data) => {
          setEntities((prev) => [
            data.value.data.onCreateOutboundOffer,
            ...prev,
          ]);
        },
      });
  }
}

export function listenToUpdateEvent(
  type: Entity,
  setEntities: React.Dispatch<React.SetStateAction<any[]>>
) {
  switch (type) {
    case Entity.COMPANY:
      return (
        API.graphql({
          query: subscriptions.onUpdateCompany,
        }) as unknown as Observable<any>
      ).subscribe({
        next: (data) => {
          setEntities((prev) =>
            prev.map((p) =>
              p.id === data.value.data.onUpdateCompany.id
                ? data.value.data.onUpdateCompany
                : p
            )
          );
        },
      });
    case Entity.CONTACT_PERSON:
      return (
        API.graphql({
          query: subscriptions.onUpdateContactPerson,
        }) as unknown as Observable<any>
      ).subscribe({
        next: (data) => {
          setEntities((prev) =>
            prev.map((p) =>
              p.id === data.value.data.onUpdateContactPerson.id
                ? data.value.data.onUpdateContactPerson
                : p
            )
          );
        },
      });
    case Entity.INBOUND_INQUIRY:
      return (
        API.graphql({
          query: subscriptions.onUpdateInboundInquiry,
        }) as unknown as Observable<any>
      ).subscribe({
        next: (data) => {
          console.error(data);
          setEntities((prev) => {
            console.error(
              prev.find(
                (p) => p.id === data.value.data.onUpdateInboundInquiry.id
              )
            );
            return prev;
          });
          setEntities((prev) =>
            prev?.map((p) =>
              p.id === data.value.data.onUpdateInboundInquiry.id
                ? data.value.data.onUpdateInboundInquiry
                : p
            )
          );
        },
      });
    case Entity.INBOUND_OFFER:
      return (
        API.graphql({
          query: subscriptions.onUpdateInboundOffer,
        }) as unknown as Observable<any>
      ).subscribe({
        next: (data) => {
          setEntities((prev) =>
            prev.map((p) =>
              p.id === data.value.data.onUpdateInboundOffer.id
                ? data.value.data.onUpdateInboundOffer
                : p
            )
          );
        },
      });
    case Entity.OUTBOUND_OFFER:
      (
        API.graphql({
          query: subscriptions.onUpdateOutboundOffer,
        }) as unknown as Observable<any>
      ).subscribe({
        next: (data) => {
          setEntities((prev) =>
            prev.map((p) =>
              data.value.data.onUpdateOutboundOffer.id === p.id
                ? data.value.data.onUpdateOutboundOffer
                : p
            )
          );
        },
      });
  }
}

/**
 *
 * @param type
 * @param setEntities
 * @param setSearchTerm
 * @returns
 */
export function listenToDeleteEventEntity(
  type: Entity,
  setEntities: React.Dispatch<React.SetStateAction<any[]>>,
  setSearchTerm?: React.Dispatch<React.SetStateAction<string>>
) {
  switch (type) {
    case Entity.COMPANY:
      return (
        API.graphql({
          query: subscriptions.onDeleteCompany,
        }) as unknown as Observable<any>
      ).subscribe({
        next: (data) => {
          setEntities((prev) =>
            prev.filter((p) => p.id !== data.value.data.onDeleteCompany.id)
          );
          setSearchTerm?.("");
        },
      });
    case Entity.CONTACT_PERSON:
      return (
        API.graphql({
          query: subscriptions.onDeleteContactPerson,
        }) as unknown as Observable<any>
      ).subscribe({
        next: (data) => {
          setEntities((prev) =>
            prev.filter(
              (p) => p.id !== data.value.data.onDeleteContactPerson.id
            )
          );
          setSearchTerm?.("");
        },
      });
    case Entity.INBOUND_INQUIRY:
      return (
        API.graphql({
          query: subscriptions.onDeleteInboundInquiry,
        }) as unknown as Observable<any>
      ).subscribe({
        next: (data) => {
          setEntities((prev) =>
            prev.filter(
              (p) => p.id !== data.value.data.onDeleteInboundInquiry.id
            )
          );
          setSearchTerm?.("");
        },
      });
    case Entity.INBOUND_OFFER:
      return (
        API.graphql({
          query: subscriptions.onDeleteInboundOffer,
        }) as unknown as Observable<any>
      ).subscribe({
        next: (data) => {
          setEntities((prev) =>
            prev.filter((p) => p.id !== data.value.data.onDeleteInboundOffer.id)
          );
          setSearchTerm?.("");
        },
      });
    case Entity.OUTBOUND_OFFER:
      (
        API.graphql({
          query: subscriptions.onDeleteOutboundOffer,
        }) as unknown as Observable<any>
      ).subscribe({
        next: (data) => {
          setEntities((prev) =>
            prev.filter(
              (p) => p.id !== data.value.data.onDeleteOutboundOffer.id
            )
          );
          setSearchTerm?.("");
        },
      });
  }
}

export function addListeners(
  type: EntityForm | undefined,
  setEntities: React.Dispatch<React.SetStateAction<any>>,
  setSearchTerm?: React.Dispatch<React.SetStateAction<string>>,
  withoutAddEvent?: boolean
) {
  switch (type) {
    case EntityForm.COMPANY:
      return [
        withoutAddEvent
          ? undefined
          : listenToAddEvent(Entity.COMPANY, setEntities),
        listenToDeleteEventEntity(Entity.COMPANY, setEntities, setSearchTerm),
        listenToUpdateEvent(Entity.COMPANY, setEntities),
      ];
    case EntityForm.CONTACT_PERSON:
      return [
        withoutAddEvent
          ? undefined
          : listenToAddEvent(Entity.CONTACT_PERSON, setEntities),
        listenToDeleteEventEntity(
          Entity.CONTACT_PERSON,
          setEntities,
          setSearchTerm
        ),
        listenToUpdateEvent(Entity.CONTACT_PERSON, setEntities),
      ];
    case EntityForm.INBOUND_INQUIRY:
      return [
        withoutAddEvent
          ? undefined
          : listenToAddEvent(Entity.INBOUND_INQUIRY, setEntities),
        listenToDeleteEventEntity(
          Entity.INBOUND_INQUIRY,
          setEntities,
          setSearchTerm
        ),
        listenToUpdateEvent(Entity.INBOUND_INQUIRY, setEntities),
      ];
    case EntityForm.INBOUND_OFFER:
      return [
        withoutAddEvent
          ? undefined
          : listenToAddEvent(Entity.INBOUND_OFFER, setEntities),
        listenToDeleteEventEntity(
          Entity.INBOUND_OFFER,
          setEntities,
          setSearchTerm
        ),
        listenToUpdateEvent(Entity.INBOUND_INQUIRY, setEntities),
      ];
    case EntityForm.OUTBOUND_OFFER:
      return [
        withoutAddEvent
          ? undefined
          : listenToAddEvent(Entity.OUTBOUND_OFFER, setEntities),
        listenToDeleteEventEntity(
          Entity.OUTBOUND_OFFER,
          setEntities,
          setSearchTerm
        ),
        listenToUpdateEvent(Entity.OUTBOUND_OFFER, setEntities),
      ];
    default:
      return [
        withoutAddEvent
          ? undefined
          : listenToAddEvent(Entity.INBOUND_INQUIRY, setEntities),
        listenToDeleteEventEntity(
          Entity.INBOUND_INQUIRY,
          setEntities,
          setSearchTerm
        ),
        listenToUpdateEvent(Entity.INBOUND_INQUIRY, setEntities),
      ];
  }
}

/**
 * Function that deletes an entity form the database
 */
export const deleteFeedEntry = async (value: TableEntity, id?: string) => {
  switch (getType(value)) {
    case "Company":
      await API.graphql({
        query: mutations.deleteCompany,
        variables: { input: { id } },
      });
      break;
    case "Contact Person":
      await API.graphql({
        query: mutations.deleteContactPerson,
        variables: { input: { id } },
      });
      break;
    case "Inbound Inquiry":
      await API.graphql({
        query: mutations.deleteInboundInquiry,
        variables: { input: { id } },
      });
      break;
    case "Outbound Offer":
      await API.graphql({
        query: mutations.deleteOutboundOffer,
        variables: { input: { id } },
      });
      break;
    case "Inbound Offer":
      await API.graphql({
        query: mutations.deleteInboundOffer,
        variables: { input: { id } },
      });
  }
};

/**
 * function that determines the type of the Entity
 */
export const getType = (value: TableEntity) => {
  if ("surname" in value) {
    return "Contact Person";
  }
  if ("vatNr" in value) {
    return "Company";
  }
  if ("status" in value) {
    return "Inbound Inquiry";
  }
  if ("totalPrice" in value) {
    return "Inbound Offer";
  }
  if ("salesPrice" in value) {
    return "Outbound Offer";
  }
  if ("subTitle" in value) {
    return "Product";
  }
  return "";
};

/**
 *
 * @param data
 */
export const searchDataFromApi = async (data: {
  query: any;
  entity: EntityForm;
  searchTerm: string;
  searchKey: string;
  optionalSearchKeys?: OptionalSearchKey[];
  nextToken?: string;
}): Promise<ApiFetchResponse> => {
  return await (
    API.graphql({
      query: data.query,
      variables: {
        nextToken: data.nextToken,
        limit: queryLimit,
        filter: !data.optionalSearchKeys
          ? {
              [data.searchKey]: {
                matchPhrasePrefix: data.searchTerm,
              },
            }
          : {
              or: [
                ...data.optionalSearchKeys,
                { [data.searchKey]: { matchPhrasePrefix: data.searchTerm } },
              ],
            },
      },
    }) as Promise<GraphQLResult<any>>
  )
    .then((res) => {
      switch (data.entity) {
        case EntityForm.COMPANY:
          return {
            entities: res.data.searchCompanys.items,
            nextToken: res.data.searchCompanys.nextToken,
            totalEntities: res.data.searchCompanys.total,
          };
        case EntityForm.CONTACT_PERSON:
          return {
            entities: res.data.searchContactPersons.items,
            nextToken: res.data.searchContactPersons.nextToken,
            totalEntities: res.data.searchContactPersons.total,
          };
        case EntityForm.INBOUND_INQUIRY:
          return {
            entities: res.data.searchInboundInquirys.items,
            nextToken: res.data.searchInboundInquirys.nextToken,
            totalEntities: res.data.searchInboundInquirys.total,
          };
        case EntityForm.INBOUND_OFFER:
          return {
            entities: res.data.searchInboundOffers.items,
            nextToken: res.data.searchInboundOffers.nextToken,
            totalEntities: res.data.searchInboundOffers.total,
          };
        case EntityForm.OUTBOUND_OFFER:
          return {
            entities: res.data.searchOutboundOffers.items,
            nextToken: res.data.searchOutboundOffers.nextToken,
            totalEntities: res.data.searchOutboundOffers.total,
          };
      }
    })
    .catch((res) => {
      console.error(res);
      switch (data.entity) {
        case EntityForm.COMPANY:
          return {
            entities: res.data.searchCompanys.items.filter((item: any) => item),
            nextToken: res.data.searchCompanys.nextToken,
            totalEntities: res.data.searchCompanys.total,
          };
        case EntityForm.CONTACT_PERSON:
          return {
            entities: res.data.searchContactPersons.items.filter(
              (item: any) => item
            ),
            nextToken: res.data.searchContactPersons.nextToken,
            totalEntities: res.data.searchContactPersons.total,
          };
        case EntityForm.INBOUND_INQUIRY:
          return {
            entities: res.data.searchInboundInquirys.items.filter(
              (item: any) => item
            ),
            nextToken: res.data.searchInboundInquirys.nextToken,
            totalEntities: res.data.searchInboundInquirys.total,
          };
        case EntityForm.INBOUND_OFFER:
          return {
            entities: res.data.searchInboundOffers.items.filter(
              (item: any) => item
            ),
            nextToken: res.data.searchInboundOffers.nextToken,
            totalEntities: res.data.searchInboundOffers.total,
          };
        case EntityForm.OUTBOUND_OFFER:
          return {
            entities: res.data.searchOutboundOffers.items.filter(
              (item: any) => item
            ),
            nextToken: res.data.searchOutboundOffers.nextToken,
            totalEntities: res.data.searchOutboundOffers.total,
          };
      }
    });
};

/**
 *
 * @param data
 */
export async function searchAllDataFromApi(data: {
  query: any;
  entity: EntityForm;
  searchTerm: string;
  searchKey: string;
  optionalSearchKeys?: OptionalSearchKey[];
}) {
  let res = await searchDataFromApi(data);
  let entities = res.entities;
  while (res.nextToken) {
    res = await searchDataFromApi({ ...data, nextToken: res.nextToken });
    entities = [...entities, ...res.entities];
  }
  return entities;
}

/**
 *
 * @param data
 */
export const getDataFromApi = async (data: {
  query: any;
  entity: Entity;
  type: EntityQueryType;
  sortDirection: "ASC" | "DESC";
  nextToken?: any;
  variables?: Record<string, string>;
  queryName?: string;
}): Promise<ApiFetchResponse> => {
  return await (
    API.graphql({
      query: data.query,
      variables: {
        ...data.variables,
        limit: queryLimit,
        nextToken: data.nextToken,
        type: data.type,
        sortDirection: data.sortDirection,
      },
    }) as Promise<GraphQLResult<any>>
  )
    .then((response) => {
      switch (data.entity) {
        case Entity.COMPANY:
          return {
            entities: response.data.companyByDate.items as Company[],
            nextToken: response.data.companyByDate?.nextToken,
          };
        case Entity.CONTACT_PERSON:
          return {
            entities: response.data.contactPersonsByCreatedAt
              .items as ContactPerson[],
            nextToken: response.data.contactPersonsByCreatedAt?.nextToken,
          };
        case Entity.INBOUND_OFFER:
          return {
            entities: response.data.inboundOffersByDate.items as InboundOffer[],
            nextToken: response.data.inboundOffersByDate?.nextToken,
          };
        case Entity.OUTBOUND_OFFER:
          return {
            entities: response.data.outboundOffersByDate
              .items as OutboundOffer[],
            nextToken: response.data.outboundOffersByDate?.nextToken,
          };
        case Entity.INBOUND_INQUIRY:
          return {
            entities: response.data[data.queryName || "inboundInquiriesByDate"]
              .items as InboundInquiry[],
            nextToken:
              response.data[data.queryName || "inboundInquiriesByDate"]
                ?.nextToken,
          };
        default:
          throw new Error("No entity Provided");
      }
    })
    .catch((e) => {
      console.error(e);
      return { entities: [] };
    });
};

/**
 *
 * @param data
 * @returns
 */
export const getAllDataFromEntity = async (data: {
  query: any;
  entity: Entity;
  nextToken?: any;
  type: EntityQueryType;
  sortDirection: "ASC" | "DESC";
  variables?: Record<string, string>;
  queryName?: string;
  limit?: number;
}) => {
  let res = await getDataFromApi(data);
  let entities = res.entities;
  while (res.nextToken) {
    res = await getDataFromApi({ ...data, nextToken: res.nextToken });
    entities = [...entities, ...res.entities];
    if (data.limit && data.limit <= entities.length) break;
  }
  return entities;
};

/**
 *
 */
export const getAllOpenInboundInquiries = async (): Promise<any[]> => {
  let res = await (
    API.graphql({
      query: queries.inboundInquiriesByStatus,
      variables: {
        limit: queryLimit,
        type: "inboundInquiries",
        status: "open",
        sortDirection: "ASC",
      },
    }) as Promise<GraphQLResult<any>>
  ).then((res) => res.data.inboundInquiriesByStatus);

  let entities = res.items;
  while (res.nextToken) {
    res = await (
      API.graphql({
        query: queries.inboundInquiriesByStatus,
        variables: {
          limit: queryLimit,
          nextToken: res.nextToken,
          status: "open",
          type: "inboundInquiries",
          sortDirection: "ASC",
        },
      }) as Promise<GraphQLResult<any>>
    ).then((res) => res.data.inboundInquiriesByStatus);

    entities = [...entities, ...res.items];
  }
  return entities;
};

//-----------------Sorting-----------------
export const sortDes = (
  a?: string | number | Date,
  b?: string | number | Date
) => {
  if (typeof a === "string" && typeof b === "string") {
    if (a.toUpperCase() > b.toUpperCase()) return -1;
    if (a.toUpperCase() < b.toUpperCase()) return 1;
    return 0;
  }
  if (typeof a === "number" && typeof b === "number") {
    return a - b;
  }
  if (a && b) {
    return compareDesc(new Date(b), new Date(a));
  }
  return a ? -1 : b ? 1 : 0;
};

export const sortAsc = (
  a?: string | number | Date,
  b?: string | number | Date
) => {
  if (typeof a === "string" && typeof b === "string") {
    if (a.toUpperCase() < b.toUpperCase()) return -1;
    if (a.toUpperCase() > b.toUpperCase()) return 1;
    return 0;
  }
  if (typeof a === "number" && typeof b === "number") {
    return b - a;
  }
  if (a && b) {
    return compareDesc(new Date(a), new Date(b));
  }
  return a ? -1 : b ? 1 : 0;
};

export type SortKeyValues =
  | "lysisMember"
  | "date"
  | "product"
  | "condition"
  | "warranty"
  | "purchasePrice"
  | "salesPrice"
  | "totalPrice"
  | "shipping"
  | "status"
  | "createdAt"
  | "updatedAt"
  | "margin"
  | "currency"
  | "name"
  | "surename"
  | "mail"
  | "telephone"
  | "paymentTerms"
  | "vatNr"
  | "country";

export const sortEntity = (
  entities: Array<TableEntity>,
  sortByKey: SortKeyValues,
  order: "asc" | "desc"
) => {
  if (!entities || entities.length === 0 || !(sortByKey in entities[0])) {
    return [];
  }
  return entities.sort((a: any, b: any) =>
    order === "asc"
      ? sortAsc(a[sortByKey], b[sortByKey])
      : sortDes(a[sortByKey], b[sortByKey])
  );
};
