import React, { useState } from "react";
import { Form, Input, Button, Col, Row, Grid } from "antd";
import { useDispatch } from "react-redux";
import { logInUser } from "../../store/actions/user.actions";
import { User, UserInformation } from "../../store/types/user.types";
import { useTranslation } from "react-i18next";
import { signIn } from "./model";
import { FormState } from "../../pages/AuthenticationPage/AuthenticationPage";
import { AuthCard } from "../AuthCard/AuthCard";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const { useBreakpoint } = Grid;

interface Props {
  setFormState: React.Dispatch<React.SetStateAction<FormState>>;
}

export const SignInForm: React.FC<Props> = ({ setFormState }) => {
  const [form] = Form.useForm();
  const [userFound, setUserFound] = useState(true);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const screens = useBreakpoint();

  const submitForm = async (userInformation: UserInformation) => {
    const check = await signIn(userInformation);
    if (check) {
      setUserFound(true);
      const user: User = {
        attributes: {
          email: userInformation.email,
          family_name: userInformation.lastName,
          name: userInformation.firstName,
          picture: userInformation.photoUrl,
        },
      };
      dispatch(logInUser(user));
    } else {
      setUserFound(false);
    }
  };

  return (
    <AuthCard title={FormState.SIGN_IN}>
      <Form onFinish={submitForm} {...layout} form={form}>
        <Form.Item
          name="email"
          label="Email"
          rules={[
            {
              required: true,
              message: t("Please enter an email"),
            },
          ]}
        >
          <Input type="email" />
        </Form.Item>
        <Form.Item
          name="password"
          label={t("Password")}
          rules={[
            {
              required: true,
              message: t("Please enter a password"),
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item hidden={userFound} wrapperCol={{ offset: 8 }}>
          <p style={{ color: "#ff4d4f", margin: 0 }}>
            {"*" + t("Cant find User or wrong password")}
          </p>
        </Form.Item>
        <Form.Item wrapperCol={{ offset: screens.xs ? 0 : 8 }}>
          <Button style={{ width: "100%" }} htmlType="submit" type="primary">
            {t("Log in")}
          </Button>
        </Form.Item>
        <Form.Item wrapperCol={{ offset: screens.xs ? 0 : 8 }}>
          <Row justify="center">
            <Col style={{ textAlign: "center" }} xs={24} sm={10}>
              <Button
                type="link"
                style={{ color: "#1890FF" }}
                onClick={() => setFormState(FormState.FORGOT_PASSWORD)}
              >
                {t("Forgot Password?")}
              </Button>
            </Col>
            {/*<Col xs={24} sm={10} style={{ textAlign: "center" }}>*/}
            {/*  <Button*/}
            {/*    type="link"*/}
            {/*    style={{ color: "#1890FF" }}*/}
            {/*    onClick={() => setFormState(FormState.SIGN_UP)}*/}
            {/*  >*/}
            {/*    {t("Create account")}*/}
            {/*  </Button>*/}
            {/*</Col>*/}
          </Row>
        </Form.Item>
      </Form>
    </AuthCard>
  );
};
