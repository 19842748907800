import React, { memo, useEffect, useState } from "react";
import { Button, Form, Input, Row, Col, notification } from "antd";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { CreateEntityModal } from "../CreateEntityModal/CreateEntityModal";
import CompanyForm from "../CompanyForm/CompanyForm";
import {
  AutoCompleteFormField,
  AutoCompleteValue,
} from "../AutoCompleteFormField/AutoCompleteFormField";
import { useAllCompanies } from "../Feed/hook.feed";
import {
  ContactPersonVariablesCreate,
  ContactPersonVariablesUpdate,
} from "../../graphql/type.graphql";
import { useContactPersonPrefill } from "./hook.contactPersonForm";
import { getEmptyAutoCompleteValue } from "../InboundOfferForm/model.inboundOfferForm";
import {
  createContactPerson,
  updateContactPerson,
} from "./model.contactPersonForm";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

interface Props {
  formId?: string;
  closeModal?: () => void;
  resetAutoCompleteFields?: boolean;
  type?: "update" | "add";
}

interface FormFields {
  id: string;
  contactPersonCompanyId: string;
  name: string;
  surname: string;
  salutation?: string;
  title?: string;
  telephone?: string;
  mail?: string;
  comments?: string;
}

export default memo<Props>(
  ({ formId, closeModal, type, resetAutoCompleteFields }) => {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const { entities: companies } = useAllCompanies();
    const [selectedCompany, setSelectedCompany] = useState<AutoCompleteValue>(
      getEmptyAutoCompleteValue()
    );
    const [selectedSalutation, setSelectedSalutation] =
      useState<AutoCompleteValue>(getEmptyAutoCompleteValue());
    const [selectedTitle, setSelectedTitle] = useState<AutoCompleteValue>(
      getEmptyAutoCompleteValue()
    );
    const [showCompanyForm, setShowCompanyForm] = useState(false);

    useEffect(() => () => resetForm(), []);

    const setAutoCompleteFields = (
      company?: AutoCompleteValue,
      salutation?: AutoCompleteValue,
      title?: AutoCompleteValue
    ) => {
      setSelectedSalutation(salutation || getEmptyAutoCompleteValue());
      setSelectedCompany(company || getEmptyAutoCompleteValue());
      setSelectedTitle(title || getEmptyAutoCompleteValue());
    };

    const { currentSelectedContactPerson } = useContactPersonPrefill(
      form,
      setAutoCompleteFields
    );

    const handleUpdate = async (values: ContactPersonVariablesUpdate) => {
      values.id = currentSelectedContactPerson.id;
      await updateContactPerson(values);
      resetForm();
      closeModal?.();
      notification.open({
        message: t("Updated Successfully"),
        type: "success",
      });
    };

    const handleCreate = async (values: ContactPersonVariablesCreate) => {
      await createContactPerson(values);
      resetForm();
      closeModal?.();
    };

    const resetForm = () => {
      form.resetFields();
      setAutoCompleteFields();
    };

    return (
      <Form
        style={{ width: "100%" }}
        onFinish={type === "add" ? handleCreate : handleUpdate}
        id={formId}
        {...layout}
        form={form}
      >
        <Form.Item
          label={t("Contact")}
          name="contactPersonCompanyId"
          validateStatus={selectedCompany.err ? "error" : "success"}
          rules={[
            {
              required: true,
              message: t("There is no company with this name"),
            },
          ]}
        >
          <Row>
            <Col span={20}>
              <AutoCompleteFormField
                reset={resetAutoCompleteFields}
                formFieldName="contactPersonCompanyId"
                setFormValue={form.setFieldsValue}
                options={companies.map((c) => ({
                  value: c.name,
                  id: c.id,
                }))}
                value={selectedCompany}
                setValue={setSelectedCompany}
              />
            </Col>
            <Col style={{ textAlign: "right" }} span={4}>
              <Button
                shape="circle"
                onClick={() => setShowCompanyForm((prev) => !prev)}
              >
                +
              </Button>
            </Col>
            <CreateEntityModal
              formId="company-form-id"
              onCancel={() => setShowCompanyForm(false)}
              visible={showCompanyForm}
              title="Add Company"
            >
              <CompanyForm
                closeModal={() => setShowCompanyForm(false)}
                type="add"
                formId="company-form-id"
              />
            </CreateEntityModal>
          </Row>
        </Form.Item>
        <Form.Item
          name="salutation"
          label={t("Salutation")}
          validateStatus={selectedSalutation.err ? "error" : "success"}
        >
          <>
            <AutoCompleteFormField
              reset={resetAutoCompleteFields}
              formFieldName="salutation"
              setFormValue={form.setFieldsValue}
              options={[
                { value: "Mr", id: "mr" },
                { value: "Ms", id: "ms" },
              ]}
              value={selectedSalutation}
              setValue={setSelectedSalutation}
            />
          </>
        </Form.Item>
        <Form.Item name="title" label={t("Title")}>
          <>
            <AutoCompleteFormField
              reset={resetAutoCompleteFields}
              formFieldName="title"
              setFormValue={form.setFieldsValue}
              options={[
                { value: "MD", id: "md" },
                { value: "DR", id: "dr" },
                { value: "PHD", id: "phd" },
              ]}
              value={selectedTitle}
              setValue={setSelectedTitle}
            />
          </>
        </Form.Item>
        <Form.Item
          name="name"
          label={t("Name")}
          rules={[
            {
              required: true,
              message: t("Please enter a name"),
            },
          ]}
        >
          <Input type="text" />
        </Form.Item>
        <Form.Item
          name="surname"
          label={t("Surname")}
          rules={[
            {
              required: true,
              message: t("Please enter a surname"),
            },
          ]}
        >
          <Input type="text" />
        </Form.Item>
        <Form.Item name="mail" label={t("Email")}>
          <Input type="mail" />
        </Form.Item>
        <Form.Item name="telephone" label={t("Telephone")}>
          <Input type="text" />
        </Form.Item>
        <Form.Item name="comments" label={t("Comments")}>
          <Input type="text" />
        </Form.Item>
      </Form>
    );
  }
);
