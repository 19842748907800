/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateCompany = /* GraphQL */ `
  subscription OnCreateCompany {
    onCreateCompany {
      id
      typeOfContact
      name
      street1
      street2
      zip
      place
      country
      telephone
      fax
      type
      plz
      mail
      web
      vatNr
      comments
      paymentTerms
      createdAt
      contactPersons {
        nextToken
      }
      inboundInquiries {
        nextToken
      }
      suppliedOutboundOffers {
        nextToken
      }
      outboundOffers {
        nextToken
      }
      inboundOffers {
        nextToken
      }
      updatedAt
    }
  }
`;
export const onUpdateCompany = /* GraphQL */ `
  subscription OnUpdateCompany {
    onUpdateCompany {
      id
      typeOfContact
      name
      street1
      street2
      zip
      place
      country
      telephone
      fax
      type
      plz
      mail
      web
      vatNr
      comments
      paymentTerms
      createdAt
      contactPersons {
        nextToken
      }
      inboundInquiries {
        nextToken
      }
      suppliedOutboundOffers {
        nextToken
      }
      outboundOffers {
        nextToken
      }
      inboundOffers {
        nextToken
      }
      updatedAt
    }
  }
`;
export const onDeleteCompany = /* GraphQL */ `
  subscription OnDeleteCompany {
    onDeleteCompany {
      id
      typeOfContact
      name
      street1
      street2
      zip
      place
      country
      telephone
      fax
      type
      plz
      mail
      web
      vatNr
      comments
      paymentTerms
      createdAt
      contactPersons {
        nextToken
      }
      inboundInquiries {
        nextToken
      }
      suppliedOutboundOffers {
        nextToken
      }
      outboundOffers {
        nextToken
      }
      inboundOffers {
        nextToken
      }
      updatedAt
    }
  }
`;
export const onCreateContactPerson = /* GraphQL */ `
  subscription OnCreateContactPerson {
    onCreateContactPerson {
      id
      salutation
      title
      name
      surname
      telephone
      type
      mail
      comments
      createdAt
      companyName
      company {
        id
        typeOfContact
        name
        street1
        street2
        zip
        place
        country
        telephone
        fax
        type
        plz
        mail
        web
        vatNr
        comments
        paymentTerms
        createdAt
        updatedAt
      }
      inboundInquiries {
        nextToken
      }
      updatedAt
    }
  }
`;
export const onUpdateContactPerson = /* GraphQL */ `
  subscription OnUpdateContactPerson {
    onUpdateContactPerson {
      id
      salutation
      title
      name
      surname
      telephone
      type
      mail
      comments
      createdAt
      companyName
      company {
        id
        typeOfContact
        name
        street1
        street2
        zip
        place
        country
        telephone
        fax
        type
        plz
        mail
        web
        vatNr
        comments
        paymentTerms
        createdAt
        updatedAt
      }
      inboundInquiries {
        nextToken
      }
      updatedAt
    }
  }
`;
export const onDeleteContactPerson = /* GraphQL */ `
  subscription OnDeleteContactPerson {
    onDeleteContactPerson {
      id
      salutation
      title
      name
      surname
      telephone
      type
      mail
      comments
      createdAt
      companyName
      company {
        id
        typeOfContact
        name
        street1
        street2
        zip
        place
        country
        telephone
        fax
        type
        plz
        mail
        web
        vatNr
        comments
        paymentTerms
        createdAt
        updatedAt
      }
      inboundInquiries {
        nextToken
      }
      updatedAt
    }
  }
`;
export const onCreateInboundInquiry = /* GraphQL */ `
  subscription OnCreateInboundInquiry {
    onCreateInboundInquiry {
      id
      lysisMember
      lysisEditor
      leadOrigin
      date
      product
      condition
      type
      inquiryComments
      status
      companyName
      company {
        id
        typeOfContact
        name
        street1
        street2
        zip
        place
        country
        telephone
        fax
        type
        plz
        mail
        web
        vatNr
        comments
        paymentTerms
        createdAt
        updatedAt
      }
      contactPerson {
        id
        salutation
        title
        name
        surname
        telephone
        type
        mail
        comments
        createdAt
        companyName
        updatedAt
      }
      outboundOffers {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateInboundInquiry = /* GraphQL */ `
  subscription OnUpdateInboundInquiry {
    onUpdateInboundInquiry {
      id
      lysisMember
      lysisEditor
      leadOrigin
      date
      product
      condition
      type
      inquiryComments
      status
      companyName
      company {
        id
        typeOfContact
        name
        street1
        street2
        zip
        place
        country
        telephone
        fax
        type
        plz
        mail
        web
        vatNr
        comments
        paymentTerms
        createdAt
        updatedAt
      }
      contactPerson {
        id
        salutation
        title
        name
        surname
        telephone
        type
        mail
        comments
        createdAt
        companyName
        updatedAt
      }
      outboundOffers {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteInboundInquiry = /* GraphQL */ `
  subscription OnDeleteInboundInquiry {
    onDeleteInboundInquiry {
      id
      lysisMember
      lysisEditor
      leadOrigin
      date
      product
      condition
      type
      inquiryComments
      status
      companyName
      company {
        id
        typeOfContact
        name
        street1
        street2
        zip
        place
        country
        telephone
        fax
        type
        plz
        mail
        web
        vatNr
        comments
        paymentTerms
        createdAt
        updatedAt
      }
      contactPerson {
        id
        salutation
        title
        name
        surname
        telephone
        type
        mail
        comments
        createdAt
        companyName
        updatedAt
      }
      outboundOffers {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateOutboundOffer = /* GraphQL */ `
  subscription OnCreateOutboundOffer {
    onCreateOutboundOffer {
      id
      lysisMember
      date
      condition
      type
      warranty
      inquiryComments
      purchasePrice
      salesPrice
      margin
      companyName
      product
      offerStatus
      supplier {
        id
        typeOfContact
        name
        street1
        street2
        zip
        place
        country
        telephone
        fax
        type
        plz
        mail
        web
        vatNr
        comments
        paymentTerms
        createdAt
        updatedAt
      }
      inboundInquiry {
        id
        lysisMember
        lysisEditor
        leadOrigin
        date
        product
        condition
        type
        inquiryComments
        status
        companyName
        createdAt
        updatedAt
      }
      company {
        id
        typeOfContact
        name
        street1
        street2
        zip
        place
        country
        telephone
        fax
        type
        plz
        mail
        web
        vatNr
        comments
        paymentTerms
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateOutboundOffer = /* GraphQL */ `
  subscription OnUpdateOutboundOffer {
    onUpdateOutboundOffer {
      id
      lysisMember
      date
      condition
      type
      warranty
      inquiryComments
      purchasePrice
      salesPrice
      margin
      companyName
      product
      offerStatus
      supplier {
        id
        typeOfContact
        name
        street1
        street2
        zip
        place
        country
        telephone
        fax
        type
        plz
        mail
        web
        vatNr
        comments
        paymentTerms
        createdAt
        updatedAt
      }
      inboundInquiry {
        id
        lysisMember
        lysisEditor
        leadOrigin
        date
        product
        condition
        type
        inquiryComments
        status
        companyName
        createdAt
        updatedAt
      }
      company {
        id
        typeOfContact
        name
        street1
        street2
        zip
        place
        country
        telephone
        fax
        type
        plz
        mail
        web
        vatNr
        comments
        paymentTerms
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteOutboundOffer = /* GraphQL */ `
  subscription OnDeleteOutboundOffer {
    onDeleteOutboundOffer {
      id
      lysisMember
      date
      condition
      type
      warranty
      inquiryComments
      purchasePrice
      salesPrice
      margin
      companyName
      product
      offerStatus
      supplier {
        id
        typeOfContact
        name
        street1
        street2
        zip
        place
        country
        telephone
        fax
        type
        plz
        mail
        web
        vatNr
        comments
        paymentTerms
        createdAt
        updatedAt
      }
      inboundInquiry {
        id
        lysisMember
        lysisEditor
        leadOrigin
        date
        product
        condition
        type
        inquiryComments
        status
        companyName
        createdAt
        updatedAt
      }
      company {
        id
        typeOfContact
        name
        street1
        street2
        zip
        place
        country
        telephone
        fax
        type
        plz
        mail
        web
        vatNr
        comments
        paymentTerms
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateInboundOffer = /* GraphQL */ `
  subscription OnCreateInboundOffer {
    onCreateInboundOffer {
      id
      type
      lysisMember
      date
      product
      condition
      inquiryComments
      currency
      purchasePrice
      warranty
      shipping
      totalPrice
      companyName
      company {
        id
        typeOfContact
        name
        street1
        street2
        zip
        place
        country
        telephone
        fax
        type
        plz
        mail
        web
        vatNr
        comments
        paymentTerms
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateInboundOffer = /* GraphQL */ `
  subscription OnUpdateInboundOffer {
    onUpdateInboundOffer {
      id
      type
      lysisMember
      date
      product
      condition
      inquiryComments
      currency
      purchasePrice
      warranty
      shipping
      totalPrice
      companyName
      company {
        id
        typeOfContact
        name
        street1
        street2
        zip
        place
        country
        telephone
        fax
        type
        plz
        mail
        web
        vatNr
        comments
        paymentTerms
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteInboundOffer = /* GraphQL */ `
  subscription OnDeleteInboundOffer {
    onDeleteInboundOffer {
      id
      type
      lysisMember
      date
      product
      condition
      inquiryComments
      currency
      purchasePrice
      warranty
      shipping
      totalPrice
      companyName
      company {
        id
        typeOfContact
        name
        street1
        street2
        zip
        place
        country
        telephone
        fax
        type
        plz
        mail
        web
        vatNr
        comments
        paymentTerms
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
