import {
  ADD_COMPANIE,
  ADD_CONTACT_PERSON,
  ADD_INBOUND_INQUIRY,
  ADD_INBOUND_OFFER,
  ADD_OUTBOUND_OFFER,
  ADD_PRODUCTS,
  ApiActionType,
  Company,
  ContactPerson,
  DELETE_COMPANY,
  DELETE_CONTACT_PERSON,
  DELETE_INBOUND_INQUIRY,
  DELETE_INBOUND_OFFER,
  DELETE_OUTBOUND_OFFER,
  EDITING_FINISHED,
  EDIT_ENTITY,
  InboundInquiry,
  InboundOffer,
  OutboundOffer,
  TableEntity,
  Product,
  ADD_RPODUCTS_SELECT_FIELDS,
  FILTER_INBOUND_INQUIRIES,
  FILTER_OUTBOUND_OFFERS,
  FILTER_INBOUND_OFFERS,
  CLEAR_ALL_FILTERS,
  FILTER_ALL_DATA,
  SET_FORM_VALUES,
  FILTER_COMPANIES,
  FILTER_CONTACT_PERSONS,
  ERROR_OCCURED,
} from "../types/api.types";

export function addCompanie(data: {
  companies: Array<Company> | null;
  company: Company | null;
}): ApiActionType {
  return {
    type: ADD_COMPANIE,
    payload: {
      arr: data.companies ? data.companies : null,
      singleItem: data.company ? data.company : null,
    },
  };
}

export function addContactPerson(data: {
  contactPersons: Array<ContactPerson> | null;
  contactPerson: ContactPerson | null;
}): ApiActionType {
  return {
    type: ADD_CONTACT_PERSON,
    payload: {
      arr: data.contactPersons ? data.contactPersons : null,
      singleItem: data.contactPerson ? data.contactPerson : null,
    },
  };
}

export function addInboundInquiry(data: {
  inboundInquiries: Array<InboundInquiry> | null;
  inboundInquiry: InboundInquiry | null;
}): ApiActionType {
  return {
    type: ADD_INBOUND_INQUIRY,
    payload: {
      arr: data.inboundInquiries ? data.inboundInquiries : null,
      singleItem: data.inboundInquiry ? data.inboundInquiry : null,
    },
  };
}

export function addInboundOffer(data: {
  inboundOffers: Array<InboundOffer> | null;
  inboundOffer: InboundOffer | null;
}): ApiActionType {
  return {
    type: ADD_INBOUND_OFFER,
    payload: {
      arr: data.inboundOffers ? data.inboundOffers : null,
      singleItem: data.inboundOffer ? data.inboundOffer : null,
    },
  };
}

export function addOutboundOffer(data: {
  outboundOffers: Array<OutboundOffer> | null;
  outboundOffer: OutboundOffer | null;
}): ApiActionType {
  return {
    type: ADD_OUTBOUND_OFFER,
    payload: {
      arr: data.outboundOffers ? data.outboundOffers : null,
      singleItem: data.outboundOffer ? data.outboundOffer : null,
    },
  };
}

export function deleteCompany(id: string): ApiActionType {
  return {
    type: DELETE_COMPANY,
    payload: { id },
  };
}

export function deleteContactPerson(id: string): ApiActionType {
  return {
    type: DELETE_CONTACT_PERSON,
    payload: { id },
  };
}

export function deleteInboundInquiry(id: string): ApiActionType {
  return {
    type: DELETE_INBOUND_INQUIRY,
    payload: { id },
  };
}

export function deleteOutboundOffer(id: string): ApiActionType {
  return {
    type: DELETE_OUTBOUND_OFFER,
    payload: { id },
  };
}

export function deleteInboundOffer(id: string): ApiActionType {
  return {
    type: DELETE_INBOUND_OFFER,
    payload: { id },
  };
}

export function editEntity(entity: TableEntity): ApiActionType {
  return {
    type: EDIT_ENTITY,
    payload: { entity },
  };
}

export function editingFinished(): ApiActionType {
  return {
    type: EDITING_FINISHED,
  };
}

export function addProducts(products: Array<Product>): ApiActionType {
  return {
    type: ADD_PRODUCTS,
    payload: {
      products,
    },
  };
}

export function setFormValues(setFormValues: boolean): ApiActionType {
  return {
    type: SET_FORM_VALUES,
    payload: {
      setFormValues,
    },
  };
}

export function AddProductsSelectFields(
  products: Array<Product>
): ApiActionType {
  return {
    type: ADD_RPODUCTS_SELECT_FIELDS,
    payload: {
      products,
    },
  };
}

export function filterInboundInquiries(
  inboundInquiries: Array<InboundInquiry>
): ApiActionType {
  return {
    type: FILTER_INBOUND_INQUIRIES,
    payload: { inboundInquiries },
  };
}

export function filterOutboundOffers(
  outboundOffers: Array<OutboundOffer>
): ApiActionType {
  return {
    type: FILTER_OUTBOUND_OFFERS,
    payload: { outboundOffers },
  };
}

export function filterInboundOffers(
  inboundOffers: Array<InboundOffer>
): ApiActionType {
  return {
    type: FILTER_INBOUND_OFFERS,
    payload: { inboundOffers },
  };
}

export function clearAllFilters(): ApiActionType {
  return {
    type: CLEAR_ALL_FILTERS,
  };
}

export function filterAllData(filteredData: Array<TableEntity>): ApiActionType {
  return {
    type: FILTER_ALL_DATA,
    payload: { filteredData },
  };
}

export function filterCompanies(companies: Array<Company>): ApiActionType {
  return {
    type: FILTER_COMPANIES,
    payload: { companies },
  };
}

export function filterContactPersons(
  contactPersons: Array<ContactPerson>
): ApiActionType {
  return {
    type: FILTER_CONTACT_PERSONS,
    payload: { contactPersons },
  };
}

export function errorOccured(errorMassage: string): ApiActionType {
  return {
    type: ERROR_OCCURED,
    payload: { errorMassage },
  };
}
