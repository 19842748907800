import { FormInstance } from "antd/lib/form";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { InboundInquiry } from "../../store/types/api.types";
import { useEffect } from "react";
import format from "date-fns/format";
import { AutoCompleteValue } from "../AutoCompleteFormField/AutoCompleteFormField";
import { setFormValues } from "../../store/actions/api.actions";
import { InboundInquiryVariablesUpdate } from "../../graphql/type.graphql";
import { formField } from "aws-amplify";

export function useInboundInquiryPrefill(
  form: FormInstance<
    InboundInquiryVariablesUpdate & { emailContactPerson: string }
  >,
  setAutoCompleteFields: (
    company?: AutoCompleteValue,
    contactPerson?: AutoCompleteValue,
    status?: AutoCompleteValue,
    condition?: AutoCompleteValue,
    product?: AutoCompleteValue,
    leadOrigin?: AutoCompleteValue
  ) => void
) {
  const dispatch = useDispatch();
  const currentSelectedInboundInquiry: InboundInquiry = useSelector(
    (state: RootStateOrAny) => state.apiReducer.edit.currSelectedItem
  );
  const shouldPrefillForm: boolean = useSelector(
    (state: RootStateOrAny) => state.apiReducer.edit.setFormValues
  );
  const userEmail: string = useSelector(
    (state: RootStateOrAny) => state.userReducer.user.attributes.email
  );

  useEffect(() => {
    if (!form.getFieldValue("lysisMember")) {
      form.setFieldsValue({
        lysisMember: userEmail,
      });
    }
  }, [form.getFieldValue("lysisMember")]);

  useEffect(() => {
    if (!shouldPrefillForm) {
      form.setFieldsValue({
        date: format(new Date(), "yyyy-MM-dd"),
        status: "open",
        leadOrigin: "mail",
      });
    }
  }, []);

  useEffect(() => {
    if (currentSelectedInboundInquiry && shouldPrefillForm) {
      form.setFieldsValue({
        lysisMember: currentSelectedInboundInquiry.lysisMember,
        date: currentSelectedInboundInquiry.date,
        product: currentSelectedInboundInquiry.product,
        condition: currentSelectedInboundInquiry.condition,
        inquiryComments: currentSelectedInboundInquiry.inquiryComments,
        leadOrigin: currentSelectedInboundInquiry.leadOrigin,
        status: currentSelectedInboundInquiry.status,
        inboundInquiryCompanyId: currentSelectedInboundInquiry.company?.id,
        inboundInquiryContactPersonId:
          currentSelectedInboundInquiry.contactPerson?.id,
        emailContactPerson: currentSelectedInboundInquiry.contactPerson?.mail,
      });
      const company: AutoCompleteValue = {
        val: currentSelectedInboundInquiry?.company.name,
        id: currentSelectedInboundInquiry?.company?.id,
        err: false,
      };
      const contactPerson: AutoCompleteValue = {
        val: `${currentSelectedInboundInquiry.contactPerson?.name} ${currentSelectedInboundInquiry.contactPerson?.surname}`,
        id: currentSelectedInboundInquiry.contactPerson?.id,
        err: false,
      };
      const status: AutoCompleteValue = {
        val: currentSelectedInboundInquiry?.status,
        id: currentSelectedInboundInquiry?.status,
        err: false,
      };
      const condition: AutoCompleteValue = {
        val: currentSelectedInboundInquiry?.condition,
        id: currentSelectedInboundInquiry?.condition,
        err: false,
      };
      const product: AutoCompleteValue = {
        val: currentSelectedInboundInquiry.product,
        id: currentSelectedInboundInquiry.product,
        err: false,
      };
      const leadOrigin: AutoCompleteValue = {
        val: currentSelectedInboundInquiry.leadOrigin || "",
        id: currentSelectedInboundInquiry.leadOrigin || "",
        err: false,
      };
      setAutoCompleteFields(
        company,
        contactPerson,
        status,
        condition,
        product,
        leadOrigin
      );
      dispatch(setFormValues(false));
    }
  });
  return {
    currentSelectedInboundInquiry,
  };
}
