import React, { useEffect, useState } from "react";
import { InboundInquiry, OutboundOffer } from "../../store/types/api.types";
import { LoadingSpinner } from "../LoadingSpinner/LoadingSpinner";
import * as queries from "../../graphql/customQueries";
import { getSingleDataEntryFromApi } from "../../graphql/model.graphql";
import { EntityTable } from "./EntityTable";
import { Routes } from "../NavBar/routes";
import { Unsubscribe } from "redux";
import { listenToUpdateEvent } from "../Feed/model";
import { Entity } from "../CreateEntityModal/CreateEntityModal";

interface ExpandableTableProps {
  inboundInquiry: InboundInquiry;
}

export function ExpandableTable({ inboundInquiry }: ExpandableTableProps) {
  const [outboundOffers, setOutboundOffers] = useState<OutboundOffer[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [subscriptions, setSubscriptions] = useState<
    | {
        unsubscribe: Unsubscribe;
      }[]
    | undefined
  >();

  const getData = async () => {
    const result = await getSingleDataEntryFromApi({
      query: queries.outboundOffersOfInboundInquiry,
      id: inboundInquiry.id,
    });
    setOutboundOffers(result.data.getInboundInquiry.outboundOffers.items);
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
    setSubscriptions([
      listenToUpdateEvent(Entity.OUTBOUND_OFFER, setOutboundOffers)!,
    ]);
    return () => subscriptions?.forEach((s) => s.unsubscribe());
  }, []);

  if (isLoading) {
    return <LoadingSpinner size={"fit"} />;
  }

  return (
    <EntityTable
      hideActions
      setIsEditorOpen={() => {}}
      data={outboundOffers}
      pathNameExpandableTable={Routes.OutboundOffers}
    />
  );
}
