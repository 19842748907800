import { useEffect, useState } from "react";
import { searchProduct } from "../components/SearchBar/model";
import { notification } from "antd";
import { useTranslation } from "react-i18next";

/**
 *
 */
export function useProducts() {
  const [products, setProducts] = useState<{ value: string; id: string }[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    searchProduct("", 0, 10000)
      .then((products) => {
        setProducts(
          products.map((p) => ({ value: p.title, id: p.title, err: false }))
        );
      })
      .catch((e) => {
        console.error(e);
        notification.open({
          type: "error",
          message: t("Something went wrong"),
        });
      });
  }, []);

  return {
    products,
    setProducts,
  };
}
