export enum Routes {
  Auth = "/auth",
  Dashboard = "/dashboard",
  Admin = "/admin",
  Profile = "/me",
  Domain = "/domain",
  InboundInquiries = "/domain/inbound-inquiries",
  OutboundOffers = "/domain/outbound-offers",
  InboundOffers = "/domain/inbound-offer",
  ContactPersons = "/domain/contact-perons",
  Companies = "/domain/companies",
  Editor = "/editor/:type",
}
