import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { FullscreenCenteredContainer } from "../FullscreenCenteredConatiner/FullscreenCenteredContainer";

interface Props {
  size: "fullscreen" | "fit";
}

export const LoadingSpinner: React.FC<Props> = ({ size }) => {
  if (size === "fullscreen")
    return (
      <FullscreenCenteredContainer>
        <Spin indicator={<LoadingOutlined style={{ fontSize: 350 }} spin />} />
      </FullscreenCenteredContainer>
    );
  else
    return (
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "20px",
        }}
      >
        <Spin indicator={<LoadingOutlined style={{ fontSize: 100 }} spin />} />
      </div>
    );
};
