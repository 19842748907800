import {
  ContactPerson,
  InboundInquiry,
  TableEntity,
} from "../../store/types/api.types";
import {
  CreateEntityVariables,
  InboundInquiryVariablesCreate,
  InboundInquiryVariablesUpdate,
  UpdateEntityVariables,
} from "../../graphql/type.graphql";
import {
  createEntity,
  getCompany,
  updateEntity,
} from "../../graphql/model.graphql";
import * as mutations from "../../graphql/mutations";
import { API } from "aws-amplify";

/**
 *
 * @param currentSelectedInboundInquiry
 */
export function initContactPerson(
  currentSelectedInboundInquiry: InboundInquiry
): ContactPerson {
  return {
    id: currentSelectedInboundInquiry?.contactPerson?.id,
    name: currentSelectedInboundInquiry?.contactPerson?.name,
    surname: currentSelectedInboundInquiry?.contactPerson?.surname,
    mail: currentSelectedInboundInquiry?.contactPerson?.mail,
    company: {
      id: currentSelectedInboundInquiry?.company?.id,
      name: currentSelectedInboundInquiry?.company?.name,
    },
    updatedAt: new Date(),
    createdAt: new Date(),
    type: "contactPerson",
  };
}

/**
 *
 * @param propsToRemove
 * @param values
 */
export function removePropsOfValues(
  propsToRemove: string[],
  values: CreateEntityVariables | UpdateEntityVariables | TableEntity
) {
  const ob: any = {};
  Object.keys(values).forEach((key) => {
    if (!propsToRemove.includes(key)) {
      // @ts-ignore
      ob[key] = values[key];
    }
  });
  return ob;
}

/**
 *
 * @param selectedProducts
 * @param values
 */
export async function createInboundInquiry(
  selectedProducts: string[],
  values: InboundInquiryVariablesCreate & { emailContactPerson: string }
) {
  const company = await getCompany(values.inboundInquiryCompanyId);
  await Promise.all(
    selectedProducts.map(async (product) =>
      createEntity({
        mutation: mutations.createInboundInquiry,
        variables: {
          ...removePropsOfValues(["emailContactPerson"], values),
          product: product,
          type: "inboundInquiry",
          companyName: company?.name || "",
        },
      })
    )
  );
}

/**
 *
 * @param values
 */
export async function updateInboundInquiry(
  values: InboundInquiryVariablesUpdate & { emailContactPerson: string }
) {
  const company = await getCompany(values.inboundInquiryCompanyId);
  await updateEntity({
    mutation: mutations.updateInboundInquiry,
    variables: {
      ...removePropsOfValues(["emailContactPerson"], values),
      companyName: company?.name || "",
    },
  });
}

/**
 *
 * @param id
 */
export async function offerInboundInquiry(id: string) {
  await API.graphql({
    query: mutations.updateInboundInquiry,
    variables: {
      input: {
        id,
        status: "offered",
      },
    },
  });
}
