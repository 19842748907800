import React, { PropsWithChildren } from "react";
import { Card } from "antd";
import { useTranslation } from "react-i18next";

interface Props {
  children: PropsWithChildren<{}>;
  title: string;
}

export const AuthCard: React.FC<Props> = ({ title, children }) => {
  const { t } = useTranslation();
  return (
    <Card
      bodyStyle={{ overflow: "auto", maxHeight: 500 }}
      style={{
        width: "40em",
        marginLeft: "1em",
        marginRight: "1em",
      }}
      title={t(title)}
    >
      {children}
    </Card>
  );
};
