import { EntityQueryType } from "../../migration/migration";

export const ADD_COMPANIE = "ADD_COMPANIE";
export const ADD_CONTACT_PERSON = "ADD_CONTACT_PERSON";
export const ADD_INBOUND_OFFER = "ADD_INBOUND_OFFER";
export const ADD_OUTBOUND_OFFER = "ADD_OUTBOUND_OFFER";
export const ADD_INBOUND_INQUIRY = "ADD_INBOUND_INQUIRY";
export const DELETE_COMPANY = "DELETE_COMPANY";
export const DELETE_CONTACT_PERSON = "DELETE_CONTACT_PERSON";
export const DELETE_INBOUND_INQUIRY = "DELETE_INBOUND_INQUIRY";
export const DELETE_INBOUND_OFFER = "DELETE_INBOUND_OFFER";
export const DELETE_OUTBOUND_OFFER = "DELETE_OUTBOUND_OFFER";
export const EDIT_ENTITY = "EDIT_ENTITY";
export const EDITING_FINISHED = "EDITING_FINISHED";
export const ADD_PRODUCTS = "ADD_PRODUCTS";
export const ADD_RPODUCTS_SELECT_FIELDS = "ADD_PRODUCTS_SELECT_FIELD";
export const FILTER_INBOUND_INQUIRIES = "FILTER_INBOUND_INQUIRIES";
export const FILTER_INBOUND_OFFERS = "FILTER_INBOUND_OFFERS";
export const FILTER_OUTBOUND_OFFERS = "FILTER_OUTBOUND_OFFERS";
export const FILTER_ALL_DATA = "FILTER_ALL_DATA";
export const FILTER_COMPANIES = "FILTER_COMPANIES";
export const FILTER_CONTACT_PERSONS = "FILTER_CONTACT_PERSONS";
export const CLEAR_ALL_FILTERS = "CLEAR_ALL_FILTERS";
export const SET_FORM_VALUES = "SET_FORM_VALUES";
export const ERROR_OCCURED = "ERROR_GETTING_DATA_FROM_AWS";

export interface ApiState {
  companies: Array<Company>;
  contactPersons: Array<ContactPerson>;
  outboundOffers: Array<OutboundOffer>;
  inboundOffers: Array<InboundOffer>;
  inboundInquiries: Array<InboundInquiry>;
  products: Array<Product>;
  productsSelectField: Array<Product>;
  edit: {
    currSelectedItem: TableEntity | undefined;
    setFormValues: boolean;
  };
  filter: {
    generalFilter: Array<TableEntity>;
    companies: Array<Company>;
    contactPersons: Array<ContactPerson>;
    outboundOffers: Array<OutboundOffer>;
    inboundOffers: Array<InboundOffer>;
    inboundInquiries: Array<InboundInquiry>;
  };
  error?: string;
}

export interface AddCompanie {
  type: typeof ADD_COMPANIE;
  payload: { arr: Array<any> | null; singleItem: any | null };
}

export interface AddContactPerson {
  type: typeof ADD_CONTACT_PERSON;
  payload: { arr: Array<any> | null; singleItem: any | null };
}

export interface AddInboundOffer {
  type: typeof ADD_INBOUND_OFFER;
  payload: { arr: Array<any> | null; singleItem: any | null };
}

export interface AddOutboundOffer {
  type: typeof ADD_OUTBOUND_OFFER;
  payload: { arr: Array<any> | null; singleItem: any | null };
}

export interface AddInboundInquiry {
  type: typeof ADD_INBOUND_INQUIRY;
  payload: { arr: Array<any> | null; singleItem: any | null };
}

export interface DeleteCompany {
  type: typeof DELETE_COMPANY;
  payload: { id: string };
}

export interface DeleteContactPerson {
  type: typeof DELETE_CONTACT_PERSON;
  payload: { id: string };
}

export interface DeleteInboundInquiry {
  type: typeof DELETE_INBOUND_INQUIRY;
  payload: { id: string };
}

export interface DeleteOutboundOffer {
  type: typeof DELETE_OUTBOUND_OFFER;
  payload: { id: string };
}

export interface DeleteInboundOffer {
  type: typeof DELETE_INBOUND_OFFER;
  payload: { id: string };
}

export interface EditEntity {
  type: typeof EDIT_ENTITY;
  payload: { entity: TableEntity };
}

export interface EditingFinished {
  type: typeof EDITING_FINISHED;
}

export interface AddProduct {
  type: typeof ADD_PRODUCTS;
  payload: { products: Array<Product> };
}

export interface AddProductsSelectFields {
  type: typeof ADD_RPODUCTS_SELECT_FIELDS;
  payload: { products: Array<Product> };
}

export interface FilterInboundInquiries {
  type: typeof FILTER_INBOUND_INQUIRIES;
  payload: { inboundInquiries: Array<InboundInquiry> };
}

export interface FilterOutboundOffers {
  type: typeof FILTER_OUTBOUND_OFFERS;
  payload: { outboundOffers: Array<OutboundOffer> };
}

export interface FilterInboundOffers {
  type: typeof FILTER_INBOUND_OFFERS;
  payload: { inboundOffers: Array<InboundOffer> };
}

export interface FilterCompanies {
  type: typeof FILTER_COMPANIES;
  payload: { companies: Array<Company> };
}

export interface FilterConctactPersons {
  type: typeof FILTER_CONTACT_PERSONS;
  payload: { contactPersons: Array<ContactPerson> };
}

export interface ClearAllFilters {
  type: typeof CLEAR_ALL_FILTERS;
}

export interface FilterAllData {
  type: typeof FILTER_ALL_DATA;
  payload: { filteredData: Array<TableEntity> };
}

export interface SetFormValues {
  type: typeof SET_FORM_VALUES;
  payload: { setFormValues: boolean };
}

export interface ErrorOccured {
  type: typeof ERROR_OCCURED;
  payload: { errorMassage: string };
}

export type ApiActionType =
  | AddCompanie
  | AddContactPerson
  | AddInboundInquiry
  | AddInboundOffer
  | AddOutboundOffer
  | DeleteCompany
  | DeleteContactPerson
  | DeleteOutboundOffer
  | DeleteInboundInquiry
  | DeleteInboundOffer
  | EditEntity
  | EditingFinished
  | AddProduct
  | AddProductsSelectFields
  | FilterInboundInquiries
  | FilterInboundOffers
  | FilterOutboundOffers
  | FilterConctactPersons
  | FilterCompanies
  | ClearAllFilters
  | FilterAllData
  | SetFormValues
  | ErrorOccured;

export interface Company {
  id: string;
  plz?: string;
  typeOfContact: string;
  name: string;
  street1?: string;
  street2?: string;
  zip: number;
  place: string;
  country: string;
  telephone?: string;
  fax?: string;
  mail?: string;
  web?: string;
  vatNr: string;
  paymentTerms?: string;
  logo?: string;
  comments?: string;
  createdAt: Date;
  type: EntityQueryType;
  updatedAt: Date;
}

export interface ContactPerson {
  id: string;
  company: {
    id: string;
    name: string;
  };
  name: string;
  surname: string;
  salutation?: string;
  title?: string;
  telephone?: string;
  mail?: string;
  comments?: string;
  type: EntityQueryType;
  createdAt: Date;
  updatedAt: Date;
}

export interface InboundInquiry {
  id: string;
  lysisMember: string;
  lysisEditor?: string;
  date?: Date;
  product: string;
  condition: string;
  inquiryComments?: string;
  leadOrigin?: string;
  status: string;
  company: {
    id: string;
    name: string;
  };
  contactPerson: {
    id: string;
    name: string;
    surname: string;
    mail: string;
  };
  createdAt: Date;
  type: EntityQueryType;
  updatedAt: Date;
}

export interface InboundOffer {
  id: string;
  lysisMember: string;
  date?: Date;
  product: string;
  condition: string;
  inquiryComments: string;
  currency: string;
  purchasePrice: number;
  shipping: number;
  totalPrice: number;
  warranty: string;
  company: {
    id: string;
    name: string;
  };
  type: EntityQueryType;
  createdAt: Date;
  updatedAt: Date;
}

export interface OutboundOffer {
  id: string;
  lysisMember: string;
  date?: Date;
  product: string;
  condition: string;
  warranty: string;
  inquiryComments?: string;
  purchasePrice: number;
  salesPrice: number;
  offerStatus?: string;
  supplier?: {
    id: string;
    name: string;
  };
  company: {
    id: string;
    name: string;
  };
  inboundInquiry?: {
    id: string;
    product: string;
    inquiryComments?: string;
  };
  margin?: number;
  type: EntityQueryType;
  createdAt: Date;
  updatedAt: Date;
}

export interface Product {
  title: string;
  subTitle: string;
  updatedAt: Date;
}

export type TableEntity =
  | Company
  | ContactPerson
  | InboundOffer
  | InboundInquiry
  | OutboundOffer
  | Product;
