import {
  ADD_COMPANIE,
  ADD_CONTACT_PERSON,
  ADD_INBOUND_INQUIRY,
  ADD_INBOUND_OFFER,
  ADD_OUTBOUND_OFFER,
  ADD_PRODUCTS,
  ApiActionType,
  ApiState,
  DELETE_COMPANY,
  DELETE_CONTACT_PERSON,
  DELETE_INBOUND_INQUIRY,
  DELETE_INBOUND_OFFER,
  DELETE_OUTBOUND_OFFER,
  EDITING_FINISHED,
  EDIT_ENTITY,
  ADD_RPODUCTS_SELECT_FIELDS,
  FILTER_INBOUND_OFFERS,
  FILTER_INBOUND_INQUIRIES,
  FILTER_OUTBOUND_OFFERS,
  CLEAR_ALL_FILTERS,
  FILTER_ALL_DATA,
  SET_FORM_VALUES,
  FILTER_COMPANIES,
  FILTER_CONTACT_PERSONS,
  ERROR_OCCURED,
} from "../types/api.types";

const INITIAL_STATE: ApiState = {
  companies: [],
  contactPersons: [],
  outboundOffers: [],
  inboundOffers: [],
  inboundInquiries: [],
  edit: {
    currSelectedItem: undefined,
    setFormValues: false,
  },
  products: [],
  productsSelectField: [],
  filter: {
    companies: [],
    contactPersons: [],
    generalFilter: [],
    outboundOffers: [],
    inboundOffers: [],
    inboundInquiries: [],
  },
};

export const apiReducer = (
  state = INITIAL_STATE,
  action: ApiActionType
): ApiState => {
  switch (action.type) {
    case ADD_COMPANIE:
      const companies = action.payload.arr
        ? action.payload.arr
        : [action.payload.singleItem, ...state.companies];
      return {
        ...state,
        companies: companies,
      };
    case ADD_CONTACT_PERSON:
      const contactPersons = action.payload.arr
        ? action.payload.arr
        : [action.payload.singleItem, ...state.contactPersons];
      return {
        ...state,
        contactPersons: contactPersons,
      };
    case ADD_INBOUND_INQUIRY:
      const inboundInquiries = action.payload.arr
        ? action.payload.arr
        : [action.payload.singleItem, ...state.inboundInquiries];
      return {
        ...state,
        inboundInquiries: inboundInquiries,
      };
    case ADD_INBOUND_OFFER:
      const inboundOffers = action.payload.arr
        ? action.payload.arr
        : [action.payload.singleItem, ...state.inboundOffers];
      return {
        ...state,
        inboundOffers: inboundOffers,
      };
    case ADD_OUTBOUND_OFFER:
      const outboundOffers = action.payload.arr
        ? action.payload.arr
        : [action.payload.singleItem, ...state.outboundOffers];
      return {
        ...state,
        outboundOffers: outboundOffers,
      };
    case DELETE_COMPANY:
      return {
        ...state,
        companies: state.companies.filter((c) => c.id !== action.payload.id),
      };
    case DELETE_INBOUND_OFFER:
      return {
        ...state,
        inboundOffers: state.inboundOffers.filter(
          (i) => i.id !== action.payload.id
        ),
        filter: {
          ...state.filter,
          inboundOffers: state.inboundOffers.filter(
            (i) => i.id !== action.payload.id
          ),
        },
      };
    case DELETE_CONTACT_PERSON:
      return {
        ...state,
        contactPersons: state.contactPersons.filter(
          (i) => i.id !== action.payload.id
        ),
      };
    case DELETE_INBOUND_INQUIRY:
      return {
        ...state,
        inboundInquiries: state.inboundInquiries.filter(
          (i) => i.id !== action.payload.id
        ),
        filter: {
          ...state.filter,
          inboundInquiries: state.inboundInquiries.filter(
            (i) => i.id !== action.payload.id
          ),
        },
      };
    case DELETE_OUTBOUND_OFFER:
      return {
        ...state,
        outboundOffers: state.outboundOffers.filter(
          (i) => i.id !== action.payload.id
        ),
        filter: {
          ...state.filter,
          outboundOffers: state.outboundOffers.filter(
            (i) => i.id !== action.payload.id
          ),
        },
      };
    case EDIT_ENTITY:
      return {
        ...state,
        edit: {
          ...state.edit,
          currSelectedItem: action.payload.entity,
        },
      };
    case EDITING_FINISHED:
      return {
        ...state,
        edit: {
          ...state.edit,
          currSelectedItem: undefined,
          setFormValues: false,
        },
      };
    case ADD_PRODUCTS:
      return {
        ...state,
        products: action.payload.products,
      };
    case ADD_RPODUCTS_SELECT_FIELDS:
      return {
        ...state,
        productsSelectField: action.payload.products,
      };
    case FILTER_INBOUND_OFFERS:
      return {
        ...state,
        filter: {
          ...state.filter,
          inboundOffers: action.payload.inboundOffers,
        },
      };
    case FILTER_INBOUND_INQUIRIES:
      return {
        ...state,
        filter: {
          ...state.filter,
          inboundInquiries: action.payload.inboundInquiries,
        },
      };
    case FILTER_OUTBOUND_OFFERS:
      return {
        ...state,
        filter: {
          ...state.filter,
          outboundOffers: action.payload.outboundOffers,
        },
      };
    case FILTER_ALL_DATA:
      return {
        ...state,
        filter: {
          ...state.filter,
          generalFilter: action.payload.filteredData,
        },
      };
    case FILTER_COMPANIES:
      return {
        ...state,
        filter: {
          ...state.filter,
          companies: action.payload.companies,
        },
      };
    case FILTER_CONTACT_PERSONS:
      return {
        ...state,
        filter: {
          ...state.filter,
          contactPersons: action.payload.contactPersons,
        },
      };
    case CLEAR_ALL_FILTERS:
      return {
        ...state,
        filter: {
          companies: [],
          contactPersons: [],
          generalFilter: [],
          outboundOffers: [],
          inboundInquiries: [],
          inboundOffers: [],
        },
      };
    case SET_FORM_VALUES:
      return {
        ...state,
        edit: {
          ...state.edit,
          setFormValues: action.payload.setFormValues,
        },
      };
    case ERROR_OCCURED:
      return {
        ...state,
        error: action.payload.errorMassage,
      };
    default:
      return state;
  }
};
