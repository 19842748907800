import { Product } from "../../store/types/api.types";
import { sanityClient } from "../../utils/sanityClient";
import groq from "groq";
import { EntityForm } from "../../pages/DashboardPage/DashboardPage";
import { TFunction } from "react-i18next";

const searchQuery = groq`*[_type == "product" &&
  (!defined($categoryFilter) || references(*[_type == "category" && title.en == $categoryFilter]._id) ||
   references(*[_type == "category" && references(*[_type == "category" && title.en == $categoryFilter]._id)]._id)) && 
  (!defined($manufacturerFilter) || manufacturer->title in $manufacturerFilter) && 
  (!defined($otherFilters) || filters[]->title.en match $otherFilters) && 
  ([defaultProductVariant.title.de,  defaultProductVariant.title.en, filters[]->title.de, filters[]->title.en, categories[]->title.de, categories[]->title.en, manufacturer->title] match $searchTerm) ]  
  | order(title) [$pStart...$pEnd] 
  { "title": defaultProductVariant.title, "subTitle": defaultProductVariant.subTitle, blurb, slug, "mainImage": defaultProductVariant.images[0] }`;

interface CompanyConnection {
  id: string;
  name: string;
}

interface InboundInquiryConnection {
  id: string;
  product: string;
}

/**
 *
 * @param searchTerm
 * @param pStart
 * @param pEnd
 * @returns
 */
export const searchProduct = (
  searchTerm: string,
  pStart = 0,
  pEnd = 10
): Promise<Array<Product>> => {
  return sanityClient
    .fetch(searchQuery, {
      searchTerm: searchTerm
        .replace("-", " ")
        .replace("(", " ")
        .replace(")", " ")
        .replace("/", " ")
        .split(" ")
        .map((w) => `*${w}*`),
      // pEnd - pStart = number of products that are returned
      pStart,
      pEnd,
      categoryFilter: null,
      manufacturerFilter: null,
      otherFilters: null,
    })
    .then((data) => {
      let products: Array<Product> = [];
      data.forEach((item: any) => {
        const product: Product = {
          title: item.title.en,
          subTitle: item.subTitle.en,
          updatedAt: new Date(),
        };
        products.push(product);
      });
      return products;
    })
    .catch((e) => {
      console.log(e);
      return Promise.reject();
    });
};

/**
 *
 * @returns
 */
export const getFirst100Products = (): Promise<Array<Product>> => {
  let pStart = 0;
  let pEnd = 500;
  return sanityClient
    .fetch(searchQuery, {
      searchTerm: " "
        .replace("-", " ")
        .replace("(", " ")
        .replace(")", " ")
        .replace("/", " ")
        .split(" ")
        .map((w) => `*${w}*`),
      // pEnd - pStart = number of products that are returned
      pStart,
      pEnd,
      categoryFilter: null,
      manufacturerFilter: null,
      otherFilters: null,
    })
    .then((data) => {
      let products: Array<Product> = [];
      data.forEach((item: any) => {
        const product: Product = {
          title: item.title.en,
          subTitle: item.subTitle.en,
          updatedAt: new Date(),
        };
        products.push(product);
      });
      return products;
    });
};

/**
 *
 * @param val
 * @param searchTerm
 * @returns
 */
export const searchValueInTableEntity = (
  val: number | Date | string | CompanyConnection | InboundInquiryConnection,
  searchTerm: string
) => {
  if (searchTerm === "") return true;
  if (!val) return false;
  if (typeof val === "string") {
    return val.toLowerCase().includes(searchTerm.toLowerCase());
  }
  if (typeof val === "number") {
    return val.toString() === searchTerm;
  }
  if (typeof val === "object") {
    if ("name" in val)
      return val.name.toLowerCase().includes(searchTerm.toLowerCase());
    if ("product" in val)
      return val.product.toLowerCase().includes(searchTerm.toLowerCase());
  }
  return new Date(val) === new Date(searchTerm);
};

/**
 *
 * @param entityType
 * @param t
 * @returns
 */
export const getValidPlaceholderForSearchBar = (
  entityType: EntityForm | undefined,
  t: TFunction
): string => {
  switch (entityType) {
    case EntityForm.INBOUND_INQUIRY:
      return t("Search") + " " + t("Inbound Inquiry");
    case EntityForm.OUTBOUND_OFFER:
      return t("Search") + " " + t("Outbound Offer");
    case EntityForm.INBOUND_OFFER:
      return t("Search") + " " + t("Inbound Offer");
    default:
      return t("Global Search");
  }
};
