import React, { useState } from "react";
import { ConfirmSignUpForm } from "../../components/ConfirmSignUpForm/ConfirmSignUpForm";
import { ForgotPasswordForm } from "../../components/ForgotPasswordForm/ForgotPasswordForm";
import { FullscreenCenteredContainer } from "../../components/FullscreenCenteredConatiner/FullscreenCenteredContainer";
import { SignInForm } from "../../components/SignInForm/SignInForm";
import { SignUpForm } from "../../components/SignUpForm/SignUpForm";

export enum FormState {
  SIGN_UP = "Sign Up",
  SIGN_IN = "Sign In",
  CONFIRM_SIGN_UP = "Confirm Sign Up",
  FORGOT_PASSWORD = "FORGOT_PASSWORD",
}

interface Props {
  initialForm: FormState;
}

export const AuthenticationPage: React.FC<Props> = ({ initialForm }) => {
  const [formState, setFormState] = useState<FormState>(initialForm);

  return (
    <FullscreenCenteredContainer>
      {formState === FormState.SIGN_IN && (
        <SignInForm setFormState={setFormState} />
      )}
      {formState === FormState.SIGN_UP && (
        <SignUpForm setFormState={setFormState} />
      )}
      {formState === FormState.CONFIRM_SIGN_UP && <ConfirmSignUpForm />}
      {formState === FormState.FORGOT_PASSWORD && (
        <ForgotPasswordForm setFormState={setFormState} />
      )}
    </FullscreenCenteredContainer>
  );
};
