import { API, Auth } from "aws-amplify";
import { isProduction } from "./config";

const adminQueryApiName = "AdminQueries";

enum AdminQueryEndpoints {
  listUsers = "/listUsers",
  listGroups = "/listGroups",
}

/**
 * List all users of the Admin cognito pool
 */
export async function listAdmins() {
  const params = {
    queryStringParameters: {
      groupname: "Admins",
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
  };
  const { NextToken, ...rest } = await API.get(
    adminQueryApiName,
    AdminQueryEndpoints.listUsers,
    params
  );
  if (isProduction()) {
    return convertToEmailArray(rest.Users).filter(
      (email) => !email.includes("expressflow")
    );
  }
  return convertToEmailArray(rest.Users);
}

/**
 *
 * @param amplifyRes
 */
function convertToEmailArray(amplifyRes: any): string[] {
  return amplifyRes.map(
    (res: any) => res.Attributes.find((att: any) => att.Name === "email").Value
  );
}
