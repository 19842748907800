import React, { useState } from "react";
import { InboundInquiry, TableEntity } from "../../store/types/api.types";
import { Button, Tooltip } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { API } from "aws-amplify";
import * as mutations from "../../graphql/mutations";
import { User } from "../../store/types/user.types";
import { RootStateOrAny, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

interface DashboardAvatarProps {
  item: TableEntity;
  editorCanBeChanged?: boolean;
}

export function DashboardAvatar({
  item,
  editorCanBeChanged,
}: DashboardAvatarProps) {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const user: User = useSelector(
    (state: RootStateOrAny) => state.userReducer.user
  );

  const getIcon = () => {
    if (
      (item as InboundInquiry).lysisEditor &&
      (item as InboundInquiry).lysisEditor?.length
    ) {
      const arr = (item as InboundInquiry).lysisEditor?.split(".");
      return <p>{`${arr![0][0].toUpperCase()}${arr![1][0].toUpperCase()}`}</p>;
    }
    return <PlusOutlined />;
  };

  const setLysisEditor = async (item: TableEntity) => {
    setIsLoading(true);
    await API.graphql({
      query: mutations.updateInboundInquiry,
      variables: {
        input: {
          id: (item as any).id,
          lysisEditor:
            !(item as InboundInquiry).lysisEditor ||
            !(item as InboundInquiry).lysisEditor?.length
              ? user.attributes.email
              : null,
        },
      },
    });
    setIsLoading(() => false);
  };

  return (
    <Tooltip
      title={t(
        (item as any).lysisEditor && !editorCanBeChanged
          ? ""
          : "Add me as editor"
      )}
    >
      <Button
        onClick={async () =>
          (item as any).lysisEditor && !editorCanBeChanged
            ? null
            : setLysisEditor(item)
        }
        type={"primary"}
        shape={"circle"}
        icon={getIcon()}
        loading={isLoading}
      />
    </Tooltip>
  );
}
