import React, { useEffect, useLayoutEffect, useState } from "react";
import * as queries from "../../graphql/queries";
import { InboundInquiry, OutboundOffer } from "../../store/types/api.types";
import { EntityForm } from "../DashboardPage/DashboardPage";
import { InboundInquiryRadioButtonValue } from "./DomainPage";
import {
  addListeners,
  ApiFetchResponse,
  getAllDataFromEntity,
  queryLimit,
  searchAllDataFromApi,
  searchDataFromApi,
} from "../../components/Feed/model";
import { Entity } from "../../components/CreateEntityModal/CreateEntityModal";
import { User } from "../../store/types/user.types";
import { RootStateOrAny, useSelector } from "react-redux";
import { Unsubscribe } from "redux";
import { OutboundOfferStatus } from "../../components/OutboundOfferStatusButton/OutboundOfferStatusButton";

/**
 *
 * @param setIsLoading
 * @param showInboundInquiriesOfEditor
 * @param type
 * @param inboundInquiries
 * @param searchTerm
 * @returns
 */
export function useInboundInquiryFiltering(
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  showInboundInquiriesOfEditor?: boolean,
  type?: InboundInquiryRadioButtonValue,
  inboundInquiries?: InboundInquiry[],
  searchTerm?: string,
  entity?: EntityForm
) {
  const [subscriptions, setSubscriptions] = useState<
    | {
        unsubscribe: Unsubscribe;
      }[]
    | undefined
  >();
  const user: User = useSelector(
    (state: RootStateOrAny) => state.userReducer.user
  );
  const [inboundInquiriesByEditor, setInboundInquiriesByEditor] = useState<
    InboundInquiry[]
  >([]);
  const [filteredInboundInquiries, setFilteredInboundInquiries] = useState<
    InboundInquiry[] | undefined
  >();

  const filterInboundInquiries = (inboundInquiries?: InboundInquiry[]) => {
    switch (type) {
      case InboundInquiryRadioButtonValue.all:
        setFilteredInboundInquiries(inboundInquiries);
        break;
      case InboundInquiryRadioButtonValue.notArchieved:
        setFilteredInboundInquiries(
          inboundInquiries?.filter(
            (i) => i.status !== "archived" && i.status !== "archieved"
          )
        );
        break;
      case InboundInquiryRadioButtonValue.inProgress:
        setFilteredInboundInquiries(
          inboundInquiries?.filter(
            (i) => i.status === "in progress" || i.status === "In progress"
          )
        );
        break;
      case InboundInquiryRadioButtonValue.open:
        setFilteredInboundInquiries(
          inboundInquiries?.filter((i) => i.status === "open")
        );
    }
  };

  const searchInboundInquiries = (inboundInquiries: InboundInquiry[]) => {
    return searchTerm && searchTerm.length
      ? inboundInquiries.filter(
          (iq) =>
            iq.product.toLowerCase().includes(searchTerm?.toLowerCase()) ||
            iq.company.name.toLowerCase().includes(searchTerm?.toLowerCase())
        )
      : inboundInquiries;
  };

  const getDataAndFilter = async () => {
    setIsLoading(true);
    const inboundInquiries: InboundInquiry[] = await getAllDataFromEntity({
      query: queries.inboundInquiriesLysisEditor,
      type: "inboundInquiry",
      sortDirection: "DESC",
      queryName: "inboundInquiriesLysisEditor",
      variables: {
        lysisEditor: user.attributes.email,
      },
      entity: Entity.INBOUND_INQUIRY,
      limit: 300,
    });
    setInboundInquiriesByEditor(inboundInquiries);
    filterInboundInquiries(searchInboundInquiries(inboundInquiries));
    setIsLoading(() => false);
  };

  useEffect(() => {
    if (entity === EntityForm.INBOUND_INQUIRY && showInboundInquiriesOfEditor) {
      setSubscriptions(
        addListeners(
          EntityForm.INBOUND_INQUIRY,
          setFilteredInboundInquiries,
          undefined,
          true
        )?.map((l) => l!)
      );
    } else {
      subscriptions?.forEach((s) => s?.unsubscribe());
    }
  }, [showInboundInquiriesOfEditor]);

  useEffect(() => {
    if (showInboundInquiriesOfEditor) {
      getDataAndFilter();
    } else {
      filterInboundInquiries(inboundInquiries);
    }
  }, [type, inboundInquiries]);

  useEffect(() => {
    if (showInboundInquiriesOfEditor) {
      setFilteredInboundInquiries(
        searchInboundInquiries(inboundInquiriesByEditor)
      );
    }
  }, [searchTerm]);

  useEffect(() => {
    if (showInboundInquiriesOfEditor) {
      getDataAndFilter();
    } else {
      filterInboundInquiries(inboundInquiries);
    }
  }, [showInboundInquiriesOfEditor]);

  return {
    filteredInboundInquiries: filteredInboundInquiries || [],
    setFilteredInboundInquiries,
  };
}

export function useOutboundOfferFilter(
  outboundOffers: OutboundOffer[],
  status?: OutboundOfferStatus,
  currentSelectedEmail?: string
) {
  const [filteredOutboundOffers, setFilteredOutboundOffers] =
    useState(outboundOffers);

  const filterSingleOutboundOffer = (
    outboundOffer: OutboundOffer,
    status?: OutboundOfferStatus,
    email?: string
  ) => {
    switch (status) {
      case OutboundOfferStatus.All:
        return !(email?.length && outboundOffer.lysisMember != email);
      case OutboundOfferStatus.Sold:
      case OutboundOfferStatus.NotSold:
        return !(
          (email?.length && outboundOffer.lysisMember !== email) ||
          (status && status != outboundOffer.offerStatus)
        );
      case OutboundOfferStatus.Open:
        return !(
          (email?.length && outboundOffer.lysisMember !== email) ||
          (status &&
            outboundOffer.offerStatus &&
            status != outboundOffer.offerStatus)
        );
    }
  };

  const filterOutboundOffers = (outboundOffers: OutboundOffer[]) => {
    setFilteredOutboundOffers(
      outboundOffers.filter((outboundOffer) =>
        filterSingleOutboundOffer(outboundOffer, status, currentSelectedEmail)
      )
    );
  };

  useEffect(() => {
    filterOutboundOffers(outboundOffers);
  }, [status, outboundOffers, currentSelectedEmail]);

  return {
    filteredOutboundOffers,
  };
}

/**
 *
 * @param type
 * @param entities
 * @param searchTerm
 * @param showInboundInquiriesOfEditor
 */
export function usePaginatedEntitySearch(
  entities: any[],
  searchTerm?: string,
  type?: EntityForm,
  showInboundInquiriesOfEditor?: boolean
) {
  const [entitiesFilteredBySearchTerm, setEntitiesFilteredBySearchTerm] =
    useState<any[]>(entities);
  const [isLoading, setIsLoading] = useState(false);
  const [elementCounter, setElementCounter] = useState(queryLimit);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [nextToken, setNextToken] = useState<string | undefined>();
  const [fetchedAllData, setFetchedAllData] = useState(false);
  const [subscriptions, setSubscriptions] = useState<
    | {
        unsubscribe: Unsubscribe;
      }[]
    | undefined
  >();

  const searchData = async (newSearchTerm?: boolean) => {
    setIsLoading(true);
    let temp: ApiFetchResponse = { entities: [] };
    switch (type) {
      case EntityForm.OUTBOUND_OFFER:
        temp = await searchDataFromApi({
          query: queries.searchOutboundOffers,
          nextToken: newSearchTerm ? undefined : nextToken,
          optionalSearchKeys: [
            { companyName: { matchPhrasePrefix: searchTerm! } },
          ],
          searchKey: "product",
          searchTerm: searchTerm!,
          entity: type!,
        });
        break;
      case EntityForm.INBOUND_OFFER:
        temp = await searchDataFromApi({
          query: queries.searchInboundOffers,
          nextToken: newSearchTerm ? undefined : nextToken,
          searchKey: "product",
          optionalSearchKeys: [
            { companyName: { matchPhrasePrefix: searchTerm! } },
          ],
          searchTerm: searchTerm!,
          entity: type!,
        });
        break;
      case EntityForm.INBOUND_INQUIRY:
        temp = await searchDataFromApi({
          query: queries.searchInboundInquirys,
          nextToken: newSearchTerm ? undefined : nextToken,
          searchKey: "product",
          optionalSearchKeys: [
            { companyName: { matchPhrasePrefix: searchTerm! } },
          ],
          searchTerm: searchTerm!,
          entity: type!,
        });
        break;
      case EntityForm.CONTACT_PERSON:
        temp = await searchDataFromApi({
          query: queries.searchContactPersons,
          nextToken: newSearchTerm ? undefined : nextToken,
          searchKey: "name",
          optionalSearchKeys: [
            { companyName: { matchPhrasePrefix: searchTerm! } },
            { surname: { matchPhrasePrefix: searchTerm! } },
          ],
          searchTerm: searchTerm!,
          entity: type!,
        });
        break;
      case EntityForm.COMPANY:
        temp = await searchDataFromApi({
          query: queries.searchCompanys,
          nextToken: newSearchTerm ? undefined : nextToken,
          searchKey: "name",
          searchTerm: searchTerm!,
          entity: type!,
        });
        break;
      // Default is used for Domain Page
      default:
        setEntitiesFilteredBySearchTerm(
          entities.filter(
            (e) =>
              e.product.toLowerCase().includes(searchTerm!.toLowerCase()) ||
              e.company.name.toLowerCase().includes(searchTerm!.toLowerCase())
          )
        );
        setFetchedAllData(true);
        return setIsLoading(() => false);
    }
    setEntitiesFilteredBySearchTerm((prev) => {
      if (elementCounter === 100 || newSearchTerm) return temp.entities;
      return [...prev, ...temp.entities];
    });
    if (
      entitiesFilteredBySearchTerm.length + temp.entities.length ===
        temp.totalEntities ||
      !temp.nextToken
    )
      setFetchedAllData(true);
    setNextToken(temp.nextToken);
    setIsLoading(() => false);
  };

  useLayoutEffect(() => {
    if (isSearchActive) {
      setSubscriptions(
        addListeners(
          type,
          setEntitiesFilteredBySearchTerm,
          undefined,
          true
        )?.map((l) => l!)
      );
    } else {
      subscriptions?.forEach((s) => s?.unsubscribe());
    }
    setNextToken(undefined);
  }, [type, isSearchActive]);

  useEffect(() => {
    if (!showInboundInquiriesOfEditor && searchTerm && searchTerm.length > 1) {
      setIsSearchActive(true);
      searchData();
    }
  }, [elementCounter]);

  useEffect(() => {
    if (!entitiesFilteredBySearchTerm.length || !searchTerm?.length) {
      setEntitiesFilteredBySearchTerm(entities);
      setFetchedAllData(false);
      setIsSearchActive(false);
      setElementCounter(100);
    }
    if (searchTerm && searchTerm.length > 1) {
      if (showInboundInquiriesOfEditor) return;
      setIsSearchActive(true);
      setNextToken(undefined);
      searchData(true);
    }
  }, [searchTerm]);

  return {
    entitiesFilteredBySearchTerm: isSearchActive
      ? entitiesFilteredBySearchTerm
      : entities,
    setEntitiesFilteredBySearchTerm,
    isLoading,
    isSearchActive,
    fetchedAllData,
    elementCounter,
    setElementCounter,
  };
}

/**
 *
 * @param type
 * @param entities
 * @param searchTerm
 */
export function useEntitySearch(
  entities: any[],
  searchTerm?: string,
  type?: EntityForm
) {
  const [entitiesFilteredBySearchTerm, setEntitiesFilteredBySearchTerm] =
    useState<any[]>(entities);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearchActive, setIsSearchActive] = useState(false);

  const searchData = async () => {
    setIsLoading(true);
    switch (type) {
      case EntityForm.OUTBOUND_OFFER:
        const outboundOffers = await searchAllDataFromApi({
          query: queries.searchOutboundOffers,
          optionalSearchKeys: [
            { companyName: { matchPhrasePrefix: searchTerm! } },
          ],
          searchKey: "product",
          searchTerm: searchTerm!,
          entity: type!,
        });
        setEntitiesFilteredBySearchTerm(outboundOffers);
        break;
      case EntityForm.INBOUND_OFFER:
        const inboundOffers = await searchAllDataFromApi({
          query: queries.searchInboundOffers,
          searchKey: "product",
          optionalSearchKeys: [
            { companyName: { matchPhrasePrefix: searchTerm! } },
          ],
          searchTerm: searchTerm!,
          entity: type!,
        });
        setEntitiesFilteredBySearchTerm(inboundOffers);
        break;
      case EntityForm.INBOUND_INQUIRY:
        const inboundInquiry = await searchAllDataFromApi({
          query: queries.searchInboundInquirys,
          searchKey: "product",
          optionalSearchKeys: [
            { companyName: { matchPhrasePrefix: searchTerm! } },
          ],
          searchTerm: searchTerm!,
          entity: type!,
        });
        setEntitiesFilteredBySearchTerm(inboundInquiry);
        break;
      case EntityForm.CONTACT_PERSON:
        const contactPersons = await searchAllDataFromApi({
          query: queries.searchContactPersons,
          searchKey: "name",
          optionalSearchKeys: [
            { companyName: { matchPhrasePrefix: searchTerm! } },
            { surname: { matchPhrasePrefix: searchTerm! } },
          ],
          searchTerm: searchTerm!,
          entity: type!,
        });
        setEntitiesFilteredBySearchTerm(contactPersons);
        break;
      case EntityForm.COMPANY:
        const companies = await searchAllDataFromApi({
          query: queries.searchCompanys,
          searchKey: "name",
          searchTerm: searchTerm!,
          entity: type!,
        });
        setEntitiesFilteredBySearchTerm(companies);
        break;
      // Default is used for Domain Page
      default:
        setEntitiesFilteredBySearchTerm(
          entities.filter(
            (e) =>
              e.product.toLowerCase().includes(searchTerm!.toLowerCase()) ||
              e.company.name.toLowerCase().includes(searchTerm!.toLowerCase())
          )
        );
    }
    setIsLoading(() => false);
  };

  useEffect(() => {
    if (!entitiesFilteredBySearchTerm.length || !searchTerm?.length) {
      setEntitiesFilteredBySearchTerm(entities);
      setIsSearchActive(false);
    }
    if (searchTerm && searchTerm.length > 1) {
      setIsSearchActive(true);
      searchData();
    }
  }, [searchTerm, entities]);

  return {
    entitiesFilteredBySearchTerm: isSearchActive
      ? entitiesFilteredBySearchTerm
      : entities,
    setEntitiesFilteredBySearchTerm,
    isLoading,
    isSearchActive,
  };
}
