import React, { useEffect, useState } from "react";
import { Row, Col, Button, Tooltip } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";
import CompanyForm from "../CompanyForm/CompanyForm";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import {
  editingFinished,
  setFormValues,
} from "../../store/actions/api.actions";
import { useTranslation } from "react-i18next";
import InboundInquiryForm from "../InboundInquiryForm/InboundInquiryForm";
import ConatctPersonForm from "../ContactPersonForm/ContactPersonForm";
import InboundOfferForm from "../InboundOfferForm/InboundOfferForm";
import OutboundOfferForm from "../OutboundOfferForm/OutboundOfferForm";
import { CreateEntityModal } from "../CreateEntityModal/CreateEntityModal";

interface Params {
  form: string;
}

export const Editor = ({
  setIsEditorOpen,
  form,
}: {
  setIsEditorOpen: React.Dispatch<React.SetStateAction<boolean>>;
  form:
    | "Contact Person"
    | "Company"
    | "Inbound Inquiry"
    | "Inbound Offer"
    | "Outbound Offer"
    | "Product"
    | "";
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const selectedEntity = useSelector(
    (state: RootStateOrAny) => state.apiReducer.edit.currSelectedItem
  );
  const [reset, setReset] = useState(false);
  const [outboundOfferModalOpen, setOutboundOfferModalOpen] = useState(false);

  const goBack = () => {
    unselectEntity();
    setIsEditorOpen(false);
  };

  const unselectEntity = () => {
    dispatch(editingFinished());
  };

  useEffect(() => {
    return () => unselectEntity();
  }, []);

  const resetForm = () => {
    //Stores the old data to redux, the form-component
    //rerender and contains the old data
    setReset(true);
    dispatch(setFormValues(true));
  };

  return (
    <div>
      <Row align="middle">
        <Col span={4}>
          <LeftOutlined onClick={goBack} style={{ fontSize: "1.5em" }} />
        </Col>
        <Col span={20}>
          <h2>{"Editor"}</h2>
        </Col>
      </Row>
      <Row
        gutter={[16, 16]}
        justify="center"
        align="middle"
        style={{ marginTop: 20, marginBottom: 20 }}
      >
        <Col xs={0} sm={12}>
          {" "}
        </Col>
        <Col xs={12} sm={6}>
          <Button
            onClick={resetForm}
            type="ghost"
            size="large"
            style={{ width: "100%" }}
          >
            {t("Discard")}
          </Button>
        </Col>
        <Col xs={12} sm={6}>
          <Button
            form="formId"
            htmlType="submit"
            type="primary"
            size="large"
            style={{ width: "100%" }}
          >
            {t("Save")}
          </Button>
        </Col>
      </Row>
      <Row>
        {form === "Company" && (
          <CompanyForm
            closeModal={() => setIsEditorOpen(false)}
            type="update"
            formId="formId"
            resetAutoCompleteFields={reset}
          />
        )}
        {form === "Contact Person" && (
          <ConatctPersonForm
            closeModal={() => setIsEditorOpen(false)}
            type="update"
            formId="formId"
            resetAutoCompleteFields={reset}
          />
        )}
        {form === "Inbound Inquiry" && (
          <>
            <InboundInquiryForm
              closeModal={() => setIsEditorOpen(false)}
              type="update"
              formId="formId"
              resetAutoCompleteFields={reset}
            />
            <Col sm={24} style={{ textAlign: "center" }}>
              <Tooltip
                title={t("Create Outbound Offer for this Inbound Inquiry")}
              >
                <Button
                  onClick={() => setOutboundOfferModalOpen(true)}
                  type="primary"
                >
                  {t("Create Outbound Offer")}
                </Button>
              </Tooltip>
            </Col>
          </>
        )}
        {form === "Inbound Offer" && (
          <InboundOfferForm
            closeModal={() => setIsEditorOpen(false)}
            type="update"
            formId="formId"
            resetAutoCompleteFields={reset}
          />
        )}
        {form === "Outbound Offer" && (
          <OutboundOfferForm
            closeModal={() => setIsEditorOpen(false)}
            type="update"
            formId="formId"
            resetAutoCompleteFields={reset}
          />
        )}
      </Row>
      {form == "Inbound Inquiry" && (
        <CreateEntityModal
          visible={outboundOfferModalOpen}
          title="New Outbound Offer"
          formId="outbound-offer-form"
          onCancel={() => setOutboundOfferModalOpen(false)}
        >
          <OutboundOfferForm
            type="add"
            formId="outbound-offer-form"
            resetAutoCompleteFields={reset}
            closeModal={() => setOutboundOfferModalOpen(false)}
            companyAutoComplete={{
              err: false,
              val: selectedEntity?.company?.name,
              id: selectedEntity?.company?.id,
            }}
            inboundInquiryAutoComplete={{
              err: false,
              val: `${selectedEntity?.product || "-"} / ${
                selectedEntity?.date || "-"
              } / ${selectedEntity?.company?.name || "-"}`,
              id: selectedEntity?.id,
            }}
          />
        </CreateEntityModal>
      )}
    </div>
  );
};
