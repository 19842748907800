import React, { useEffect, useState } from "react";
import { AutoComplete } from "antd";
import { useTranslation } from "react-i18next";

interface AutoCompleteFormFieldProps {
  options: Array<{ value: string; id: string }>;
  value: AutoCompleteValue;
  setValue:
    | React.Dispatch<React.SetStateAction<AutoCompleteValue>>
    | ((value: AutoCompleteValue) => void);
  formFieldName: string;
  reset?: boolean;
  disabled?: boolean;
  setFormValue: (value: any) => void;
  filterContactPerson?: () => void;
}

export interface AutoCompleteValue {
  val: string;
  id: string;
  err: boolean;
}

export const AutoCompleteFormField: React.FC<AutoCompleteFormFieldProps> = ({
  options,
  value,
  setValue,
  formFieldName,
  setFormValue,
  filterContactPerson,
  reset,
  disabled,
}) => {
  const [inputValue, setInputValue] = useState(value?.val);
  const { t } = useTranslation();

  useEffect(() => {
    setInputValue(value?.val);
  }, [value?.val, reset]);

  const onChange = (data: string) => {
    setInputValue(data);
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter" || event.key === "Tab") {
      const option = options.find((o) =>
        o.value.toLowerCase().includes(inputValue.toLowerCase())
      );
      if (option) {
        setInputValue(option.value);
        setFormValue({
          [formFieldName]: option.id,
        });
        setValue({
          val: option.value,
          id: option.id,
          err: false,
        });
        filterContactPerson?.();
      }
    }
  };

  const onBlur = (e: React.FocusEvent<HTMLElement>) => {
    const option = options.find((o) => o.value === t(inputValue));
    if (option) {
      setValue({
        val: option.value,
        err: false,
        id: option.id,
      });
      setFormValue({
        [formFieldName]: option.id,
      });
      filterContactPerson?.();
    } else {
      setValue({
        ...value,
        err: true,
      });
      setFormValue({
        [formFieldName]: "",
      });
      filterContactPerson?.();
    }
  };

  return (
    <AutoComplete
      disabled={disabled}
      value={t(inputValue)}
      options={options}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      style={{ width: "100%" }}
      onChange={onChange}
      filterOption={(input, option) =>
        option!.value.toUpperCase().indexOf(input.toUpperCase()) !== -1
      }
    />
  );
};
