import React, { PropsWithChildren } from "react";

interface Props {
  children: PropsWithChildren<{}>;
}

export const FullscreenCenteredContainer: React.FC<Props> = ({ children }) => {
  return (
    <div
      style={{
        height: "100vh",
        overflow: "auto",
        backgroundColor: "white",
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {children}
    </div>
  );
};
