import { API } from "aws-amplify";
import { CreateEntityVariables, UpdateEntityVariables } from "./type.graphql";
import { GraphQLResult } from "@aws-amplify/api";
import * as queries from "../graphql/queries";
import * as customQueries from "../graphql/customQueries";
import { Company, InboundOffer } from "../store/types/api.types";

/**
 *
 * @param data
 */
export async function updateEntity(data: {
  mutation: any;
  variables: UpdateEntityVariables;
}) {
  const { mutation, variables } = data;
  await API.graphql({
    query: mutation,
    variables: {
      input: variables,
    },
  });
}

/**
 *
 * @param data
 */
export async function createEntity(data: {
  mutation: any;
  variables: CreateEntityVariables;
}) {
  const { mutation, variables } = data;
  await API.graphql({
    query: mutation,
    variables: {
      input: variables,
    },
  });
}

/**
 *
 * @param data
 */
export async function getSingleDataEntryFromApi(data: {
  query: any;
  id: string;
}) {
  return API.graphql({
    query: data.query,
    variables: {
      id: data.id,
    },
  }) as Promise<GraphQLResult<any>>;
}

/**
 *
 * @param id
 */
export async function getCompany(id: string): Promise<Company | undefined> {
  const graphQlResponse = await getSingleDataEntryFromApi({
    query: queries.getCompany,
    id,
  });
  return graphQlResponse.data.getCompany;
}

export async function getInboundOffersByProduct(
  product: string,
  limit?: number
): Promise<InboundOffer[]> {
  const response = (await API.graphql({
    query: customQueries.listInboundOffersByProduct,
    variables: {
      filter: {
        product: {
          eq: product,
        },
      },
      limit: 10000,
      sortDirection: "DESC",
    },
  })) as GraphQLResult<any>;
  if (response.errors) {
    console.error(response.errors);
    return [];
  }
  return response.data.listInboundOffers.items;
}
