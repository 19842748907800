import React from "react";
import { TFunction, useTranslation } from "react-i18next";
import {
  AutoCompleteFormField,
  AutoCompleteValue,
} from "./AutoCompleteFormField";

interface Props {
  selectedWarranty: AutoCompleteValue;
  setSelectedWarranty: React.Dispatch<React.SetStateAction<AutoCompleteValue>>;
  reset?: boolean;
  setFormValue: (value: any) => void;
}

export const getOptions = (t: TFunction) => {
  const options = [
    { value: "30 " + t("Days"), id: "30d" },
    { value: "60 " + t("Days"), id: "60d" },
    { value: "90" + t("Days"), id: "90d" },
    { value: "6 " + t("Months"), id: "6m" },
    { value: "12 " + t("Months"), id: "12m" },
    { value: "2 " + t("Years"), id: "2y" },
    { value: "3 " + t("Years"), id: "3y" },
    { value: "4 " + t("Years"), id: "4y" },
    { value: "5 " + t("Years"), id: "5y" },
  ];
  return options;
};

export const getWarranty = (t: TFunction, id?: string) => {
  const options = getOptions(t);
  return id ? options.find((o) => o.id === id)?.value || "" : "";
};

export const AutoCompleteWarrantyFormField: React.FC<Props> = ({
  selectedWarranty,
  setSelectedWarranty,
  reset,
  setFormValue,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <AutoCompleteFormField
        reset={reset}
        formFieldName="warranty"
        setFormValue={setFormValue}
        options={getOptions(t)}
        value={selectedWarranty}
        setValue={setSelectedWarranty}
      />
    </>
  );
};
