import React, { useState } from "react";
import { Form, Input, Button, Grid } from "antd";
import { useDispatch } from "react-redux";
import { signUpUser } from "../../store/actions/user.actions";
import { User, UserInformation } from "../../store/types/user.types";
import { useTranslation } from "react-i18next";
import { signUp } from "./model";
import { FormState } from "../../pages/AuthenticationPage/AuthenticationPage";
import { AuthCard } from "../AuthCard/AuthCard";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

interface Props {
  setFormState: React.Dispatch<React.SetStateAction<FormState>>;
}

const { useBreakpoint } = Grid;

export const SignUpForm: React.FC<Props> = ({ setFormState }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [signUpError, setSignUpError] = useState(false);
  const { t } = useTranslation();
  const screens = useBreakpoint();

  const submitForm = async (userInformation: UserInformation) => {
    //Just for now
    userInformation.photoUrl = "";

    const check = await signUp(userInformation);
    if (check) {
      const user: User = {
        attributes: {
          email: userInformation.email,
          family_name: userInformation.lastName,
          name: userInformation.firstName,
          picture: userInformation.photoUrl,
        },
      };
      dispatch(signUpUser(user));
      setFormState(FormState.CONFIRM_SIGN_UP);
    } else {
      form.resetFields();
      setSignUpError(true);
    }
  };

  const handleOnClick = () => {
    setFormState(FormState.SIGN_IN);
  };

  return (
    <AuthCard title={FormState.SIGN_UP}>
      <Form onFinish={submitForm} {...layout} form={form}>
        <Form.Item
          name="email"
          label="Email"
          rules={[
            {
              required: true,
              message: t("Please enter an email"),
            },
          ]}
        >
          <Input type="email" />
        </Form.Item>
        <Form.Item
          name="password"
          label={t("Password")}
          rules={[
            {
              required: true,
              message: t("Please enter a password"),
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          name="confirm"
          label={t("Confirm Password")}
          dependencies={["password"]}
          rules={[
            {
              required: true,
              message: t("Please confirm your password"),
            },
            ({ getFieldValue }) => ({
              validator(_, confirm_password) {
                if (getFieldValue("password") === confirm_password) {
                  return Promise.resolve();
                } else
                  return Promise.reject(
                    t("The two passwords that you entered do not match!")
                  );
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item name="firstName" label={t("First Name")}>
          <Input />
        </Form.Item>
        <Form.Item name="lastName" label={t("Last Name")}>
          <Input />
        </Form.Item>
        <Form.Item hidden={!signUpError} wrapperCol={{ offset: 8 }}>
          <p style={{ color: "#ff4d4f", margin: 0 }}>
            {"*" + t("Cant SignUp please try again")}
          </p>
        </Form.Item>
        <Form.Item wrapperCol={{ offset: screens.xs ? 0 : 8 }}>
          <Button style={{ width: "100%" }} htmlType="submit" type="primary">
            {t("Sign Up")}
          </Button>
        </Form.Item>
        <Form.Item
          wrapperCol={{ offset: screens.xs ? 0 : 8 }}
          style={{ textAlign: "center" }}
        >
          <Button
            type="link"
            style={{ color: "#1890FF" }}
            onClick={handleOnClick}
          >
            {t("Log in")}
          </Button>
        </Form.Item>
      </Form>
    </AuthCard>
  );
};
