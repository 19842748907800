import { useEffect, useLayoutEffect, useState } from "react";
import { EntityForm } from "../../pages/DashboardPage/DashboardPage";
import {
  addListeners,
  ApiFetchResponse,
  getAllDataFromEntity,
  getAllOpenInboundInquiries,
  getDataFromApi,
  listenToAddEvent,
  listenToDeleteEventEntity,
} from "./model";
import * as queries from "../../graphql/queries";
import { Entity } from "../CreateEntityModal/CreateEntityModal";
import { Unsubscribe } from "redux";
import { Company, InboundInquiry } from "../../store/types/api.types";
import _ from "lodash";

/**
 *
 * @param elementCounter
 * @param type
 * @param setSearchTerm
 * @returns
 */
export function useEntities(
  elementCounter: number,
  type?: EntityForm,
  setSearchTerm?: React.Dispatch<React.SetStateAction<string>>
) {
  const [currentType, setCurrentType] = useState(type);
  const [finishedLoading, setFinishedLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [nextToken, setNextToken] = useState<string | undefined>(undefined);
  const [entities, setEntities] = useState<any[]>([]);
  const [subscriptions, setSubscriptions] = useState<
    | {
        unsubscribe: Unsubscribe;
      }[]
    | undefined
  >();

  const getData = async (reset?: boolean) => {
    if (finishedLoading) return;
    setIsLoading(true);
    let temp: ApiFetchResponse = { entities: [] };
    switch (type) {
      case EntityForm.COMPANY:
        temp = await getDataFromApi({
          query: queries.companyByDate,
          nextToken: reset ? undefined : nextToken,
          type: "company",
          sortDirection: "DESC",
          entity: Entity.COMPANY,
        });
        break;
      case EntityForm.CONTACT_PERSON:
        temp = await getDataFromApi({
          query: queries.contactPersonsByCreatedAt,
          nextToken: reset ? undefined : nextToken,
          type: "contactPerson",
          sortDirection: "DESC",
          entity: Entity.CONTACT_PERSON,
        });
        break;
      case EntityForm.INBOUND_INQUIRY:
        temp = await getDataFromApi({
          query: queries.inboundInquiriesByDate,
          nextToken: reset ? undefined : nextToken,
          type: "inboundInquiry",
          sortDirection: "DESC",
          entity: Entity.INBOUND_INQUIRY,
        });
        break;
      case EntityForm.INBOUND_OFFER:
        temp = await getDataFromApi({
          query: queries.inboundOffersByDate,
          nextToken: reset ? undefined : nextToken,
          type: "inboundOffer",
          sortDirection: "DESC",
          entity: Entity.INBOUND_OFFER,
        });
        break;
      case EntityForm.OUTBOUND_OFFER:
        temp = await getDataFromApi({
          query: queries.outboundOffersByDate,
          nextToken: reset ? undefined : nextToken,
          type: "outboundOffer",
          sortDirection: "DESC",
          entity: Entity.OUTBOUND_OFFER,
        });
        break;
      default:
        temp.entities = await getAllOpenInboundInquiries();
        temp.nextToken = undefined;
    }
    setFinishedLoading(!temp.nextToken);
    setIsLoading(false);
    setNextToken(temp.nextToken);
    setEntities((prev) =>
      reset
        ? temp.entities
        : _.uniqBy([...prev, ...temp.entities], (item) => item.id)
    );
  };

  const unsubscribeFromEvents = () => {
    subscriptions?.forEach((s) => s?.unsubscribe());
  };

  useLayoutEffect(() => {
    if (type !== currentType) {
      getData(true);
      setCurrentType(type);
      setEntities([]);
      setNextToken(undefined);
      setFinishedLoading(false);
    } else {
      getData();
    }
    if (elementCounter === 100) {
      setIsLoading(true);
      unsubscribeFromEvents();
      setSubscriptions(
        addListeners(type, setEntities, setSearchTerm)?.map((l) => l!)
      );
    }
  }, [type, elementCounter]);

  useEffect(() => () => unsubscribeFromEvents(), [type]);

  return {
    isLoading,
    setIsLoading,
    entities,
    setEntities,
    fetchedAllData: finishedLoading,
    unsubscribeFromEvents,
  };
}

/**
 *
 * @returns
 */
export function useAllCompanies() {
  const [isLoading, setIsLoading] = useState(true);
  const [entities, setEntities] = useState<Company[]>([]);
  const [subscriptions, setSubscriptions] = useState<
    | {
        unsubscribe: Unsubscribe;
      }[]
    | undefined
  >();

  const getData = async (reset?: boolean) => {
    setIsLoading(true);
    const temp = await getAllDataFromEntity({
      query: queries.companyByDate,
      type: "company",
      sortDirection: "DESC",
      entity: Entity.COMPANY,
    });
    setEntities((prev) =>
      reset ? (temp as Company[]) : [...prev, ...(temp as Company[])]
    );
    setIsLoading(false);
  };

  const addListeners = () => {
    return [
      listenToAddEvent(Entity.COMPANY, setEntities),
      listenToDeleteEventEntity(Entity.COMPANY, setEntities),
    ];
  };

  useLayoutEffect(() => {
    getData(true);
    setSubscriptions(addListeners()?.map((l) => l!));
    return () => {
      subscriptions?.forEach((s) => s?.unsubscribe());
    };
  }, []);

  return {
    isLoading,
    entities,
    setEntities,
  };
}

/**
 *
 * @returns
 */
export function useAllOpenInboundInquiries() {
  const [isLoading, setIsLoading] = useState(true);
  const [entities, setEntities] = useState<InboundInquiry[]>([]);
  const [subscriptions, setSubscriptions] = useState<
    | {
        unsubscribe: Unsubscribe;
      }[]
    | undefined
  >();

  const getData = async (reset?: boolean) => {
    setIsLoading(true);
    const temp = await getAllOpenInboundInquiries();
    setEntities((prev) =>
      reset
        ? (temp as InboundInquiry[])
        : [...prev, ...(temp as InboundInquiry[])]
    );
    setIsLoading(false);
  };

  const addListeners = () => {
    return [
      listenToAddEvent(Entity.INBOUND_INQUIRY, setEntities),
      listenToDeleteEventEntity(Entity.INBOUND_INQUIRY, setEntities),
    ];
  };

  useLayoutEffect(() => {
    getData(true);
    setSubscriptions(addListeners()?.map((l) => l!));
    return () => {
      subscriptions?.forEach((s) => s?.unsubscribe());
    };
  }, []);

  return {
    isLoading,
    entities,
    setEntities,
  };
}
