import React, { useEffect, useState } from "react";
import { Popconfirm, Table } from "antd";
import {
  getColumnsWithCustomRender,
  getKeys,
  getTitle,
  initialState,
} from "./model";
import { InboundInquiry, TableEntity } from "../../store/types/api.types";
import { getValidForm } from "../../pages/DomainPage/utils";
import { deleteFeedEntry, listenToUpdateEvent, sortAsc } from "../Feed/model";
import { useLocation } from "react-router-dom";
import { editEntity, setFormValues } from "../../store/actions/api.actions";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { EntityForm } from "../../pages/DashboardPage/DashboardPage";
import { ExpandableTable } from "./ExpandableTable";
import { Entity } from "../CreateEntityModal/CreateEntityModal";
import { Unsubscribe } from "redux";

interface Props {
  data: Array<TableEntity>;
  setIsEditorOpen: React.Dispatch<React.SetStateAction<boolean>>;
  entity?: EntityForm;
  hideActions?: boolean;
  pathNameExpandableTable?: string;
}

export const EntityTable: React.FC<Props> = ({
  data,
  setIsEditorOpen,
  entity,
  pathNameExpandableTable,
  hideActions,
}) => {
  const { pathname } = useLocation();
  const [expandedRows, setExpandedRows] = useState<number[]>([]);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const rows = initialState(data, t);

  const handleEdit = (key: React.Key) => {
    const selectedRow = rows.find((r) => r.key === key);
    const entity = data.find((data: any) => data.id === selectedRow?.id);
    if (!entity) return;
    dispatch(setFormValues(true));
    dispatch(editEntity(replaceCurrencyValues(entity)));
    setIsEditorOpen(true);
  };

  const replaceCurrencyValues = (entity: any) => {
    Object.keys(entity).forEach((key) => {
      if (typeof entity[key] === "string" && entity[key].match(/(€|\$)/)) {
        entity[key] = Number(entity[key].replace(/(€|\$)/, ""));
      }
    });
    return entity;
  };

  const deleteTableEntry = (key: React.Key) => {
    const deleteEntity: any = rows.find((r) => r.key === key);
    deleteFeedEntry(deleteEntity, deleteEntity.id);
  };

  const getColumns = (data: TableEntity | undefined, pathname: string) => {
    if (!data) {
      return [{}];
    }
    const entity = getValidForm(pathname);
    const keys = getKeys(entity);
    const columns: Array<any> = getColumnsWithCustomRender(t, entity);
    keys.forEach((k: string) => {
      if (k !== "updatedAt" && k !== "company") {
        columns.push({
          title: t(getTitle(k)),
          align: getColumnAlignment(k),
          dataIndex: k,
          sorter: {
            compare: (a: any, b: any) => sortAsc(a[k], b[k]),
          },
        });
      }
    });
    if (!hideActions) {
      columns.push({
        title: t("Action"),
        dataIndex: "action",
        render: (_: any, record: { key: React.Key }) =>
          data ? (
            <div>
              <Popconfirm
                title={t("Are you sure you want to delete this entry?")}
                placement="left"
                onConfirm={() => deleteTableEntry(record.key)}
              >
                <a>Delete</a>
              </Popconfirm>
              <br />
              <a onClick={() => handleEdit(record.key)}>Edit</a>
            </div>
          ) : null,
      });
    }
    return columns;
  };

  const getColumnAlignment = (key: string) => {
    switch (key) {
      case "purchasePrice":
      case "salesPrice":
      case "shipping":
      case "totalPrice":
      case "margin":
        return "right";
      default:
        return "left";
    }
  };

  return (
    <Table
      size="middle"
      dataSource={rows}
      columns={getColumns(data[0], pathNameExpandableTable || pathname)}
      expandable={
        entity === EntityForm.INBOUND_INQUIRY
          ? {
              expandedRowRender: (
                inboundInquiry: InboundInquiry,
                index: number
              ) =>
                expandedRows.includes(index) ? (
                  <ExpandableTable inboundInquiry={inboundInquiry} />
                ) : null,
            }
          : undefined
      }
      onExpandedRowsChange={(rows) => setExpandedRows(rows as number[])}
      scroll={{ x: 600 }}
      pagination={false}
    />
  );
};
