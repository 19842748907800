import {
  GET_USER_INFORMATION_COMPLETED,
  CONFIRM_USER,
  LOG_IN_USER,
  SIGN_UP_USER,
  UserActionType,
  UserState,
  LOG_OUT_USER,
  EDIT_USER_INFORMATION,
} from "../types/user.types";

const INITIAL_STATE: UserState = {
  loading: true,
  userFound: false,
  confirmed: false,
  user: {
    attributes: {
      email: "",
      family_name: "",
      name: "",
      picture: "",
    },
  },
};

export function userReducer(
  state = INITIAL_STATE,
  action: UserActionType
): UserState {
  switch (action.type) {
    case LOG_IN_USER:
      return {
        loading: false,
        userFound: true,
        confirmed: state.confirmed,
        user: action.payload,
      };
    case CONFIRM_USER:
      return {
        ...state,
        confirmed: true,
      };
    case GET_USER_INFORMATION_COMPLETED:
      return {
        ...state,
        loading: false,
      };
    case SIGN_UP_USER:
      return {
        ...state,
        userFound: true,
        confirmed: false,
        user: action.payload,
      };
    case LOG_OUT_USER:
      return {
        ...state,
        userFound: false,
      };
    case EDIT_USER_INFORMATION:
      return {
        ...state,
        user: {
          attributes: {
            ...state.user.attributes,
            name: action.payload.firstName,
            family_name: action.payload.lastName,
          },
        },
      };
    default:
      return state;
  }
}
