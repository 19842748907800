import { FormInstance } from "antd/lib/form";
import { CompanyVariablesUpdate } from "../../graphql/type.graphql";
import { AutoCompleteValue } from "../AutoCompleteFormField/AutoCompleteFormField";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { setFormValues } from "../../store/actions/api.actions";
import { Company } from "../../store/types/api.types";
import { Country } from "./CompanyForm";
import { removePropsOfValues } from "../InboundInquiryForm/model.inboundInquiryForm";

/**
 *
 * @param form
 * @param countries
 * @param setAutoCompleteFields
 */
export function useCompanyPrefill(
  form: FormInstance<CompanyVariablesUpdate>,
  countries: Country[],
  setAutoCompleteFields: (
    typeOfContact?: AutoCompleteValue,
    country?: AutoCompleteValue
  ) => void
) {
  const dispatch = useDispatch();
  const shouldPrefillForm: boolean = useSelector(
    (state: RootStateOrAny) => state.apiReducer.edit.setFormValues
  );
  const currentSelectedCompany: Company = useSelector(
    (state: RootStateOrAny) => state.apiReducer.edit.currSelectedItem
  );

  useEffect(() => {
    if (currentSelectedCompany && shouldPrefillForm) {
      form.setFieldsValue({
        ...removePropsOfValues(
          ["type", "createdAt", "updatedAt"],
          currentSelectedCompany
        ),
      });
      const typeOfContact: AutoCompleteValue = {
        val:
          currentSelectedCompany.typeOfContact.slice(0, 1).toUpperCase() +
          currentSelectedCompany.typeOfContact.slice(1),
        id: currentSelectedCompany.typeOfContact,
        err: false,
      };
      const country: AutoCompleteValue = {
        val:
          countries.find((c) => c.code === currentSelectedCompany?.country)
            ?.name || "",
        id: currentSelectedCompany?.country || "",
        err: false,
      };
      setAutoCompleteFields(typeOfContact, country);
      dispatch(setFormValues(false));
    }
  });
  return {
    currentSelectedCompany,
  };
}
