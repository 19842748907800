import { Auth } from "aws-amplify";
import { UserInformation } from "../../store/types/user.types";

/**
 * Function that allows user to log in
 */
export async function signIn(userInformation: UserInformation) {
  return await Auth.signIn(userInformation.email, userInformation.password)
    .then(() => true)
    .catch(() => false);
}
